import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-custom-currency',
    template: `
        <span [style.fontSize]="integerSize">{{ integerPart }}</span>
        <span [style.fontSize]="fractionalSize">{{ '.' + fractionalPart }}฿</span
        >
    `,
})
export class CustomCurrencyComponent {
    @Input() value: number | string = 0;
    @Input() integerSize = '16px';
    @Input() fractionalSize = '16px';
    // default font size is 16px

    get integerPart() {
        return Math.floor(+this.value).toLocaleString();
      }
    
      get fractionalPart() {
        return (+this.value).toFixed(2).split('.')[1];
      }
}
