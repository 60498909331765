import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ICart,
    CartAmount,
    IConfirmCartItem,
    IUpdateCartItem,
    IMyCart,
    CartPayload,
    IRetailerAddress,
    IValidateOrderPayload,
    IValidateResponse,
    ICreateOrder,
} from '@core/models/retailer/cart.model';
import { IOrderDetail, IOrderDetailV2, PreviewOrders } from '@core/models/retailer/order-preview.model';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';
import { Icoupon, IcouponPayload } from '@core/models/retailer/coupon.model';
import { IProductCartSelect } from '@core/models/retailer/promotion-v2.model';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    constructor(private http: HttpClient) { }
    private cartChangedSource = new Subject<void>();
    cartChanged = this.cartChangedSource.asObservable();

    private cartItemCount = new BehaviorSubject(0);
    public currentItemCount = this.cartItemCount.asObservable();

    notifyCartChanged() {
        this.cartChangedSource.next();
    }

    getCart(): Observable<APIResponse<ICart>> {
        return this.http
            .get<APIResponse<ICart>>(`${environment.apiUrl}/cart`)
            .pipe(
                tap((response) => {
                    if (response && response.data) {
                        const count = response.data.items.reduce(
                            (acc, item) => acc + item.quantity,
                            0
                        );
                        this.cartItemCount.next(count);
                    }
                }),
                map((response) => response)
            );
    }

    updateCartItems(
        payload: IUpdateCartItem[]
    ): Observable<APIResponse<ICart>> {
        return this.http
            .put<APIResponse<ICart>>(`${environment.apiUrl}/cart/item`, payload)
            .pipe(tap(() => this.notifyCartChanged()));
    }

    getCartAmount(): Observable<APIResponse<CartAmount>> {
        return this.http
            .get<APIResponse<CartAmount>>(`${environment.apiUrl}/cart/amount`)
            .pipe(tap(() => this.notifyCartChanged()));
    }

    confirmCart(payload: IConfirmCartItem[]): Observable<APIResponse<null>> {
        return this.http
            .put<APIResponse<null>>(
                `${environment.apiUrl}/cart/confirm`,
                payload
            )
            .pipe(tap(() => this.notifyCartChanged()));
    }

    getOrderPreview(): Observable<APIResponse<PreviewOrders>> {
        return this.http
            .get<APIResponse<PreviewOrders>>(
                `${environment.apiUrl}/cart/preview-orders`
            )
            .pipe(tap(() => this.notifyCartChanged()));
    }
    ////////////////////////////////////////// New //////////////////////////////////////////////////////////////////

    getMyCart(): Observable<IMyCart[]> {
        return this.http.get<IMyCart[]>(`${environment.apiAdminUrl}/cart`).pipe(
            tap((response) => {
                if (response) {

                    const count = response.reduce((acc, cart) => acc + cart.items.reduce((accItem, item) => accItem + item.quantity, 0), 0)
                    this.cartItemCount.next(count);
                }
            }),
            map((response) => response)
        );
    }

    updateCartItem(payload: CartPayload[]) {
        return this.http.put(`${environment.apiAdminUrl}/cart`, payload);
    }
    getRetailerAddress() {
        return this.http.get<IRetailerAddress>(
            `${environment.apiAdminUrl}/retailer/my-address`
        );
    }
    retailerOrderValidate(payload: IValidateOrderPayload) {
        return this.http.post<IValidateResponse[]>(
            `${environment.apiAdminUrl}/retailer/order/validate`,
            payload
        );
    }

    retailerCreateOrder(payload = {}) {
        return this.http.post<ICreateOrder>(
            `${environment.apiAdminUrl}/retailer/order/confirm`,
            payload
        );
    }

    getOrderById(orderId: string): Observable<IOrderDetail> {
        return this.http.get<IOrderDetail>(`${environment.apiAdminUrl}/retailer/orders/${orderId}`)
    }

    getOrderV2ById(orderId: string): Observable<IOrderDetailV2> {
        return this.http.get<IOrderDetailV2>(`${environment.apiAdminV2Url}/retailer/orders/${orderId}`)
    }

    couponValidate(code: string, payload: IcouponPayload[]) {
        return this.http.post<Icoupon>(`${environment.apiAdminUrl}/coupons/${code}/validate`, payload);
    }

    reOrderV2(orderId: string) {
        return this.http.post<IProductCartSelect>(`${environment.apiAdminV2Url}/retailer/reorder/${orderId}`, {})
    }
}
