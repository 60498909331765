/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseOrder, PurchaseOrderImpl } from './purchase-order.model';

export class CreateRetailerPurchaseOrderResponse {
  retailerPurchaseOrderNumber: string;

  constructor(data: any) {
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
  }
}

export class RetailerPurchaseOrder {
  id: string;
  retailerPurchaseOrderNumber: string;
  retailerName: string;
  retailerAddress: string;
  createdAt: string;
  totalAmount: number;
  shippingCost: number;
  couponDiscount: number;
  netAmount: number;
  total_quantity_all: number;
  orders: PurchaseOrder[];
  promotionDiscount: number;
  totalAfterPromotionDiscount: number;


  constructor(data: any) {
    this.id = data.id;
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
    this.retailerName = data.retailerName;
    this.retailerAddress = data.retailerAddress;
    this.createdAt = data.createdAt;
    this.totalAmount = data.totalAmount;
    this.shippingCost = data.shippingCost;
    this.couponDiscount = data.discount;
    this.netAmount = data.netAmount;
    this.total_quantity_all = data.total_quantity_all;
    this.orders = data.orders?.map(
      (x: PurchaseOrder) => new PurchaseOrderImpl(x)
    );
    this.promotionDiscount = data.promotionDiscount;
    this.totalAfterPromotionDiscount = data.totalAfterPromotionDiscount;
  }
}

export class RetailerPurchaseOrderHistory {
  retailerPurchaseOrderNumber: string;
  createdDate: string;
  summaryAmount: number;
  retailerPurchaseOrderHistoryDetails: RetailerPurchaseOrderHistoryDetail[];

  constructor(data: any) {
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
    this.createdDate = data.createdDate;
    this.summaryAmount = data.summaryAmount;
    this.retailerPurchaseOrderHistoryDetails =
      data.retailerPurchaseOrderHistoryDetails?.map(
        (x: RetailerPurchaseOrderHistoryDetail) =>
          new RetailerPurchaseOrderHistoryDetail(x)
      );
  }
}

export class RetailerPurchaseOrderHistoryDetail {
  shipFromName: string;
  purchaseOrderNumber: string;
  status: string;
  netAmount: number;

  constructor(data: any) {
    this.shipFromName = data.shipFromName;
    this.purchaseOrderNumber = data.purchaseOrderNumber;
    this.status = data.status;
    this.netAmount = data.netAmount;
  }
}


export interface RetailerPromotionPurchaseOrder {
  id: string;
  couponCode: string;
  totalOrderAmount: number,
  promotionDiscount: number,
  couponDiscount: number,
  shippingCost: number,
  netAmount: number,
  total_quantity_all: number,
  createdAt: string,
  orderLists: orderLists[]
}

export interface orderLists {
  orderId: string,
  status: string,
  createdAt: string,
  totalOrderAmount: number,
  promotionDiscount: number,
  couponDiscount: number,
  shippingCost: number,
  netAmount: number,
  stockistId: number,
  stockistName: string,
  total_quantity: number,
  additionalPrivilege: additionalPrivilege[]
  itemInCart: itemInCart[]
}

export interface additionalPrivilege {
  Name: string,
  promotionId: number,
  discount: number,
  selectedGift: selectedGift[]
}

export interface selectedGift {
  stockistProductId?: number,
  giftId: number | null,
  productId?: number,
  quantity: number,
  name: string,
  nameEn: string
}

export interface itemInCart {
  cartId: number,
  promotionId?: number,
  type: string,
  privilegeType: string,
  name: string,
  nameEn: string,
  coverImageThump?: string,
  tierLevel?: number,
  quantity: number,
  discount?: number,
  amountPerUnit?: number,
  totalAmount: number,
  totalAfterDiscount?: number,
  createAt: string,
  imagePromotion?: string,
  items?: itemOrder[],
  gifts?: gifts[]
}

export interface itemOrder {
  stockistProductId: number,
  productId: number,
  name: string,
  nameEn: string,
  quantity: number
}

export interface gifts {
  stockistProductId?: number,
  giftId?: number | null,
  productId?: number,
  quantity: number,
  name: string,
  nameEn: string
}
