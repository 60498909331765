import { IValidateCouponPayload, IValidateCouponResponse } from '@core/models/retailer/cart.model';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IValidateData, IValidateOrderPayload, IValidateResponse } from '@core/models/retailer/cart.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { CartService } from '@core/services/retailer/cart.service';
import { ConfigService } from '@core/services/shared/config.service';
import { TranslateService } from '@ngx-translate/core';


interface Item {
  productId: number;
  price: number;
  quantity: number;
}

interface StockistData {
  stockistId: number;
  items: Item[];
}

interface ErrorResponse {
  error: string;
}

@Component({
  selector: 'app-modal-coupon',
  templateUrl: './modal-coupon.component.html',
  styleUrls: ['./modal-coupon.component.scss']
})

export class ModalCouponComponent implements OnInit {

  code = '';
  error = '';
  @Input() oldDiscountCode = '';
  @Input() totalPriceOrder = 0;
  @Input() dataOrder: IValidateResponse[] = [];

  @Output() discountApplied = new EventEmitter<number>();
  @Output() discountCode = new EventEmitter<string>();
  @Output() discountType = new EventEmitter<string>();
  @Output() modalClose = new EventEmitter<void>();

  constructor(
    private cartService: CartService,
    private cartV2Service: CartV2Service,
    private translate: TranslateService,
    private configService: ConfigService,
  ) { }
  isOpen = false;
  isNotUse = false;

  type = '';

  ngOnInit(): void {
    this.code = this.oldDiscountCode
    if (this.oldDiscountCode !== '') {
      this.code = this.oldDiscountCode
      this.isNotUse = true
    }
  }

  applyDiscount() {
    if (!this.code) {
      return
    }
    if (this.configService.getAppVersion() == "v1") {
      this.validateCouponV1Handler();
      return
    }
    this.validateCouponV2Hanlder()
  }

  private validateCouponV2Hanlder() {
    const payload: IValidateCouponPayload = {
      coupon: this.code
    }

    this.cartV2Service.couponValidate(payload).subscribe({
      next:(response) => this.onValidateSuccess(response),
      error:() => { this.error = this.translate.instant('MODAL_COUPON.ERROR'); }
    })
  }

  onValidateSuccess(response: IValidateCouponResponse) {
    if (response.errorMessage == "") {
      this.discountCode.emit(this.code);
      this.isOpen = false
      return
    }
    const errorMessageTh = response.errorMessage ?? "";
    const errorMessageEn = response.errorMessageEn ?? "";
    this.error = this.translate.currentLang == "th" ? errorMessageTh : errorMessageEn
  }

  private validateCouponV1Handler() {
    const data: IValidateData[] = [];
    for (const i in this.dataOrder) {
      if (Object.prototype.hasOwnProperty.call(this.dataOrder, i)) {
        const stockistData: StockistData = { stockistId: this.dataOrder[i].stockistId, items: [] };
        for (const j in this.dataOrder[i].items) {
          if (Object.prototype.hasOwnProperty.call(this.dataOrder[i].items, j)) {
            const itemData = {
              productId: this.dataOrder[i].items[j].productId,
              price: this.dataOrder[i].items[j].price,
              quantity: this.dataOrder[i].items[j].quantity
            };
            stockistData.items.push(itemData);
          }
        }
        data.push(stockistData);
      }
    }

    const checkCouponOrder: IValidateOrderPayload = {
      coupon: this.code,
      orders: data
    };

    this.cartService.retailerOrderValidate(checkCouponOrder).subscribe({
      next: (res) => {
        let discount = 0;
        for (const item of res) {
          discount += item.couponDiscount;
        }
        this.discountApplied.emit(discount);
        this.discountCode.emit(this.code);
        this.closeModal();
      },
      error: (error: ErrorResponse) => {
        this.onError(error)
      },
    });
  }

  onError(error: ErrorResponse) {
    const error_text = error.error;
    if (error_text === 'ไม่พบรหัสคูปอง กรุณาตรวจสอบความถูกต้องแล้วลองใหม่อีกครั้ง') {
      this.error = this.translate.instant('TITLE.ERROR_COUPON_1');
    } if (error_text === 'ขออภัย รหัสคูปองไม่ถูกต้อง หรือคุณยังไม่ได้รับสิทธิ์เพื่อใช้งานรหัสคูปองนี้') {
      this.error = this.translate.instant('TITLE.ERROR_COUPON_2');
    } if (error_text === 'รหัสคูปองนี้หมดอายุแล้ว') {
      this.error = this.translate.instant('TITLE.ERROR_COUPON_3');
    } if (error_text === 'ใช้สิทธิ์รหัสคูปองนี้ครบแล้ว หรือถูกใช้งานหมดแล้ว') {
      this.error = this.translate.instant('TITLE.ERROR_COUPON_4');
    } if (error_text === 'รายการสินค้าของคุณไม่เข้าร่วมรายการกับคูปองนี้') {
      this.error = this.translate.instant('TITLE.ERROR_COUPON_6');
    } else {
      this.error = "เกิิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";
    }
    return;
  }

  notUseDiscount() {
    if (this.configService.getAppVersion() == "v1") {
      this.discountApplied.emit(0);
      this.discountType.emit("");
      this.discountCode.emit("")
      this.closeModal();
      return
    }
    this.code = "";
    this.validateCouponV2Hanlder();
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.modalClose.emit();
  }

  checkCoupon() {
    if (this.code === this.oldDiscountCode) {
      if (this.oldDiscountCode !== '') {
        this.isNotUse = true
      } else {
        this.isNotUse = false
      }
    } else {
      this.isNotUse = false
    }
  }
}
