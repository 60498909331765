import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderDetail, IOrderDetailV2 } from '@core/models/retailer/order-preview.model';
import { CartService } from '@core/services/retailer/cart.service';
import { ConfigService } from '@core/services/shared/config.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-order-history-detail-main',
    templateUrl: './order-history-detail-main.component.html',
    styleUrls: ['./order-history-detail-main.component.scss']
})
export class OrderHistoryDetailMainComponent implements OnInit {
    orderData: IOrderDetail | undefined;
    orderDataV2: IOrderDetailV2 | undefined;
    appVersion?: string
    isLoading = false

    constructor(private cartService: CartService, private route: ActivatedRoute, private configService: ConfigService, private spinner: NgxSpinnerService, private router: Router) { }

    ngOnInit(): void {
        this.isLoading = true
        this.spinner.show('spinner')
        this.appVersion = this.configService.getAppVersion();
        this.loadOrderDetail()

    }

    loadOrderDetail() {
        const orderId = this.route.snapshot.params['orderid'];
        if (orderId) {

            this.appVersion === 'v1' ? this.cartService.getOrderById(orderId).subscribe((res) => {
                this.orderData = res
                this.isLoading = false
                this.spinner.hide('spinner')
            }) : this.cartService.getOrderV2ById(orderId).subscribe((res) => {
                this.orderDataV2 = res
                this.isLoading = false
                this.spinner.hide('spinner')
            });

        }
    }

}
