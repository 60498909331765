import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IProductCartSelect, ItemInCart, IVerifyCartResponse, OrderList, VerifyCartAdditionalPrivilege } from '@core/models/retailer/promotion-v2.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { IRetailerAddress, IValidateResponse } from '@core/models/retailer/cart.model';
import { Router } from '@angular/router';
import { ICreateOrderResponse } from '@core/models/retailer/createOrderResponse.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-summary-order',
    templateUrl: './summary-order.component.html',
    styleUrls: ['./summary-order.component.scss']
})
export class SummaryOrderComponent implements OnInit, AfterViewChecked {
    @ViewChild('footerPage', { static: false }) footerPage?: ElementRef;
    constructor(
        private route: Router,
        private translate: TranslateService,
        private cartService: CartV2Service,
        private spinner: NgxSpinnerService
    ) {

    }
    isLoading = false;
    firstIsLoading = false;
    expandDetails = false;
    detailIcon = 'assets/images/detail-icon.png'

    validateDetail: IValidateResponse[] = [];
    retailerResponse?: IRetailerAddress;
    verifyCartResponse?: IVerifyCartResponse
    isModalOpen = false;

    couponName = '';
    currentLang = 'th';

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.getRetailerAddress()
    }


    ngAfterViewChecked(): void {
        if (this.footerPage) {
            this.updateFooterHeight();
        }
    }

    updateFooterHeight(): void {
        const footerHeight = this.footerPage?.nativeElement.offsetHeight + 65 + 'px';
        document.documentElement.style.setProperty('--footer-height', footerHeight);
    }

    handleImageError(event: ErrorEvent) {
        const target = event.target as HTMLImageElement
        target.src = 'assets/images/image_error.jpg'
    }

    openDiscountModal() {
        this.isModalOpen = true;
    }

    closeDiscountModal() {
        this.isModalOpen = false;
    }

    toggleExpandDetails(): void {
        this.expandDetails = !this.expandDetails;
        setTimeout(() => {
            this.updateFooterHeight();
        }, 0);
    }

    getVerifyCartHandler() {
        this.cartService.getVerifyCart()
            .subscribe({
                next: (res) => {
                    let cartList: IProductCartSelect[] = []
                    res.orderLists.forEach((cart) => {
                        const stockistCart: IProductCartSelect[] = cart.itemInCart.map((item) => { return { cartId: item.cartId, quantity: item.quantity, price: 0 } })
                        cartList = cartList.concat(stockistCart)
                    })
                    const jsonCartList = JSON.stringify(cartList)
                    localStorage.setItem("reOrderCartList", jsonCartList)
                    this.firstIsLoading = true
                    this.onGetVerifyCartSuccess(res)
                },
                error: () => { this.showLoader(false) }
            })
    }

    getRetailerAddress() {
        this.showLoader(true)
        this.cartService.getRetailerAddress()
            .subscribe({
                next: (response) => {
                    this.retailerResponse = response
                    this.getVerifyCartHandler()
                },
                error: () => this.showLoader(false)
            })
    }

    onGetVerifyCartSuccess(res: IVerifyCartResponse) {
        this.verifyCartResponse = res
        this.couponName = this.verifyCartResponse.couponCode
        this.showLoader(false)
    }

    onGetNewCouponCodeHandler(couponCode: string) {
        this.isModalOpen = false;
        this.couponName = couponCode
        this.showLoader(true)
        this.getVerifyCartHandler()
    }

    onCreateOrderHandler() {
        this.showLoader(true)
        this.cartService.createOrder().subscribe({
            next: (response) => this.onCreateOrderSuccess(response),
            error: () => this.showLoader(false)
        })
    }

    onCreateOrderSuccess(response: ICreateOrderResponse) {
        const keyCreateOrder = "createOrder"
        const convertedData = JSON.stringify(response);
        localStorage.setItem(keyCreateOrder, convertedData);

        this.showLoader(false)
        this.navigateToOrderSuccess()
    }

    navigateToOrderSuccess() {
        const keyPathOrderSuccess = '/retailer/order-success-new'
        this.route.navigate([keyPathOrderSuccess]);
    }

    getAddress(): string {
        return (
            this.retailerResponse?.billingAddress +
            ' ' +
            this.retailerResponse?.billingSubdistrict +
            ' ' +
            this.retailerResponse?.billingDistrict +
            ' ' +
            this.retailerResponse?.billingProvince +
            ' ' +
            this.retailerResponse?.billingPostCode
        );
    }

    hasCartDiscout(item: ItemInCart): boolean {
        return item.discount != null && item.discount != 0.0
    }

    sumTotalAfterDiscount(list: ItemInCart[]): number {
        return list.map(item => item.totalAfterDiscount).reduce(((x1, x2) => x1 + x2))
    }

    sumTotalAmount(list: ItemInCart[]): number {
        return list.map(item => item.totalAmount).reduce(((x1, x2) => x1 + x2))
    }

    sumTotalAmountAllStockist(orderList?: OrderList[]): number {
        if (orderList == null) {
            return 0
        }
        return orderList.map(item => item.itemInCart).map((item) => this.sumTotalAmount(item)).reduce(((x1, x2) => x1 + x2))
    }

    sumTotalNetAmountAllStockist(orderList?: OrderList[], couponDiscount?: number): number {
        if (orderList == null) {
            return 0
        }
        const discountWithPromotion = orderList.map(item => item.itemInCart).map((item) => this.sumTotalAfterDiscount(item)).reduce(((x1, x2) => x1 + x2))
        let totalAdditionalDiscount = 0
        orderList.forEach(item => item.additionalPrivilege.forEach((ap) => {totalAdditionalDiscount += ap.discount ?? 0}))
        return discountWithPromotion - (couponDiscount ?? 0) - (totalAdditionalDiscount ?? 0);
    }

    sumAmountProduct(orderList?: OrderList[]): string {
        if (orderList == null) {
            return ""
        }
        return orderList.map(
            item => item.itemInCart).map(
                (item) => item.map(
                    item => item.quantity).reduce(((x1, x2) => x1 + x2)
                    )
            ).reduce(((x1, x2) => x1 + x2)).toString()
    }

    sumShiping(orderList?: OrderList[]): number {
        if (orderList == null) {
            return 0
        }
        return orderList.map(
            item => item.zone.deliveryFee
        ).reduce((x1, x2) => x1 + x2)
    }

    sumTotalDiscountPromorion(orderList?: OrderList[]): number {
        if (orderList == null) {
            return 0
        }
        const discountPrivilege = orderList
            .filter(x => x.promotionDiscount != null)
            .map(x => x.promotionDiscount)
            .reduce((x1, x2) => {
                if (x2 == undefined || x1 == undefined) {
                    return
                }
                return x1 + x2;
            })
        return discountPrivilege || 0
    }

    sumDiscountPromotionByCart(list: ItemInCart[]): number {
        return list.map(item => item.discount).reduce(((x1, x2) => x1 + x2))
    }

    sumDiscountCoupon(orderList?: OrderList[]): number {
        if (!orderList) {
            return 0
        }
        const discount = orderList?.map(order => order.couponDiscount).reduce(((x1, x2) => x1 + x2))
        if (discount == null) {
            return 0
        }
        return discount
    }

    isButtonEnabled(): boolean {
        return this.verifyCartResponse?.valid || false;
    }

    isHaveAdditionalPrivilege(orderList?: OrderList[]): boolean {
        if (!orderList) {
            return false
        }
        const additionalPrivileges = orderList?.map(order => order.additionalPrivilege).flat()

        if (!additionalPrivileges) {
            return false
        }
        return additionalPrivileges.length > 0
    }

    getAdditionalPrivilegeDiscount(orderList?: OrderList[]): VerifyCartAdditionalPrivilege[] {
        if (!orderList) {
            return []
        }
        const additionalPrivileges = orderList?.map(order => order.additionalPrivilege).flat()

        if (!additionalPrivileges) {
            return []
        }
        const isNotNullOrZero = (valie?: number | null): boolean => valie !== null && valie !== 0

        return additionalPrivileges.filter(p => isNotNullOrZero(p.discount))
    }

    getAdditionalPrivilegeGiveAway(orderList?: OrderList[]): VerifyCartAdditionalPrivilege[] {
        if (!orderList) {
            return []
        }
        const additionalPrivileges = orderList?.map(order => order.additionalPrivilege).flat()

        if (!additionalPrivileges) {
            return []
        }

        return additionalPrivileges.filter(p => p.selectedGifts != null && p.selectedGifts?.length > 0)
    }

    getTypePerUnit(type: string): string {
        if (type.toUpperCase() == "BUNDLE") {
            return this.translate.instant("PAYMENT.SET")
        }
        return this.translate.instant("PAYMENT.UNIT")
    }

    showLoader(show: boolean) {
        this.isLoading = show
        if (show) {
            this.spinner.show('spinner');
            return
        }
        this.spinner.hide('spinner');
    }
}
