/* eslint-disable @typescript-eslint/no-explicit-any */
export interface StockistsResponse {
  items: Stockist[];
  message?: string;
}

export class Stockist {
  id: number;
  name: string;
  nameEN: string;
  logoPathThump: string;
  image_Url: string;
  coverImagePathThump: string;

  constructor(data: any) {
    this.id = data?.id || 0;
    this.name = data?.name || '';
    this.nameEN = data?.nameEN || '';
    this.logoPathThump = data?.logoPathThump || '';
    this.image_Url = data?.image_Url || '';
    this.coverImagePathThump = data?.coverImagePathThump || '';
  }
}
