import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBrandRecommend } from '@core/models/retailer/brand.model';
import { BrandService } from '@core/services/retailer/brand.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

export interface BrandParams {
  limit: number
  page: number
  keyword?: string
}

@Component({
  selector: 'app-all-recommend-brands',
  templateUrl: './all-recommend-brands.component.html',
  styleUrls: ['./all-recommend-brands.component.scss']
})
export class AllRecommendBrandsComponent implements OnInit {
  params: BrandParams = {
    limit: 50,
    page: 1,
  };

  brandList: IBrandRecommend[] = []
  total = 0
  hasMoreData = true
  showNotFoundIcon = false
  isLoading = false
  currentLang!: string

  constructor(private route: Router, private brandService: BrandService, private spinner: NgxSpinnerService, private translate: TranslateService) { }

  ngOnInit(): void {
    const oldFilterBrand = localStorage.getItem('oldFilterBrand')
    if (oldFilterBrand) {
      const oldFilterJson: BrandParams = JSON.parse(oldFilterBrand)
      this.params = oldFilterJson
    }

    this.currentLang = this.translate.currentLang
    this.spinner.show('spinner');
    this.isLoading = true;
    this.handleSearch()
  }

  navigateBack(): void {
    this.route.navigate(['/all-product']);
  }

  navigateToBrandProduct(brandId: number) {
    const encodeFilter = JSON.stringify(this.params)
    localStorage.setItem('oldFilterBrand', encodeFilter)
    this.route.navigate([`/retailer/all-product`, { 'brand-id': brandId }]);
  }

  search(query: string): void {
    this.params = {
      ...this.params,
      limit: 50,
      page: 1,
      keyword: query,
    };
    this.brandList = [];
    this.spinner.show('spinner');
    this.isLoading = true;
    this.handleSearch();
  }

  handleSearch() {
    this.spinner.show('spinner');
    this.isLoading = true;
    this.brandService
      .getRecommendBrand(this.params)
      .subscribe({
        next: (res) => {
          this.total = res.total;
          if (Array.isArray(res.data)) {
            if (res.data.length === 0) {
              this.hasMoreData = false;
            } else {
              const newProducts = res.data
              const existingIds = this.brandList.map(
                (item) => item.id
              );
              const uniqueNewProducts = newProducts.filter(
                (item) => !existingIds.includes(item.id)
              );
              this.brandList.push(...uniqueNewProducts);
            }
            this.showNotFoundIcon = this.brandList.length === 0;
          }
          this.isLoading = false;
          this.spinner.hide('spinner');
        },
        error: (err) => {
          console.error(err)
          this.isLoading = false;
          this.spinner.hide('spinner');
          this.showNotFoundIcon = true
        }
      })

  }

  showProductList(): boolean {
    return this.brandList.length > 0;
  }

  showNotFoundMessage(): boolean {
    return this.showNotFoundIcon && !this.showProductList();
  }
}
