<nav class="navbar bg-body-tertiary"
  [ngStyle]="{'background-color': 'white','padding-left': '1rem','padding-right': '1rem',position: 'fixed',top: '10',width: '100%','margin-top': '-1rem'}">
  <div class="back-button justify-content-start pointer-cursor" routerLink="/retailer/my-cart-v2">
    <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
    <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
  </div>
</nav>

<div *ngIf="firstIsLoading" class="aura-container-custom pt-4 px-0 pb-4"
  style="background-color: #EEE;padding-top: 4px;">
  <div class="d-flex align-items-center fs-4" [ngStyle]="{ width: '100%','background-color':'white' }">
    <p class="fs-5 fw-semibold mb-0 me-auto title-session px-3">
      {{ "TITLE.CHECK_ORDER" | translate }}
    </p>
  </div>

  <div *ngIf="firstIsLoading" class="d-flex flex-column justify-content-center pt-3 gap-2"
    style="background-color: #FFFF;">

    <!-- retailer shipping address section -->
    <div class="d-flex gap-2 fs-6 text-wrap px-3">
      <img class="mr-3" src="assets/images/shipaddress.svg" width="20px" height="20px" alt="" />
      <div>
        <div class="fs-sm fw-rg">{{ "TITLE.DELIVERED_AT" | translate }}</div>
        <div>
          <span class="fs-md fw-md">
            {{retailerResponse?.retailerName }}
            <br /></span>
          <span class="fs-sm fw-rg">
            {{ getAddress() }}
          </span>
        </div>
      </div>
    </div>

    <!-- product list section -->
    <div class="cart-loop" *ngFor="let order of verifyCartResponse?.orderLists; let i = index"
      [attr.data-cy-row-number]="i+1">

      <!-- stockist name section -->
      <div class="d-flex gap-2 fs-6 pt-2 text-wrap align-items-center top-border px-3">
        <img src="assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{order.stockistName}}</p>
      </div>
      <div class="bottom-border"></div>

      <!-- product section -->
      <div class="item-cart" *ngFor="let itemInCart of order.itemInCart; let i = index" [attr.data-cy-row-number]="i+1">
        <div class="product-pane px-3">
          <div class="d-flex align-items-baseline product-detail" style="margin-right: auto">
            <div class="">
              <img class="product-image" width="80px" src="{{ itemInCart.coverImagePathThumb }}"
                (error)="handleImageError($event)" alt="notFound" />
            </div>
            <div class="w-100">
              <div class="row">
                <div class="product-name px-3">
                  <p class="pt-3 card-text-name">{{ currentLang === "th" ? itemInCart.name : itemInCart.nameEn}}</p>
                </div>
              </div>
            </div>
            <div class="col-1 product-qty" *ngIf="itemInCart.type!=='Tier'">x{{itemInCart.quantity}}</div>
          </div>
          <div>
            <!-- product detail section -->
            <div class="row d-flex">
              <app-my-cart-product-detail-v2 [productDetail]="itemInCart.items"
                [gifts]="itemInCart.gifts"></app-my-cart-product-detail-v2>
            </div>
            <div class="pt-28px"></div>

            <!-- product price section -->
            <div class="d-flex gap-2 text-wrap align-items-start sum-row">
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <p class="mb-2"></p>

                  <!-- amout per unit -->
                  <span class="per-amount">
                    <app-custom-currency [value]="itemInCart.amountPerUnit" integerSize="12px" fractionalSize="12px" />/{{getTypePerUnit(itemInCart.type) }}
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="mb-2 amount-label">{{ 'TITLE.SUM' | translate}}</p>
                  <div class="d-flex justify-content-end">
                    <div class="d-flex flex-column justify-content-between" style="height: 100%;">
                      <p class="cut-amount" *ngIf="hasCartDiscout(itemInCart)">
                        <app-custom-currency [value]="itemInCart.totalAmount" integerSize="12px"
                          fractionalSize="12px"></app-custom-currency>
                      </p>
                    </div>
                    <p class="amount-label" [ngStyle]="{ 'color': hasCartDiscout(itemInCart) ? 'red' : 'inherit' }">
                      <app-custom-currency [value]="itemInCart.totalAfterDiscount" integerSize="16px"
                        fractionalSize="16px"></app-custom-currency>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- price summary per stockist section -->
      <div class="d-flex gap-2 text-wrap align-items-start sum-row px-3 pd-16px">
        <div class="w-100">
          <div class="d-flex justify-content-between">
            <div class="mb-2">{{ 'PAYMENT.PRODUCT_TOTAL' | translate}}</div>
            <app-custom-currency [value]="sumTotalAfterDiscount(order.itemInCart)" integerSize="16px"
              fractionalSize="16px" />
          </div>
          <div class="d-flex justify-content-between">
            <div class="mb-2">{{ 'PAYMENT.SHIPPING_COST' | translate}}</div>
            <app-custom-currency [value]="order.zone.deliveryFee" integerSize="14px" fractionalSize="14px" />
          </div>
          <div *ngIf="order.errorMessage" class="is-invalid w-100 d-flex gap-3 px-3 align-items-center">
            <i ngClass="fa fa-exclamation-triangle"></i>
            <p class="my-2">{{ currentLang === 'th' ? order.errorMessage:order.errorMessageEn }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <div class="amount-label">{{ 'PAYMENT.TOTAL' | translate}}</div>
            <span class="amount-label">
              <app-custom-currency [value]="sumTotalAfterDiscount(order.itemInCart)+ order.zone.deliveryFee"
                integerSize="16px" fractionalSize="16px" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- footer -->
<div id="footer-page" #footerPage class="footer footer-position" *ngIf="firstIsLoading">
  <div class="d-flex flex-column w-100 px-ß">
    <!-- coupon input section -->
    <div class="d-flex justify-content-between fw-light coupon-session" (click)="openDiscountModal()">
      <div class="coupon-title coupon">
        <img src="assets/icons/tag.png" /> {{ 'MODAL_COUPON.TITLE' | translate}}
      </div>
      <div class="d-flex gap-3 align-items-center coupon-inside">
        <div class="coupon-code">{{couponName}}</div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
    <div class="bottom-border" *ngIf="false"></div>

    <!-- additional privilege section -->
    <div class="d-flex" *ngIf="isHaveAdditionalPrivilege(verifyCartResponse?.orderLists)" [class.more-detail]="!expandDetails" [class.more-detail-active]="expandDetails"
      (click)="toggleExpandDetails()">
      <div class="d-flex justify-content-between align-items-center">
        <div class="more-detail-title">
          {{ 'ADDITIONAL_PRIVILEGE.TITLE' | translate}}
        </div>
        <i class="fa-regular fa-angle-down down" [style.transform]="expandDetails ? 'rotate(180deg)' : 'none'"></i>
      </div>
      <div *ngIf="expandDetails" class="additional">
        <div class="w-100">
          <div *ngIf="getAdditionalPrivilegeGiveAway(verifyCartResponse?.orderLists).length !== 0" class="d-flex justify-content-between pt-12px">
            <div class="sub-title-aditional-privilege">{{ 'TITLE.GIFT' | translate}}</div>
          </div>
          <div *ngFor="let additionalPrivlege of getAdditionalPrivilegeGiveAway(verifyCartResponse?.orderLists)">
              <div *ngFor="let giveAway of additionalPrivlege.selectedGifts">
                <div class="d-flex justify-content-between pt-12px">
                  <div class="sub-detail-aditional-privilege">{{ currentLang === 'th' ? giveAway.name : giveAway.nameEn}}</div>
                  <div class="sub-detail-aditional-privilege">x{{giveAway.quantity}}</div>
                </div>
              </div>  
          </div>
          <div *ngIf="getAdditionalPrivilegeDiscount(verifyCartResponse?.orderLists).length !== 0" class="d-flex justify-content-between pt-12px">
            <div class="sub-title-aditional-privilege">{{ "ORDER_INFO.ADDITIONAL.DISCOUNT_TITLE" | translate }}</div>
          </div>
          <div *ngFor="let additionalPrivlege of getAdditionalPrivilegeDiscount(verifyCartResponse?.orderLists)">
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-detail-aditional-privilege">{{additionalPrivlege.name}}</div>
              <div class="sub-detail-aditional-privilege">
                -<app-custom-currency
                [value]="additionalPrivlege.discount ?? 0"
                integerSize="14px" fractionalSize="14px" />
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>

    <!-- price summary per order section -->
    <div>
      <div class="d-flex justify-content-between left-right-footer sub-detail-payment">
        <div>
          {{ "PAYMENT.TOTAL_ALL" | translate }} ({{sumAmountProduct(verifyCartResponse?.orderLists)}} {{ "TITLE.UNIT" |
          translate }})
        </div>
        <app-custom-currency [value]="sumTotalAmountAllStockist(verifyCartResponse?.orderLists)" integerSize="14px"
          fractionalSize="14px" />
      </div>
      <div class="d-flex justify-content-between left-right-footer sub-detail-payment"
        *ngIf="sumTotalDiscountPromorion(verifyCartResponse?.orderLists) !== 0">
        <div>
          {{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}
        </div>
        <div class="color-red">
          -<app-custom-currency [value]="sumTotalDiscountPromorion(verifyCartResponse?.orderLists)" integerSize="14px"
            fractionalSize="14px" />
        </div>
      </div>
      <div class="d-flex justify-content-between left-right-footer  sub-detail-payment"
        *ngIf="sumDiscountCoupon(verifyCartResponse?.orderLists) !== 0">
        <div>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</div>
        <div class="color-red">
          -<app-custom-currency [value]="sumDiscountCoupon(verifyCartResponse?.orderLists)" integerSize="14px"
            fractionalSize="14px" />
        </div>
      </div>
      <div class="d-flex justify-content-between left-right-footer sub-detail-payment">
        <div>{{ "PAYMENT.SHIPPING_COST" | translate }} ({{ "TITLE.ALL_STORE" | translate }})</div>
        <app-custom-currency [value]="sumShiping(verifyCartResponse?.orderLists)" integerSize="14px"
          fractionalSize="14px" />
      </div>
      <div class="d-flex justify-content-between left-right-footer sub-detail-payment-total">
        <div>{{ "PAYMENT.NET_TOTAL" | translate }}</div>
        <app-custom-currency
          [value]="sumTotalNetAmountAllStockist(verifyCartResponse?.orderLists, verifyCartResponse?.couponDiscount)+sumShiping(verifyCartResponse?.orderLists)"
          integerSize="16px" fractionalSize="16px" />
      </div>
    </div>

    <!-- confirm order button -->
    <button type="button" class="btn confirm-order-btn" [disabled]="!isButtonEnabled()" (click)="onCreateOrderHandler()"
      data-cy="confirm-order-btn">
      {{ "ACTION.CONFIRM_ORDER" | translate }}
    </button>
  </div>

  <!-- coupon modal section -->
  <app-modal-coupon *ngIf="isModalOpen" [dataOrder]="validateDetail" [totalPriceOrder]="0"
    [oldDiscountCode]="couponName" (discountCode)="onGetNewCouponCodeHandler($event)"
    (modalClose)="closeDiscountModal()" />
</div>

<!-- spinner -->
<div class="overlay" *ngIf="isLoading">
  <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
    name="spinner" style="margin-top: 0px !important;" />
</div>