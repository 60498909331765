/* eslint-disable @typescript-eslint/no-explicit-any */

let env: any = {};

declare const CONFIG: { string: string };
if (typeof CONFIG === 'undefined') {
    env = {
        googleMapBaseUrl: `https://maps.googleapis.com/maps/api/geocode/json?address`,
        secret: 'aurauser',
        secretToken: 'auratoken',
        baseApiUrl: 'https://retailer.aura-dev.site/api',
        apiUrl: 'https://retailer.aura-dev.site/api/customer',
        apiUrlLine: 'https://retailer.aura-dev.site/api/line',
        apiAdminUrl: 'https://retailer.aura-dev.site/api/customer',
        apiAdminV2Url: 'https://retailer.aura-dev.site/api/v2/customer',
        apiLoginUrl: 'https://retailer.aura-dev.site/apix/customer',
        apiProductUrl: 'https://retailer.aura-dev.site/api/product',
        apiProductV2Url: 'https://retailer.aura-dev.site/api/v2/product',
        apiPromotionUrl: 'https://retailer.aura-dev.site/api/promotion',
        apiPromotionV2Url: 'https://retailer.aura-dev.site/api/v2/promotion',
        apiNotificationUrl: 'https://retailer.aura-dev.site/api/notification',
        apiCmsUrl: 'https://retailer.aura-dev.site/api/cms',
        apiCmsV2Url: 'https://retailer.aura-dev.site/api/v2/cms',
        zendeskUrl: 'https://static.zdassets.com/ekr/snippet.js?key=8ee3894f-2a93-4b80-a189-f05c7ac451cc',
        name: 'local environment',
        env: 'local',
        googleKey: 'AIzaSyDUZ_MVXGacdBRH8fR5G2YwTsYa9wbsowg',
    };
} else {
    env = CONFIG;
}

export const environment = {
    ...env,
    firebaseConfig: {
        apiKey: 'AIzaSyDCpqEhy34qKd0QZdYZHHRi_dSwN9KdPWY',
        authDomain: 'tnt-aura-dev-8cb6e.firebaseapp.com',
        projectId: 'tnt-aura-dev-8cb6e',
        storageBucket: 'tnt-aura-dev-8cb6e.appspot.com',
        messagingSenderId: '925414639265',
        appId: '1:925414639265:web:c4af039a8bcaaeaf5d52e0',
    },
};
