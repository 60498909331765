import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal-select-gift',
  templateUrl: './modal-select-gift.component.html',
  styleUrls: ['./modal-select-gift.component.scss']
})
export class ModalSelectGiftComponent {
  @Output() clickClose = new EventEmitter<void>();

  isAllGiftsSelected = false;

  onAllGiftsSelected(isSelected: boolean) {
    this.isAllGiftsSelected = isSelected;
  }

  closeModal() {
    this.clickClose.emit()
  }
}
