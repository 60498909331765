import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancelOrderPayload } from '@core/models/retailer/cart.model';
// import { ReasonForCancellation } from '@core/models/retailer/confirm-order-cancellation.model';
import { IHistoryOrderMainBody } from '@core/models/retailer/history.model';
import {
  PurchaseOrder,
  PurchaseOrderCreateRequest,
  reOrderPayload,
  reOrderProducts,
} from '@core/models/retailer/purchase-order.model';
import {
  CreateRetailerPurchaseOrderResponse,
  RetailerPurchaseOrder,
  RetailerPurchaseOrderHistory,
} from '@core/models/retailer/retailer-purchase-order';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';
import { ConfigService } from '../shared/config.service';

export interface Pagination {
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  basePath = `${environment.apiUrl}/retailer`;
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  createRetailerPurchaseOrder(
    request: PurchaseOrderCreateRequest
  ): Observable<APIResponse<CreateRetailerPurchaseOrderResponse>> {
    return this.http.post<APIResponse<CreateRetailerPurchaseOrderResponse>>(
      `${this.basePath}/orders`,
      request
    );
  }

  getRetailerPurchaseOrder(
    retailerPurchaseOrderNumber: string
  ): Observable<APIResponse<RetailerPurchaseOrder>> {
    return this.http.get<APIResponse<RetailerPurchaseOrder>>(
      `${this.basePath}/orders/${retailerPurchaseOrderNumber}`
    );
  }

  getRetailerPurchaseOrders(): Observable<
    APIResponse<RetailerPurchaseOrderHistory[]>
  > {
    return this.http.get<APIResponse<RetailerPurchaseOrderHistory[]>>(
      `${this.basePath}/orders`
    );
  }

  getPurchaseOrder(
    retailerPurchaseOrderNumber: string,
    purchaseOrderNumber: string
  ): Observable<APIResponse<PurchaseOrder>> {
    return this.http.get<APIResponse<PurchaseOrder>>(
      `${this.basePath}/orders/${retailerPurchaseOrderNumber}/purchase-orders/${purchaseOrderNumber}`
    );
  }


  ///////////////////////////////////////////////////// New //////////////////////////////////////////////////////

  getOrderHistory(params: Pagination): Observable<IHistoryOrderMainBody[]> {
    return this.http.get<IHistoryOrderMainBody[]>(`${environment.apiAdminUrl}/retailer/ordergroups`, {
      params: ClearParams(params),
    });
  }

  cancelPurchaseOrder(
    orderId: string,
    reason: CancelOrderPayload
  ): Observable<APIResponse> {
    const url = this.configService.getApiUrl(`customer/retailer/order/${orderId}/cancel`)
    return this.http.patch<APIResponse>(
      url,
      reason
    );
  }

  reOrderPurchase(orderId: string, confirm: reOrderPayload): Observable<reOrderProducts[]> {
    return this.http.patch<reOrderProducts[]>(`${environment.apiAdminUrl}/cart/reorder/${orderId}`, confirm);
  }
}
