<div *ngIf="product">
  <div class="product-pane">
    <!-- product section -->
    <div class="d-flex align-items-baseline" style="margin-right: auto">
      <!-- checkbox section -->
      <div class="col-1 checkbox">
        <input [checked]="product.isSelected" (change)="handleSelectedProduct()" type="checkbox"
          id="product-{{product.cartId}}-checkbox">
        <label for="product-{{ product.cartId }}-checkbox" class="checkmark"></label>
      </div>
      <!-- product image section -->
      <div class="">
        <img src="{{product.coverImagePathThumb}}" (error)="handleImageError($event)" alt="product image"
          class="p-2 product_image">
      </div>
      <!-- product description section -->
      <div class="w-100">
        <div class="row">
          <div class="col">
            <div class="product-name">
              <p class="pt-3 lh-base card-text-name">{{currentLang === 'th' ? product.name : product.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- promotion product list section -->
    <div class="row">
      <div class="d-flex align-items-baseline" style="margin-right: auto">
        <div class="col-1"></div>
        <div class="col w-100">
          <app-my-cart-product-detail-v2 [productDetail]="product.items"></app-my-cart-product-detail-v2>
        </div>
      </div>
    </div>

    <!-- increase/decrease quantity & product amount section -->
    <div class="row mt-1">
      <div class="col-1"></div>
      <div class="col d-flex justify-content-between" style="margin-left: 0px;">
        <div class="d-flex">
          <button class="btn btn-icon" style="margin-left: -12px" (click)="reduceQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/MinusIcon.svg" />
          </button>
          <input #currentQuantity class="amount input-item" type="text" value="{{ product.quantity }}"
            oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);" (input)="onInput($event)"
            (blur)="handleOnBlur()" [disabled]="isManageMode" />
          <button class="btn btn-icon" (click)="increaseQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/PlusIcon.svg" />
          </button>
        </div>
        <span>
          <p class="fw-bold" style="margin-top: 0.6rem;">{{product.amountPerUnit * product.quantity |
            currency:'THB': 'symbol-narrow'}}</p>
        </span>
      </div>
    </div>
  </div>
</div>