import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBannerList, IShowCaseAvailiable, IShowCaseAvailiableV2 } from '@core/models/retailer/cms.model';
import { ListProductStockistResponse, ListProductStockistResponseV2, ProductParams } from '@core/models/retailer/product.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  baseURL = `${environment.apiCmsUrl}/`;
  constructor(
    private http: HttpClient
  ) { }

  getShowcaseList(): Observable<IBannerList[]> {
    return this.http.get<IBannerList[]>(this.baseURL + 'banners/available-banners');
  }

  getShowCaseAvailiable(): Observable<IShowCaseAvailiable[]> {
    return this.http.get<IShowCaseAvailiable[]>(
      `${environment.apiCmsUrl}/show-cases/available-show-cases`
    );
  }

  getShowCaseProduct(showCaseId: number, params: ProductParams): Observable<ListProductStockistResponse> {
    return this.http.get<ListProductStockistResponse>(
      `${environment.apiCmsUrl}/show-cases/${showCaseId}/products`,
      {
        params: ClearParams(params),
      }
    );
  }

  getShowCaseAvailiableV2(): Observable<IShowCaseAvailiableV2[]> {
    return this.http.get<IShowCaseAvailiableV2[]>(
      `${environment.apiCmsV2Url}/show-cases/available-show-cases`
    );
  }

  getShowCaseProductV2(showCaseId: number, params: ProductParams): Observable<ListProductStockistResponseV2> {
    return this.http.get<ListProductStockistResponseV2>(
      `${environment.apiCmsV2Url}/show-cases/${showCaseId}/products`,
      {
        params: ClearParams(params),
      }
    );
  }

  getBannerPromotionProduct(params: ProductParams, bannerId: number): Observable<ListProductStockistResponse> {
    return this.http.get<ListProductStockistResponse>(
      `${environment.apiCmsUrl}/banners/${bannerId}/products`,
      {
        params: ClearParams(params),
      }
    );
  }
}
