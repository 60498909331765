/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { IGift, IProductItem } from '@core/models/retailer/promotion-v2.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-my-cart-product-detail-v2',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class MyCartProductDetailV2Component implements OnInit {
    @Input() productDetail: IProductItem[] | undefined;
    @Input() gifts: IGift[] | undefined;

    constructor(
        private translate: TranslateService,
    ) { }

    currentLang = 'th';

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
    }
}
