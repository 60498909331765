import { Component, Input, OnInit } from '@angular/core';
import { IOrderItemPromotionDetail } from '@core/models/retailer/order-preview.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-product-promotion-order-list',
  templateUrl: './show-product-promotion-order-list.component.html',
  styleUrls: ['./show-product-promotion-order-list.component.scss']
})
export class ShowProductPromotionOrderListComponent implements OnInit {
  @Input() products: IOrderItemPromotionDetail[] = [];
  currentLang = 'th'
  imagePromotion = ''

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
  }

  getFormattedCurrency(amount: number | undefined) {
    return amount
    ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)}฿`
    : '';
  }

}
