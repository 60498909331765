import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBrandRecommend } from '@core/models/retailer/brand.model';
import { BrandService } from '@core/services/retailer/brand.service';
import { BrandParams } from '../all-recommend-brands/all-recommend-brands.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-recommend-brand',
    templateUrl: './recommend-brand.component.html',
    styleUrls: ['./recommend-brand.component.scss']
})

export class RecommendBrandComponent implements OnInit {
    recommendBrand: IBrandRecommend[] = []
    isLoading = false
    params: BrandParams = {
        limit: 20,
        page: 1,
    };
    currentLang!: string

    constructor(private route: Router, private brandService: BrandService, private spinner: NgxSpinnerService, private translate: TranslateService) { }
    ngOnInit(): void {
        this.currentLang = this.translate.currentLang
        this.spinner.show('spinner');
        this.isLoading = true;
        this.loadData()
    }

    loadData() {
        this.isLoading = true
        this.brandService.getRecommendBrand(this.params).subscribe({
            next: (res) => {
                this.recommendBrand = res.data
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
            error: (err) => {
                console.error(err)
                this.isLoading = false;
                this.spinner.hide('spinner');
            }
        })
    }

    onClickBrand(brandId: number) {
        this.route.navigate([`/retailer/all-product`, { 'brand-id': brandId }]);
    }
}
