<div *ngIf="orderDetail">
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigateToOrderHistory()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ACTION.BACK_PAGE" | translate }}</label>
        </div>
    </nav>

    <div class="bg-gradient-order-pending mt-2">
        <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
            <div>
                <p class="fs-sm" style="color: #8E8E8E;">{{ "TITLE.NUMBER" | translate }}</p>
                <p class="fw-bold">{{ orderDetail.id }}</p>
            </div>
            <div class="fs-sm fw-semibold order-status" [ngClass]="orderDetail.status.toLocaleLowerCase()">
                {{ "TITLE.WAIT_COMFIRM" | translate }}
            </div>
        </div>
        <div class="pb-3 px-3">
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-start align-items-center">

                    <div *ngIf="orderDetail?.status" class="fw-normal"
                        [attr.data-cy]="orderDetail.status.toLowerCase() + '-date'">
                        {{ "TITLE.ORDER_AT" | translate }}
                        {{ orderDetail.createdAt | displayDate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px-3">
        <div class="d-flex justify-content-between fw-light">
            <div class="order-history-detail-info d-flex align-items-center">
                <i class="fa-solid fa-truck"></i>
                <div class="ml-2">
                    <div class="order-text-header fs-sm pt-1">{{ "TITLE.DELIVER_FROM" | translate }}</div>
                    <div class="fs-6 fw-normal order-text-message">
                        {{orderDetail.stockistName}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="mb-4">
            <app-ship-to-address [name]="orderDetail.retailerName" [address]="getAddress()"></app-ship-to-address>
            <div class="border-line-bottom"></div>
            <div>
                <div class="">
                    <div class="fs-6 fw-semibold product-list-label pb-3">
                        <p>{{ "ORDER_INFO.PENDING.PRODUCT_LIST" | translate }}</p>
                        <app-show-product-promotion-order-list
                            [products]="orderDetail.items"></app-show-product-promotion-order-list>
                    </div>
                </div>
            </div>
            <div>
                <app-additional-privilege [additionalDiscounts]="orderDetail.additionalDiscounts"
                    [additionalGiveAways]="orderDetail.additionalGiveAways"
                    *ngIf="orderDetail.additionalDiscounts.length > 0 || orderDetail.additionalGiveAways.length >0"></app-additional-privilege>
                <div>
                    <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
                </div>
            </div>
            <div class="d-flex flex-column gap-3 mb-3">
                <button type="button" class="btn btn-outline-danger fw-semibold"
                    style="width: 100% !important; align-self: center" data-cy="cancel-order-btn"
                    (click)="onClickOpenCancelModal()">
                    {{ "TITLE.PURCHASE_CANCEL" | translate }}
                </button>
                <button type="button" class="btn btn-secondary-button fw-semibold"
                    style="width: 100% !important; align-self: center" data-cy="confirm-order-btn"
                    (click)="openReorderModal = !openReorderModal">
                    {{ "TITLE.ORDER_AGAIN" | translate }}
                </button>
            </div>
        </div>
    </div>
    <app-reason-cancellation-modal *ngIf="openCancelModal" [isOpenModal]="openCancelModal"
        [orderNumber]="orderDetail.id" (isOpenModalChange)="onClickCloseModal()"
        [currentOrderStatus]="orderDetail.status" />

    <app-reorder-modal-v2 *ngIf="openReorderModal" [isOpenModal]="openReorderModal" [orderNumber]="orderDetail.id"
        [products]="reOrderProducts" (isOpenModalChange)="onClickCloseModal()"
        [currentOrderStatus]="orderDetail.status" />
</div>