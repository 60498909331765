import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalMessageComponent } from '../../modal-message/modal-message.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTag } from '@core/models/shares/enum';
import { TranslateService } from '@ngx-translate/core';
import { CartManageProduct, IProductCart } from '@core/models/retailer/promotion-v2.model';

@Component({
  selector: 'app-modal-manage-product',
  templateUrl: './modal-manage-product.component.html',
  styleUrls: ['./modal-manage-product.component.scss']
})
export class ModalManageProductComponent {
  @Output() clickClose = new EventEmitter<void>();
  @Output() removeProduct = new EventEmitter<string>();

  @Input() cartSelected: CartManageProduct | undefined;
  @Input() promotionType = '';
  @Input() product: IProductCart | undefined;

  private modalRef: NgbModalRef | undefined;

  constructor(
    private route: Router,
    private modalService: NgbModal,
    private translate: TranslateService
  ) { }

  closeModal() {
    this.clickClose.emit()
  }

  removeProductFromCart(cartId: string) {
    this.removeProduct.emit(cartId)
  }

  openModal() {
    this.modalRef = this.modalService.open(ModalMessageComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Success,
      title: this.translate.instant('CART.CLOSE'),
      message: `${this.translate.instant('CART.BIRNG')} ${this.cartSelected?.promotionName}\n${this.translate.instant('CART.OUT')}`,
      confirmLabel: this.translate.instant('ACTION.CONFIRM'),
      cancelLabel: this.translate.instant('TITLE.CANCEL'),
    };
    this.modalRef.result.then(
      (confirm) => {
        if (confirm) {
          if (this.cartSelected?.cartId) {
            this.removeProductFromCart(this.cartSelected?.cartId);
            this.closeModal();
          }
        } else {
          this.closeModal();
        }
      },
    );
  }

  navigateToEditPromotionDetail() {
    if (this.cartSelected?.type === 'Bundle') {
      this.route.navigate([`/retailer/promotion-bundle/edit-cart/${this.cartSelected?.cartId}`]);
    } else if (this.cartSelected?.type === 'Tier') {
      this.route.navigate([`/retailer/promotion-tier/${this.product?.promotionId}`]);
    }
  }

  navigateToBundlePromotionDetail() {
    this.route.navigate([`/retailer/promotion-bundle/${this.cartSelected?.promotionId}`]);
  }
}
