import { Component, Input, OnInit } from '@angular/core';
import { AdditionalPrivilege } from '@core/models/retailer/createOrderResponse.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-additional-privilege-list',
  templateUrl: './show-additional-privilege-list.component.html',
  styleUrls: ['./show-additional-privilege-list.component.scss'],
})
export class ShowAdditionalPrivilegeListComponent implements OnInit {
  @Input() additionalPrivileges: AdditionalPrivilege[] = [];
  currentLang = 'th'
  isShow = false
  haveGift = false
  haveDiscount = false

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
    this.checkGiftAndDiscount();
  }

  changeShow() {
    this.isShow = this.isShow ? false : true
  }

  checkGiftAndDiscount() {
    for (const element of this.additionalPrivileges) {
      if (element.selectedGifts.length > 0) {
        this.haveGift = true;
        break;
      }
    }

    for (const element of this.additionalPrivileges) {
      if (element.discount > 0) {
        this.haveDiscount = true
        break;
      }
    }
  }

  getFormattedCurrency(amount: number) {
    return amount
      ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)}฿`
      : '';
  }
}
