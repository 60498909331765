<div id="myModal" class="modal">
    <div id="animate" class="modal-reorder">
        <div class="header">
            <span class="title">{{ "TITLE.EXAMINE_PRODUCT" | translate}}</span>
            <i class="fa-solid fa-xmark" (click)="close()"></i>
        </div>
        <div class="container mt-3">
            <div class="row mb-5">
                <div *ngFor="let product of products" class="mb-2">
                    <ng-container>
                        <div class="product-item-share align-items-start ">
                            <div class="image-container">
                                <img alt="Product image" [src]="product.coverImagePathThump"
                                    onerror="this.src='assets/images/image_error.jpg'" />
                                <!-- <div class="text-overlay">{{ "TITLE.EMPTY" | translate }}</div> -->
                            </div>
                            <div class="group-name mt-2">
                                <p class="product-name fs-sm text-left">
                                    {{ product.name }}
                                </p>
                            </div>

                            <div *ngIf="product.promotionType !== 'Tier'" class="group-quantity mt-2">
                                <div class="box-quantity">
                                    <p class="quantity fw-semibold">
                                        X{{ product.quantity }}
                                    </p>
                                </div>

                            </div>


                        </div>

                        <div class="mx-4 ">
                            <div *ngFor="let item of product.items">
                                <div class="d-flex justify-content-between align-items-center sub-product-name">
                                    <span>{{currentLang === "th" || item.nameEn === undefined ? item.name :
                                        item.nameEn}}</span>
                                    <span>X{{item.quantity}}</span>
                                </div>
                            </div>
                        </div>


                    </ng-container>
                </div>

                {{errorMessage}}
                <div class="fixed-bottom">
                    <div class="bottom p-3">
                        <button (click)="onReOrder()"
                            class="rounded-3 d-flex p-2 justify-content-center align-items-center gap-2 flex-fill"
                            style="width: 100%;">
                            {{ "ACTION.ADD_CART" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>