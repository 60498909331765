<div>
    <div class="search-box">
        <img src="assets/icons/Chevron_left.svg" (click)="navigateBack()">
        <div class="w-100">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}" (searchChange)="search($event)"
                [oldKeyword]="params.keywords"></app-input-search>
        </div>
    </div>


    <div infiniteScroll [infiniteScrollDistance]="20" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
        style="padding-bottom: 4rem;">
        <div>
            <div class="productlist container-fluid d-flex justify-content-start row row-cols-2 row-cols-md-4">
                <div class="card-loop" *ngFor="let item of productList; let i = index" [attr.data-cy-row-number]="i+1">
                    <app-card-item-product [item]="item" class="d-flex justify-content-center" [filter]="params" />
                </div>
            </div>
        </div>
        <div id="loading-all-product" *ngIf="isLoading" class="overlay">
            <ngx-spinner bdColor="rgb(0,0,0,0)" size="small" color="#21C063" type="ball-clip-rotate"
                [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
        </div>
    </div>
    <div class="position-absolute start-50 translate-middle" style="top: 57%; width: 90%;"
        *ngIf="showNotFoundMessage()">
        <div class="row justify-content-center">
            <img src="assets/icons/not_found_favorite.svg" width="256px" height="256px">
            <div class="text-center not-found-title">{{
                'TITLE.EMPTY_FAVORITE' |
                translate}}
            </div>
            <div class="text-center not-found-des">{{ 'TITLE.EMPTY_FAVORITE_DES' |
                translate}}</div>
        </div>

    </div>
</div>