import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return value.replace(urlRegex, (url) => {
      return `<a href="${url}" rel="noopener noreferrer">${url}</a>`;
    });
  }
}
