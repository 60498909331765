<div *ngFor="let item of giftItem; let stockistIndex = index">
    <div *ngFor="let additional of item.additionalPrivileges; let additionalIndex = index">
      <div *ngIf="additional.additionalPrivilegeGroups.length > 0" class="selected-product-container">
        <div class="gift-list-title d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img [src]="'assets/icons/pink-gift.svg'" />
            <span class="gift-name">{{ additional.name }}</span>
          </div>
          <p class="gift-amount">
            {{ countSelectedForPrivilege(stockistIndex, additionalIndex) + '/' + additional?.additionalPrivilegeGroups?.length }}
          </p>
        </div>
        <!-- -------------------------- Gift List -------------------------------------------------- -->
        <div *ngFor="let giftLevel of additional?.additionalPrivilegeGroups; let giftGroupIndex = index" class="selected-gift-container">
          <div class="content-gift-list-item">
            <p class="gift-list-item">{{ 'SUMMARY.GIFT' | translate }} {{ giftGroupIndex + 1 }}</p>
            <div class="gift-selected-count">
              {{ checkIsSelectedGift(stockistIndex, additionalIndex, giftGroupIndex) ? '1' : '0' }}/1
            </div>
          </div>
          <div *ngFor="let giftDetail of giftLevel.giftItem; let giftItemIndex = index">
            <div class="content-gift-list-item">
              <div class="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  (click)="onSelectGift(stockistIndex, additionalIndex, giftGroupIndex, giftDetail, item.stockistId,additional.promotionId)"
                  [id]="additional.promotionId + '_' + giftItemIndex + '_' + giftGroupIndex + '_' + stockistIndex"
                  [name]="additional.promotionId + '_' + giftGroupIndex + '_' + stockistIndex"
                  [value]="giftDetail.giftId"
                  class="custom-radio"
                />
                <label [for]="additional.promotionId + '_' + giftItemIndex + '_' + giftGroupIndex + '_' + stockistIndex"></label>
                <img class="gift-item-image" alt="giftImage" [src]="giftDetail.coverImagePathThump" (error)="onImageError($event)"/>
                <label [for]="additional.promotionId + '_' + giftItemIndex + '_' + giftGroupIndex + '_' + stockistIndex" class="gift-name card-text-stockist">
                  {{ currentLang === 'th' ? giftDetail.name : giftDetail.nameEn }}
                </label>
              </div>
              <span class="gift-amount">x{{ giftDetail.quantity }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>