import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFilterOption } from '@core/models/retailer/brand.model';
import { ProductParams } from '@core/models/retailer/product.model';
import { Stockist } from '@core/models/retailer/stockist.model';
import { BrandService } from '@core/services/retailer/brand.service';
import { CategoryService } from '@core/services/retailer/category.service';
import { ProductService } from '@core/services/retailer/product.service';
import { FilterEvent } from '../search/search.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-product-modal',
  templateUrl: './filter-product-modal.component.html',
  styleUrls: ['./filter-product-modal.component.scss']
})
export class FilterProductModalComponent implements OnInit {
  @Input({ required: true }) isOpenModal!: boolean;
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  categoryOption: IFilterOption[] = [];
  brandOption: IFilterOption[] = [];
  stockistOption: IFilterOption[] = [];
  @Input() selectedCategory: number[] = [];
  @Input() selectedBrand: number[] = [];
  @Input() selectedStockist: number[] = [];
  @Input() orderBy: string | null = null;
  @Input() limit = 10;
  @Output() filterEvent = new EventEmitter<FilterEvent>();
  @Output() closeFIlterEvent = new EventEmitter<FilterEvent>();

  selectedHistoryCategory: number[] = [];
  selectedHistoryBrand: number[] = [];
  selectedHistoryStockist: number[] = [];
  orderByHistory: string | null = null;

  params: ProductParams = {
    limit: 50,
    page: 1,
    datatable: true
  }
  filterByOption = [
    {
      name: '',
      value: ''
    }
  ]

  category!: string
  brand!: string
  store!: string

  local!: string

  constructor(
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.local = this.translate.currentLang;
    this.filterByOption = [
      {
        name: this.translate.instant('FILTER.LOW'),
        value: 'Price_asc'
      },
      {
        name: this.translate.instant('FILTER.HIGH'),
        value: 'Price_desc'
      }
    ];
    this.category = this.translate.instant('MENU.CATEGORY');
    this.brand = this.translate.instant('MENU.BRAND');
    this.store = this.translate.instant('MENU.STORE');

    this.getCategoryLevel1()
    this.getBrands()
    this.getStockist()
    this.params.limit = this.limit
    this.selectedHistoryBrand = [...this.selectedBrand]
    this.selectedHistoryCategory = [...this.selectedCategory]
    this.selectedHistoryStockist = [...this.selectedStockist]
    this.orderByHistory = this.orderBy
  }

  close() {
    const totalSelected = this.selectedHistoryCategory.length + this.selectedHistoryBrand.length + this.selectedHistoryStockist.length
    this.params = {
      ...this.params,
      ...(this.selectedHistoryCategory.length > 0 && { categoryIds: this.selectedHistoryCategory }),
      ...(this.selectedHistoryBrand.length > 0 && { brandIds: this.selectedHistoryBrand }),
      ...(this.selectedHistoryStockist.length > 0 && { stockistIds: this.selectedHistoryStockist }),
      ...(this.orderByHistory && { orderBy: this.orderByHistory })
    };
    this.closeFIlterEvent.emit({
      params: this.params,
      totalSelected: totalSelected
    });
    this.isOpenModal = false;
    this.isOpenModalChange.emit(this.isOpenModal);
  }

  onInputChecked(optionId: number, type: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    switch (type) {
      case 'category':
        this.updateFilterValue(this.selectedCategory, optionId, isChecked);
        break;
      case 'brand':
        this.updateFilterValue(this.selectedBrand, optionId, isChecked);
        break;
      case 'stockist':
        this.updateFilterValue(this.selectedStockist, optionId, isChecked);
        break;
    }
  }

  isChecked(optionId: number, type: string): boolean {
    switch (type) {
      case 'category':
        return this.selectedCategory.includes(optionId);
      case 'brand':
        return this.selectedBrand.includes(optionId);
      case 'stockist':
        return this.selectedStockist.includes(optionId);
      default:
        return false;
    }
  }

  updateFilterValue(array: number[], index: number, isChecked: boolean) {
    if (isChecked) {
      if (!array.includes(index)) {
        array.push(index);
      }
    } else {
      const idx = array.indexOf(index);
      if (idx > -1) {
        array.splice(idx, 1);
      }
    }
  }

  showSelectedNumber(filterId: string) {
    switch (filterId) {
      case 'category':
        return this.selectedCategory.length;
      case 'brand':
        return this.selectedBrand.length;
      case 'stockist':
        return this.selectedStockist.length;
      default:
        return 0
    }
  }

  resetFilterValue() {
    this.selectedBrand = [];
    this.selectedCategory = [];
    this.selectedStockist = [];
    this.orderBy = null;
  }

  getCategoryLevel1() {
    this.categoryService.getCategoryLevel1().subscribe({
      next: (res) => {
        this.categoryOption = res
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  getBrands() {
    this.brandService.getBrand().subscribe({
      next: (res) => {
        this.brandOption = res
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  getStockist() {
    this.productService.getStockistsAvailable().subscribe({
      next: (res) => {
        if (Array.isArray(res)) {
          this.stockistOption = res.map(
            (stockist) => new Stockist(stockist)
          );
        }
      },
      error: (err) => {
        console.error(err)
      }
    });
  }

  submit() {
    const { selectedCategory, selectedBrand, selectedStockist, orderBy } = this;
    const totalSelected = selectedCategory.length + selectedBrand.length + selectedStockist.length
    this.params = {
      ...this.params,
      ...(selectedCategory.length > 0 && { categoryIds: selectedCategory }),
      ...(selectedBrand.length > 0 && { brandIds: selectedBrand }),
      ...(selectedStockist.length > 0 && { stockistIds: selectedStockist }),
      ...(orderBy && { orderBy: orderBy })
    };
    this.filterEvent.emit({
      params: this.params,
      totalSelected: totalSelected
    });
    this.isOpenModal = false;
    this.isOpenModalChange.emit(this.isOpenModal);
  }
}
