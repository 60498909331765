<div class="loading-section" *ngIf="loading_data">
  <div class="spinner-border"></div>
</div>
<div class="w-100" *ngIf="PromotionRecommendList.length > 0 && !loading_data">
  <!-- title-bar -->
  <div class="title">
    <div class="title-bg d-flex justify-content-start ">
      <div class="title-icon">
        <img src="assets/icons/recommend.svg">
      </div>
      <div class="title-text">
        <label class="title-text-main">
          <b>{{ 'PROMOTION_RECOMMEND.TITLE' | translate }}</b>
        </label>
        <br>
        <label class="title-text-sub">
          {{ 'PROMOTION_RECOMMEND.TITLE_SUB' | translate }}
        </label>
      </div>
    </div>
  </div>

  <!-- recommend-list -->
  <div class="item-list d-flex justify-content-start">
    <ng-container *ngFor="let item of PromotionRecommendList">
      <div class="card-recommend" align="center">
        <div class="layout-image">
          <img class="recommend-image" [src]="item.promotionCoverImage" onerror="this.src='assets/images/image_error.jpg'">
          <div class="promotion-sticker" *ngIf="item.imagePromotion">
            <img class="promotion-icon"  [src]="item.imagePromotion" width="60" height="60" onerror="this.src='assets/images/image_error.jpg'" />
          </div>
        </div>

        <div class="recommend-detail">
          <div class="item-detail d-flex justify-content-start" align="left">
            <div>
              <img [src]="item.privilegeType === 'Discount' ? 'assets/icons/cent.svg' : 'assets/icons/gift.svg'">
            </div>
            <div class="text-detail">
              <label *ngIf="item.privilegeType === 'Discount'" class="detail-text">{{ 'PROMOTION_RECOMMEND.DISCOUNT' | translate }}</label>
              <label *ngIf="item.privilegeType === 'Gift'" class="detail-text">{{ 'PROMOTION_RECOMMEND.GIFT' | translate }}</label>
              <br>
              <label *ngIf="item.privilegeType === 'Discount' && item.amount" class="detail-discount"><b><app-custom-currency [value]="item.amount" integerSize="20px" fractionalSize="16px"></app-custom-currency></b></label>
              <label *ngIf="item.privilegeType === 'Gift'  && item.amount" class="detail-discount"><b>{{item.amount}} {{ 'PROMOTION_RECOMMEND.UNIT' | translate }}</b></label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
