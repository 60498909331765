import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerPurchaseOrderHistory } from '@core/models/retailer/retailer-purchase-order';
import { RetailerOrderStatus } from '@core/models/retailer/retailer-enum';
import { UserService } from '@core/services/auth/user.service';
import { Pagination, PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { IHistoryOrderMainBody } from '@core/models/retailer/history.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

const limit = 5

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements OnInit {
    retailerPurchaseOrderHistories: RetailerPurchaseOrderHistory[] = [];
    orderHistoryList: IHistoryOrderMainBody[] = []
    page = 1;
    havenextPage = true
    isLoading = true

    constructor(
        private router: Router,
        private userService: UserService,
        private purchaseOrderService: PurchaseOrderService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService

    ) { }

    ngOnInit(): void {
        // this.orderHistoryList = mockData
        this.getOrderHistories();
    }

    getStatusLabel(status: string): string {
        switch (status.toUpperCase()) {
            case RetailerOrderStatus.PENDING:
                return this.translate.instant('HISTORY_STATUS.PENDING');
            case RetailerOrderStatus.CONFIRMED:
                return this.translate.instant('HISTORY_STATUS.CONFIRM');
            case RetailerOrderStatus.CANCELREQUESTED:
                return this.translate.instant('HISTORY_STATUS.REQUEST');
            case RetailerOrderStatus.CANCELLED:
                return this.translate.instant('HISTORY_STATUS.CANCEL');
            case RetailerOrderStatus.DELIVERED:
                return this.translate.instant('HISTORY_STATUS.DELIVER');
            case RetailerOrderStatus.DEPRECATED:
                return this.translate.instant('HISTORY_STATUS.DEPRECATED');
            default:
                return 'Unknown status';
        }
    }

    getOrderHistories() {
        this.isLoading = true
        this.spinner.show('spinner');
        const params: Pagination = {
            limit: limit,
            page: this.page
        }
        this.purchaseOrderService.getOrderHistory(params).subscribe({
            next: (res) => {
                this.orderHistoryList = this.orderHistoryList.concat(res)
                this.isLoading = false
                this.spinner.hide('spinner');
                if (res.length < limit) {
                    this.havenextPage = false
                }
            }, error: (err) => {
                console.error(err)
                this.isLoading = false
                this.spinner.hide('spinner');
            }
        })
    }

    onSelectPurchaseOrder(
        orderId: string,
    ) {
        window.localStorage.setItem('page', 'history');
        const targetPath = `/retailer/order-history/${orderId}`;
        this.router.navigate([targetPath]);
    }

    onClickLoadMore() {
        this.page++
        this.getOrderHistories()
    }

    goToProductsPage() {
        this.router.navigate(['retailer/products']);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if ((window.innerHeight + 10 + window.scrollY) >= document.body.offsetHeight && !this.isLoading && this.havenextPage && this.page !== undefined) {
            this.onClickLoadMore();
        }
    }
    formatCurrency(amount: number ) {
        return amount
        ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)}฿`
        : '';
    }
}
