import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    IValidateOrderPayload,
} from '@core/models/retailer/cart.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartPayload, ICart, IProductCartSelect, IValidatePayload, SelectGiftPayload } from '@core/models/retailer/promotion-v2.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { IVerifyCartPayload } from '@core/models/retailer/verify-cart-payload.model';
import { TranslateService } from '@ngx-translate/core';

export interface CheckStockist {
    stockistsId: number;
    checked: boolean;
}

export interface UpdateCartAmount {
    productId: number;
    newQuntity: number;
}

@Component({
    selector: 'app-my-cart',
    templateUrl: './my-cart.component.html',
    styleUrls: ['./my-cart.component.scss'],
})
export class MyCartV2Component implements OnInit, OnDestroy {
    constructor(
        private route: Router,
        private cartService: CartV2Service,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,

    ) { }
    isEmpty = true;
    cart: ICart[] = [];
    totalCart = 0;
    selectedProduct: IValidateOrderPayload[] = [];
    reOrderProducts: IProductCartSelect[] = [];
    isManageMode = false;
    checkedAllStockist = false;
    isOnInit = false;
    showModal = false;
    isLoading = false;
    selectGiftPayload: SelectGiftPayload[] = [];
    additionalPrivileges: SelectGiftPayload[] = [];
    currentLang = 'th'

    ngOnDestroy(): void {
        window.localStorage.removeItem('reOrderCartList');
    }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.isLoading = true
        this.spinner.show('spinner');
        this.reOrderProducts = JSON.parse(
            localStorage.getItem('reOrderCartList') ?? '[]'
        );
        this.getMyCart();
    }

    goToProductsPage() {
        this.route.navigate(['retailer/products']);
    }

    updateCartAmout(amount: number): number {
        return (this.totalCart = amount);
    }

    getMyCart() {
        this.cartService.getMyCart().subscribe({
            next: (res) => {
                this.cart = res;
                if (res && res.length > 0) {
                    this.isEmpty = false;
                } else {
                    this.isEmpty = true;
                }
                if (!this.isOnInit) {
                    if (this.reOrderProducts.length > 0) {
                        for (const item of this.reOrderProducts) {
                            this.onSelectProduct(item);
                        }
                    }
                }
                this.isLoading = false
                this.spinner.hide('spinner');
                this.isOnInit = true;
            }, error: () => {
                this.isLoading = false
                this.spinner.hide('spinner');
            }
        });
    }

    clickCloseModal(): void {
        this.showModal = false;
    }

    onSelectStockist(stockistsData: CheckStockist) {
        const stockistIndex = this.cart.findIndex(
            (x) => x.stockistId === stockistsData.stockistsId
        );
        if (stockistIndex !== -1) {
            if (!stockistsData.checked) {
                this.cart[stockistIndex].itemInCart.forEach((item) => {
                    item.isSelected = true;
                });
            } else {
                this.cart[stockistIndex].itemInCart.forEach((item) => {
                    item.isSelected = false;
                });
            }
        }
        this.checkedAllStockist = this.cart.every((stockist) =>
            stockist.itemInCart.every((product) => product.isSelected === true)
        );
    }

    onSelectProduct(productData: IProductCartSelect) {
        const stockistIndex = this.cart.findIndex((i) =>
            i.itemInCart.find(
                (o) => o.cartId === productData.cartId
            )
        );

        const productIndex = this.cart[stockistIndex].itemInCart.findIndex(
            (i) => i.cartId === productData.cartId
        );

        const isSelected = this.cart[stockistIndex].itemInCart[productIndex].isSelected;
        this.cart[stockistIndex].itemInCart[productIndex].isSelected = !isSelected;

        if (!this.isManageMode) {
            this.updateCartAmout(this.calculateTotalPrice());
        }
    }

    onSelectAll() {
        this.cart.forEach((stockist) => {
            stockist.itemInCart.forEach((item) => {
                item.isSelected = this.checkedAllStockist;
            });
        });
    }

    onProductChanges(product: CartPayload) {
        const stockistIndex = this.cart.findIndex((i) => i.itemInCart.find((o) => o.cartId === product.cartId));
        const productIndex = this.cart[stockistIndex].itemInCart.findIndex((i) => i.cartId === product.cartId);
        if (product.quantity == 0) {
            const filteredItems = this.cart[stockistIndex].itemInCart.filter((item) => item.cartId !== product.cartId);
            this.cart = this.cart
                .map((stockist, i) => {
                    if (i === stockistIndex) {
                        return {
                            ...stockist,
                            itemInCart: filteredItems,
                        };
                    }
                    return stockist;
                })
                .filter((stockist) => stockist.itemInCart.length > 0);
        } else {
            this.cart[stockistIndex].itemInCart[productIndex].quantity = product.quantity;
        }
        this.updateCartAmout(this.calculateTotalPrice());
        this.cartService.updateCartItems([product]).subscribe()
        this.isEmpty = this.cart.length > 0 ? false : true
    }

    calculateTotalPrice(): number {
        return this.cart.reduce((totalPrice, stockistProduct) => {
            const selectedItems = stockistProduct.itemInCart.filter((data) => data.isSelected);
            const stockistTotal = selectedItems.reduce((subtotal, product) => {
                switch (product.type) {
                    case 'Bundle':
                        return (
                            subtotal +
                            (product.amountPerUnit * product.quantity) -
                            (product.discountPerUnit * product.quantity)
                        );
                    case 'Tier':
                        return subtotal + product.totalAfterDiscount;
                    default:
                        return subtotal + (product.amountPerUnit * product.quantity);
                }
            }, 0);

            return totalPrice + stockistTotal;
        }, 0);
    }

    onReviewOrder() {
        this.getaditionalPrivilegeHandler()
    }

    onValidateOrder() {
        this.showLoader(true)
        const payload: IVerifyCartPayload[] = this.cart
            .filter(stockist => stockist.itemInCart.some(item => item.isSelected))
            .map(stockist => ({
                stockistId: stockist.stockistId,
                additionalPrivilege: [],
                cartId: stockist.itemInCart
                    .filter(item => item.isSelected)
                    .map(selectedItem => selectedItem.cartId)
            }));
        this.cartService.postverifyCart(payload).subscribe({
            next: () => {
                this.showLoader(false)
                this.navigateToSummary()
            },
            error: (error) => {
                this.showLoader(false)
                console.error('Error fetching additional privileges:', error);
            }
        })
    }

    navigateToSummary() {
        this.route.navigate(['/retailer/summary-order']);
    }

    getaditionalPrivilegeHandler() {
        const payload: IValidatePayload[] = this.cart
            .filter(stockist => stockist.itemInCart.some(item => item.isSelected))
            .map(stockist => ({
                stockistId: stockist.stockistId,
                cartId: stockist.itemInCart
                    .filter(item => item.isSelected)
                    .map(selectedItem => selectedItem.cartId)
            }));

        this.cartService.getAdditionalPrivilege(payload).subscribe({
            next: (res: SelectGiftPayload[]) => {
                this.selectGiftPayload = res
                const hasAdditionalPrivilege = res.some(item => item.additionalPrivileges?.some(privilege => privilege.additionalPrivilegeGroups.length !== 0));
                if (hasAdditionalPrivilege) {
                    this.showLoader(false)
                    this.showModal = true;
                } else {
                    this.onValidateOrder()
                }
            },
            error: (error) => {
                console.error('Error fetching additional privileges:', error);
            }
        });
    }

    onDeleteOrder() {
        let payload: CartPayload[] = [];
        const stockistsData = this.cart.filter((i) =>
            i.itemInCart.some((b) => b.isSelected === true)
        );
        stockistsData.forEach((stockist) => {
            const selectedItems = stockist.itemInCart
                .filter((product) => product.isSelected === true)
                .map((filtedProduct) => ({
                    cartId: filtedProduct.cartId,
                    quantity: 0,
                })) as CartPayload[];

            payload = payload.concat(selectedItems);
            const unselectedItems = stockist.itemInCart.filter(
                (product) => product.isSelected === false
            );

            this.cart = this.cart.map((currentStockist) => {
                if (currentStockist.stockistId == stockist.stockistId) {
                    return {
                        ...currentStockist,
                        itemInCart: unselectedItems,
                    };
                }
                return currentStockist;
            }).filter((stockist) => stockist.itemInCart.length > 0);
        });
        this.cartService.updateCartItems(payload).subscribe();
        this.isManageMode = this.cart.length > 0 ? true : false
        this.isEmpty = this.cart.length > 0 ? false : true
    }

    manageCart() {
        this.isManageMode = !this.isManageMode;
        this.cart.forEach((stockist) => {
            stockist.itemInCart.forEach((item) => {
                item.isSelected = false;
            });
        });
        this.checkedAllStockist = false;
    }

    isButtonEnabled(): boolean {
        return this.cart.some((stockist) =>
            stockist.itemInCart.some((item) => item.isSelected)
        );
    }

    showLoader(show: boolean) {
        this.isLoading = show
        if (show) {
            this.spinner.show('spinner');
            return
        }
        this.spinner.hide('spinner');
    }
}
