/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/auth/user.service';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { NotificationService } from '@core/services/retailer/notification.service';
import { ConfigService } from '@core/services/shared/config.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

declare global {
    interface Window {
        zE: any;
    }
}

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
    cartItemsCount = 0;
    notificationCount = 0;
    typeUser = '';
    private itemCountSubscription: Subscription;
    private cartChangedSubscription: Subscription;
    private notificationCountSubscription: Subscription;
    currentEnv = '';
    appVersion = '';


    constructor(
        private router: Router,
        private authUserService: UserService,
        private cartService: CartV2Service,
        private notificationService: NotificationService,
        private configService: ConfigService
    ) {
        this.itemCountSubscription =
            this.cartService.currentItemCount.subscribe(
                (count) => (this.cartItemsCount = count)
            );

        this.notificationCountSubscription =
            this.notificationService.currentItemCount.subscribe(
                (count) => (this.notificationCount = count)
            );

        this.cartChangedSubscription = this.cartService.cartChanged.subscribe(
            () => this.cartService.getMyCart().subscribe()
        );

        this.notificationService.getNotificationCount().subscribe({
            next: (res) => {
                this.notificationCount = res.unreadNotification;
            },
        });
    }

    ngOnInit() {
        this.currentEnv = environment.name
        this.getUserType();
        this.cartService.getMyCart().subscribe();
        this.appVersion = this.configService.getAppVersion();
    }

    ngOnDestroy() {
        this.itemCountSubscription.unsubscribe();
        this.cartChangedSubscription.unsubscribe();
    }

    getUserType() {
        const user = localStorage.getItem('user') || '';
        if (user) {
            this.typeUser = JSON.parse(user).profile.type;
        }
    }

    getDisplayedCartItemsCount(): number {
        return this.cartItemsCount > 99 ? 99 : this.cartItemsCount;
    }

    goToCart() {
        this.appVersion === 'v1' ? this.router.navigate(['/retailer/my-cart']) : this.router.navigate(['/retailer/my-cart-v2']);
    }

    goToNotification() {
        this.router.navigate(['/retailer/my-notification']);
    }

    imageLineLogo = 'assets/images/LINE_Brand_icon.png';

    logout() {
        this.router.navigate(['/auth']);
        this.authUserService.clearUser();
    }

    openChat() {
        const scriptId = 'ze-snippet';

        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `${environment.zendeskUrl}`;

            script.onload = function () {
                if (window.zE) {
                    window.zE('messenger', 'open');
                }
            };

            document.body.appendChild(script);
        } else if (window.zE) {
            window.zE('messenger', 'open');
        }
    }
}
