<div *ngIf="orderDetail">
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigateToOrderHistory()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ACTION.BACK_PAGE" | translate }}</label>
        </div>
    </nav>

    <div class="bg-gradient-order-cancled mt-2">
        <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
            <div>
                <p class="fs-sm" style="color: #8E8E8E;">{{ "TITLE.NUMBER" | translate }}</p>
                <p class="fw-bold">{{ orderDetail.id }}</p>
            </div>
            <div class="fs-sm fw-semibold order-status text-center" [ngClass]="orderDetail.status.toLocaleLowerCase()">
                {{ "TITLE.WANT_CANCEL" | translate }}
            </div>
        </div>
        <div class="pb-3 px-3">
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-start align-items-center">

                    <div *ngIf="orderDetail?.status" class="fw-normal "
                        [attr.data-cy]="orderDetail.status.toLowerCase() + '-date'">
                        {{ "TITLE.WANT_CANCEL_AT" | translate }}
                        {{ orderDetail.cancelRequestedDate | displayDate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">

        <div class="mb-4">

            <div class="fs-sm mb-3 d-flex align-items-center gap-2"
                style="background-color: #EFEFEF;padding: 10px;border-left: 4px solid #505050; margin: 0 -16px; color: #505050;">
                <img src="assets/icons/receipt-cancle.svg" width="16px" height="16px" />
                <p>
                    {{ "TITLE.CANCEL_ALERT" | translate }}
                </p>

            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="d-flex justify-content-between fw-light">
            <div class="order-history-detail-info d-flex align-items-center">
                <i class="fa-solid fa-calendar"></i>
                <div class="ml-2">
                    <div class="order-text-header fs-sm">{{ "TITLE.EXPECTED_DELIVERY_DATE" | translate }}
                    </div>
                    <div class="fs-6 fw-normal order-text-message">
                        {{orderDetail.deliveryDate | displayDeliveryDate}}, {{formatTime(orderDetail.officeTime)}}
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between fw-light">
            <div class="order-history-detail-info d-flex align-items-center">
                <i class="fa-solid fa-truck"></i>
                <div class="ml-2">
                    <div class="order-text-header fs-sm pt-1">{{ "TITLE.DELIVER_FROM" | translate }}</div>
                    <div class="fs-6 fw-normal order-text-message">
                        {{orderDetail.stockistName}}
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="mb-4">
            <app-ship-to-address [name]="orderDetail.retailerName" [address]="getAddress()"></app-ship-to-address>
            <div class="border-line-bottom"></div>
            <div>
                <div class="">
                    <div class="fs-6 fw-semibold product-list-label pb-3">
                        <p>{{ "TITLE.PRODUCT" | translate }}</p>
                    </div>
                </div>
                <div class="">
                    <app-show-product-promotion-order-list
                        [products]="orderDetail.items"></app-show-product-promotion-order-list>

                </div>
            </div>
            <div>
                <app-additional-privilege [additionalDiscounts]="orderDetail.additionalDiscounts" [additionalGiveAways]="orderDetail.additionalGiveAways" *ngIf="orderDetail.additionalDiscounts.length > 0 || orderDetail.additionalGiveAways.length >0"></app-additional-privilege>
                <div>
                    <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
                </div>
            </div>
            <div *ngIf="orderDetail.refOrderId"
                class="my-3 change-order-tab d-flex justify-content-between align-items-center p-2">
                <div class="d-flex align-items-center gap-2">
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <p class="fs-xs" style="color: #505050;">{{ "TITLE.ORDER_CHANGE" | translate }}</p>
                </div>
                <button class="btn-old-order fs-sm fw-md" (click)="navigateToOldOrder()">{{ "TITLE.VIEW_ORDER" |
                    translate }} <i class="fa-solid fa-arrow-right" aria-hidden="true"></i></button>
            </div>
        </div>
    </div>
</div>