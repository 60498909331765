import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrderDetailV2 } from '@core/models/retailer/order-preview.model';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { APP_NAME } from '@helpers/app.constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ItemReOrder } from '../reorder-modal-v2/reorder-modal-v2.component';

@Component({
  selector: 'app-order-history-detail-confirmed-v2',
  templateUrl: './order-history-detail-confirmed-v2.component.html',
  styleUrls: ['./order-history-detail-confirmed-v2.component.scss']
})
export class OrderHistoryDetailConfirmedV2Component implements OnInit {
  @Input() orderDetail: IOrderDetailV2 | undefined
  openCancelModal = false
  addressRetailer = '';
  isLateDelivered = false
  openReorderModal = false
  reOrderProducts: ItemReOrder[] = [];
  appName = APP_NAME;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private translate: TranslateService
  ) { }

  private modalRef: NgbModalRef | undefined;

  ngOnInit(): void {
    if (this.orderDetail) {
      this.orderDetail.items.forEach((item) => {
        let promotionType: "Product" | "Bundle" | "Tier" = "Product"
        if (item.productId) {
          promotionType = "Product"
        } else if (item.promotionBunleId) {
          promotionType = "Bundle"
        } else if (item.promotionTierId) {
          promotionType = "Tier"
        }
        const itemProduct: ItemReOrder = {
          promotionType: promotionType,
          coverImagePathThump: item.coverImagePathThumb,
          name: item.name,
          nameEn: item.nameEn,
          price: item.amount,
          quantity: item.quantity,
          items: item.orderProducts
        }
        this.reOrderProducts.push(itemProduct)
      })
    }

  }

  getAddress() {
    return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
  }

  navigateToOrderHistory() {
    const page = window.localStorage.getItem('page');

    if (page) {
      if (page === 'notification') {
        this.router.navigate(['retailer/my-notification']);
      } else if (page === 'history') {
        this.router.navigate(['retailer/order-history']);
      }
    } else {
      this.router.navigate(['retailer/order-history']);
    }

    window.localStorage.removeItem('page')
  }

  navigateToOldOrder() {
    this.router.navigateByUrl(`retailer/order-history/${this.orderDetail?.refOrderId}`).then(() => window.location.reload())
  }

  onClickOpenCancelModal() {
    this.openCancelModal = true
  }

  onClickCloseModal() {
    this.openCancelModal = false
    this.openReorderModal = false
  }

  checkLateDelivered() {
    if (this.orderDetail) {
      return new Date(this.orderDetail.deliveryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    }
    return false

  }

  formatTime(officeTime: string): string {
    if (officeTime) {
      const [startTime, endTime] = officeTime.split("-");
      const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
      const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
      return `${formattedStartTime} - ${formattedEndTime} น.`;
    }
    return '';

  }

  getTotalQuantity(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.quantity,
        0
      );

      return totalQuantity;
    }

    return 0;
  }
  getTotalAmount(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.amount,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  getNetAmount(): number {
    if (!this.orderDetail) return 0
    const netAmount = this.orderDetail.netAmount;
    return netAmount

  }

  getPaymentInfo(): PaymentInfo {
    return <PaymentInfo>{
      totalQuantity: this.getTotalQuantity(),
      totalAmount: this.getTotalAmount(),
      couponDiscount: this.orderDetail?.couponDiscount,
      promotionDiscount: this.orderDetail?.promotionDiscount,
      shippingCost: this.orderDetail?.shippingCost,
      netAmount: this.getNetAmount(),
    };
  }

}
