import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { orderProduct } from '@core/models/retailer/order-preview.model';
import { CartService } from '@core/services/retailer/cart.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalMessageAlertComponent } from '../modal-message-alert/modal-message-alert.component';

export interface ItemReOrder {
  promotionType: "Bundle" | "Tier" | "Product"
  coverImagePathThump: string
  name: string
  nameEn?: string | undefined
  items: orderProduct[]
  quantity: number
  price: number
}


@Component({
  selector: 'app-reorder-modal-v2',
  templateUrl: './reorder-modal-v2.component.html',
  styleUrls: ['./reorder-modal-v2.component.scss']
})
export class ReorderModalV2Component {
  @Input() isOpenModal!: boolean;
  @Input() orderNumber!: string;
  @Input() products: ItemReOrder[] = [];
  @Input() currentOrderStatus = ''
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  isFormValid = false;
  otherReason = '';
  showError = false;
  reason5Checked = false;
  formattedReason = {};
  errorMessage = ""
  currentLang = "th"

  reasonForm: FormGroup;

  private modalRef: NgbModalRef | undefined;

  constructor(private fb: FormBuilder, private cartService: CartService, private router: Router, private translate: TranslateService, private modalService: NgbModal,) {
    this.currentLang = this.translate.currentLang
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    });
  }

  close() {
    this.isOpenModal = false;
    this.isOpenModalChange.emit(this.isOpenModal);
  }

  onReOrder() {
    if (this.orderNumber) {
      this.cartService.reOrderV2(this.orderNumber).subscribe({
        next: (res) => {
          const cartList = JSON.stringify(res)
          localStorage.setItem("reOrderCartList", cartList)
          this.router.navigate(['/retailer/my-cart-v2'])
        },
        error: (err) => {
          this.close()
          this.modalRef = this.modalService.open(ModalMessageAlertComponent, {
            centered: true,
            backdropClass: 'custom-modal-backdrop'
          });
          this.modalRef.componentInstance.modalConfig = {
            message: this.translate.instant('ORDER_ERROR.ERROR_PRODUCT')
          };
          this.errorMessage = err.Message
          console.error(err)
        }
      })
    }

  }
}
