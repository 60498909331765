import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './components/products/products.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { MyCartComponent } from './components/my-cart/my-cart.component';
import { ReviewOrderComponent } from './components/review-order/review-order.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
// import { OrderHistoryDetailPendingComponent } from './components/order-history-detail-pending/order-history-detail-pending.component';
import { OrderHistoryDetailCancelrequestedComponent } from './components/order-history-detail-cancelrequested/order-history-detail-cancelrequested.component';
import { OrderHistoryDetailCancelledComponent } from './components/order-history-detail-cancelled/order-history-detail-cancelled.component';
import { OrderHistoryDetailConfirmedComponent } from './components/order-history-detail-confirmed/order-history-detail-confirmed.component';
import { OrderHistoryDetailDeliveredComponent } from './components/order-history-detail-delivered/order-history-detail-delivered.component';
import { OrderHistoryDetailDeprecatedComponent } from './components/order-history-detail-deprecated/order-history-detail-deprecated.component';
import { AllProductComponent } from './components/all-product/all-product.component';
import { OrderHistoryDetailMainComponent } from './components/order-history-detail-main/order-history-detail-main.component';
import { OrderProcessedCancelledComponent } from './components/order-processed-cancelled/order-processed-cancelled.component';
import { OrderProcessedCancelrequestedComponent } from './components/order-processed-cancelrequested/order-processed-cancelrequested.component';
import { SearchComponent } from './components/search/search.component';
import { MyNotificationComponent } from './components/my-notification/my-notification.component';
import { MyNotificationDetailComponent } from './components/my-notification-detail/my-notification-detail.component';
import { ShowCaseDetailComponent } from './components/show-case-detail/show-case-detail.component';
import { BannerPromotionProductComponent } from './components/banner-promotion-product/banner-promotion-product.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LanguagePageComponent } from './components/language-page/language-page.component';
import { AllRecommendBrandsComponent } from './components/all-recommend-brands/all-recommend-brands.component';
import { AllFavoriteProductComponent } from './components/all-favorite-product/all-favorite-product.component';
import { PromotionTierDetailComponent } from './components/promotion-tier-detail/promotion-tier-detail.component';
import { PromotionBundleDetailComponent } from './components/promotion-bundle-detail/promotion-bundle-detail.component';
import { ShowCasePromotionDetailComponent } from './components/show-case-promotion-detail/show-case-promotion-detail.component';
import { SummaryOrderComponent } from './components/summary-order/summary-order.component';
import { MyCartV2Component } from './components/my-cart-v2/my-cart.component';
import { OrderSuccessNewComponent } from './components/order-success-new/order-success-new.component';
import { OrderProcessedCancelledV2Component } from './components/order-processed-cancelled-v2/order-processed-cancelled-v2.component';
import { OrderProcessedCancelrequestedV2Component } from './components/order-processed-cancelrequested-v2/order-processed-cancelrequested-v2.component';

const routes: Routes = [
    {
        path: 'products',
        component: ProductsComponent,
    },
    {
        path: 'product-detail/:product-number',
        component: ProductDetailComponent,
    },
    {
        path: 'all-product',
        component: AllProductComponent,
    },
    // {
    //     path: 'all-product/:stockist-id',
    //     component: AllProductComponent,
    // },
    {
        path: 'my-cart',
        component: MyCartComponent,
    },
    {
        path: 'my-cart-v2',
        component: MyCartV2Component,
    },
    {
        path: 'my-notification',
        component: MyNotificationComponent,
    },
    {
        path: 'my-notification/:notificationDataType/:notificationParam',
        component: MyNotificationDetailComponent,
    },
    {
        path: 'review-order',
        component: ReviewOrderComponent,
    },
    {
        path: 'order-history',
        component: OrderHistoryComponent,
    },
    {
        path: 'order-history/:orderid',
        component: OrderHistoryDetailMainComponent,
    },
    {
        path: 'order-history/:retailerPurchaseOrderNumber/purchase-order/:purchaseOrderNumber/statuses/cancelrequested',
        component: OrderHistoryDetailCancelrequestedComponent,
    },
    {
        path: 'order-history/:retailerPurchaseOrderNumber/purchase-order/:purchaseOrderNumber/statuses/cancelled',
        component: OrderHistoryDetailCancelledComponent,
    },
    {
        path: 'order-history/:retailerPurchaseOrderNumber/purchase-order/:purchaseOrderNumber/statuses/confirmed',
        component: OrderHistoryDetailConfirmedComponent,
    },
    {
        path: 'order-history/:retailerPurchaseOrderNumber/purchase-order/:purchaseOrderNumber/statuses/delivered',
        component: OrderHistoryDetailDeliveredComponent,
    },
    {
        path: 'order-history/:retailerPurchaseOrderNumber/purchase-order/:purchaseOrderNumber/statuses/deprecated',
        component: OrderHistoryDetailDeprecatedComponent,
    },
    {
        path: 'order-success',
        component: OrderSuccessComponent,
    },
    {
        path: 'order/:orderid/processed/cancelled',
        component: OrderProcessedCancelledComponent
    },
    {
        path: 'order/:orderid/processed-cancelled',
        component: OrderProcessedCancelledV2Component
    },
    {
        path: 'order/:orderid/processed/cancelrequested',
        component: OrderProcessedCancelrequestedComponent
    },
    {
        path: 'order/:orderid/processed-cancelrequested',
        component: OrderProcessedCancelrequestedV2Component
    },
    {
        path: 'search',
        component: SearchComponent
    },
    {
        path: 'show-case/:show-case-number',
        component: ShowCaseDetailComponent,
    },
    {
        path: 'banner/:bannerid/products',
        component: BannerPromotionProductComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'language',
        component: LanguagePageComponent
    },

    {
        path: 'all-recommend-brands',
        component: AllRecommendBrandsComponent
    },
    {
        path: 'all-recommend-brands/:brandid',
        component: AllRecommendBrandsComponent
    },
    {
        path: 'all-favorite-products',
        component: AllFavoriteProductComponent
    },
    {
        path: 'promotion-tier/:promotion-id',
        component: PromotionTierDetailComponent
    },
    {
        path: 'promotion-bundle/:promotion-id',
        component: PromotionBundleDetailComponent
    },
    {
        path: 'show-case-promotion/:show-case-number',
        component: ShowCasePromotionDetailComponent,
    },
    {
        path: 'order-success-new',
        component: OrderSuccessNewComponent,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'products',
    },
    {
        path: 'summary-order',
        component: SummaryOrderComponent,
    },
    {
        path: 'promotion-bundle/edit-cart/:cart-id',
        component: PromotionBundleDetailComponent
    },
    {
      path: 'promotion-tier/edit-cart/:cart-id',
      component: PromotionTierDetailComponent
  },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RetailerRoutingModule { }
