import { Component, Input, OnInit } from '@angular/core';
import { additionalPrivilege } from '@core/models/retailer/retailer-purchase-order';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-additional-privilege-list',
  templateUrl: './show-additional-privilege-list.component.html',
  styleUrls: ['./show-additional-privilege-list.component.scss'],
})
export class ShowAdditionalPrivilegeListComponent implements OnInit {
  @Input() additionalPrivileges: additionalPrivilege[] = [];
  currentLang = 'th'
  isShow = false
  haveGift = false
  haveDiscount = false

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
    this.checkGiftAndDiscount();
  }

  changeShow() {
    this.isShow = this.isShow ? false : true
  }

  checkGiftAndDiscount() {
    this.additionalPrivileges.forEach(element => {
      if (element.selectedGift.length > 0) {
        this.haveGift = true
      }

      if (element.discount > 0) {
        this.haveDiscount = true
      }
    });
  }

  getFormattedCurrency(amount: number | undefined) {
    return amount ? `-${amount.toFixed(2)}฿`: ''
  }
}
