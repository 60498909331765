<div class="modal-container ps-1 pe-1 d-flex justify-content-center flex-column">
  <div data-cy="modal-header" class="d-flex align-items-end justify-content-center">
    <button type="button" data-cy="cross-close" class="btn-close ms-auto mt-3" aria-label="Close" (click)="close()"></button>
  </div>

  <div data-cy="modal-content" class="modal-body text-center mt-3">
    <h5 id="titlePopupConfirm" class="modal-title header-style text-center">
      {{ modalConfig.title }}
    </h5>
  </div>

  <div data-cy="modal-content" class="modal-body text-center mb-3">
    <p class="message-text">{{ modalConfig.message }}</p>
  </div>

  <div data-cy="modal-footer" class="d-flex justify-content-center flex-column ps-1 pe-1">
    <div class="d-flex justify-content-center mb-2 w-100">
      <button type="button" data-cy="button-confirm" class="btn w-100 btn-custom-size" [ngClass]="getButtonClasses()" (click)="confirm()">
        {{ modalConfig.confirmLabel }}
      </button>
    </div>
    <div class="d-flex justify-content-center w-100 mb-3" >
      <button *ngIf="modalConfig.cancelLabel" type="button" data-cy="button-close" class="btn w-100 btn-custom-size mb-2" [ngClass]="getOutlineButtonClasses()" (click)="close()">
        {{ modalConfig.cancelLabel }}
      </button>
    </div>
  </div>
