import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderDetailV2 } from '@core/models/retailer/order-preview.model';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { CartService } from '@core/services/retailer/cart.service';

@Component({
  selector: 'app-order-processed-cancelrequested-v2',
  templateUrl: './order-processed-cancelrequested-v2.component.html',
  styleUrls: ['./order-processed-cancelrequested-v2.component.scss']
})
export class OrderProcessedCancelrequestedV2Component implements OnInit {
  data: IOrderDetailV2 | undefined;
  orderId = '';

  constructor(
    private activateRoute: ActivatedRoute,
    private route: Router,
    private cartService: CartService,

  ) { }

  ngOnInit() {
    this.orderId = this.activateRoute.snapshot.paramMap.get('orderid') || '';
    this.getOrderDetail();
  }

  navigateToLanding() {
    this.route.navigate(['/retailer']);
  }

  navigateToOrderHistoryDetail() {
    this.route.navigate([`/retailer/order-history/${this.orderId}`]);
  }

  getOrderDetail() {
    this.cartService.getOrderV2ById(this.orderId).subscribe({
      next: (res) => {
        this.data = res;
      },
    });
  }

  getTotalQuantity(): number {
    if (this.data && this.data.items) {
      const totalQuantity = this.data.items.reduce(
        (total, item) => total + item.quantity,
        0
      );

      return totalQuantity;
    }

    return 0;
  }
  getTotalAmount(): number {
    if (this.data && this.data.items) {
      const totalQuantity = this.data.items.reduce(
        (total, item) => total + item.amount,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  getNetAmount(): number {
    if (!this.data) return 0
    const netAmount = this.data.netAmount;
    return netAmount

  }

  getPaymentInfo(): PaymentInfo {
    return <PaymentInfo>{
      totalQuantity: this.getTotalQuantity(),
      totalAmount: this.getTotalAmount(),
      couponDiscount: this.data?.couponDiscount,
      promotionDiscount: this.data?.promotionDiscount,
      shippingCost: this.data?.shippingCost,
      netAmount: this.getNetAmount(),
    };
  }

}
