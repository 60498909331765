<div class="modal-wrapper">
    <div class="modal-content">
        <!-- Close Button -->
        <div class="modal-header">
            <button type="button" class="close" (click)="closeModal()">
                <span>&times;</span>
            </button>
        </div>
        <div *ngIf="summaryOrder" class="modal-body">
            <!------------------------------------ Promotion Detail Section ------------------------------------>
            <div class="modal-title">
                <div class="product-image">
                    <img height="100px" alt="productImage" [src]="summaryOrder.coverImagePath" />
                </div>

                <div>
                    <p>{{summaryOrder.name}}</p>
                    <div *ngIf="summaryOrder.discount !== null">
                        <div class="d-flex gap-1">
                            <span [ngStyle]="{ color: summaryOrder.discount ? '#F40009' : '' }" class="discount-price">
                                <span>{{ formatCurrency(summaryOrder.discount ? summaryOrder.amount -
                                    summaryOrder.discount
                                    :
                                    summaryOrder.amount).split('.')[0] }}</span>
                                <span class="decimal">.{{ formatCurrency(summaryOrder.discount ? summaryOrder.amount -
                                    summaryOrder.discount : summaryOrder.amount).split('.')[1] }}</span>
                                <span class="currency">{{ getSymbol() }}</span>
                            </span>
                            <span class="discount-original-price" *ngIf="summaryOrder.discount">{{
                                formatCurrency(summaryOrder.amount) }}
                                <span class="currency">{{getSymbol()}}</span></span>
                        </div>

                        <div *ngIf="summaryOrder.discount" class="discount-sum">{{'SUMMARY.DISCOUNT_AMOUNT'| translate}}
                            {{formatCurrency(summaryOrder.discount)}}
                            <span class="currency">{{getSymbol()}}</span> 🎉
                        </div>
                    </div>

                    <div *ngIf="summaryOrder.discount === null">
                        <span class="text-amount">{{ formatCurrency(summaryOrder.amount).split('.')[0] }}</span>
                        <span class="decimal">.{{ formatCurrency(summaryOrder.amount).split('.')[1] }}</span>
                        <span class="currency">{{ getSymbol() }}</span>
                        <div *ngIf="summaryOrder.promotionPrivilegeGiveAwayGroups && summaryOrder.promotionPrivilegeGiveAwayGroups.length > 0"
                            class="discount-sum">{{'SUMMARY.GIFT_TITLE'| translate}}
                            🎁
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------- Product List Section------------------------------------- -->
            <div class="selected-product-container">
                <p class="selected-product">{{'SUMMARY.SELECTED_ITEM' | translate}}</p>
                <div *ngIf="summaryOrder.stockistProduct" class="img-stk-product">
                    <div *ngFor="let item of summaryOrder.stockistProduct">
                        <app-image-product-summary [imageSrc]="item.coverImagePath" [amount]="summaryOrder.amount" />
                    </div>
                </div>
            </div>
            <!-- ------------------------------- Gift Section ---------------------------------------------- -->
            <div *ngIf="summaryOrder.promotionPrivilegeGiveAwayGroups && summaryOrder.promotionPrivilegeGiveAwayGroups.length > 0"
                class="selected-product-container">
                <div class="gift-list-title d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <img [src]="'assets/icons/pink-gift.svg'" />
                        <span>{{'SUMMARY.SELECT_GIFT_TITLE' | translate}}</span>
                    </div>
                    <p>{{countSelected() +'/'+ summaryOrder.promotionPrivilegeGiveAwayGroups.length}}</p>
                </div>
                <!-- -------------------------- Gift List -------------------------------------------------- -->
                <div *ngFor="let giftLevel of summaryOrder.promotionPrivilegeGiveAwayGroups; let giftindex = index"
                    class="selected-gift-container">
                    <div class="content-gift-list-item">
                        <p class="gift-list-item">{{'SUMMARY.GIFT' | translate}} {{giftindex + 1}}</p>
                        <div class="gift-selected-count">{{checkIsSelectedGift(giftindex) ? '1' :'0'}} / 1</div>
                    </div>
                    <div *ngFor="let giftItem of giftLevel.promotionPrivilegeGiveAways; let giftItemIndex = index">
                        <div class="content-gift-list-item">
                            <div class="d-flex align-items-center gap-2">
                                <input type="radio" (click)="onSelectGift(giftindex,giftItem)"
                                    [id]="'giftItem' + giftItemIndex + '_' + giftindex" [name]="'giftId' + giftindex"
                                    [value]="giftItem.giftId" class="custom-radio">
                                <label [for]="'giftItem' + giftItemIndex + '_' + giftindex"></label>
                                <img class="gift-item-image" alt="giftImage" [src]="giftItem.coverImagePathThump" />
                                <span class="gift-name">{{giftItem.name}}</span>
                            </div>
                            <span class="gift-amount">x{{giftItem.quantity}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ----------------------------- Submit Button ----------------------------------------------------- -->
            <button *ngIf="summaryOrder" (click)="onSubmit()" [disabled]="!checkIsAllGiftSelected()" class="btn btn-add-product">
                {{ 'ACTION.ADD_CART' | translate }}
            </button>
        </div>
    </div>
</div>