import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBrandRecommend } from '@core/models/retailer/brand.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-brand',
  templateUrl: './card-brand.component.html',
  styleUrls: ['./card-brand.component.scss']
})
export class CardBrandComponent implements OnInit {
  @Input() item: IBrandRecommend | undefined;
  @Input() isLandigPage = false
  @Output() clickBrand: EventEmitter<number> = new EventEmitter<number>()

  imagePath = '';
  currentLang!: string

  constructor(private translate: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.currentLang = this.translate.currentLang;
    if (this.item) {
      const image = this.item.logoUrlThump;
      this.imagePath = image
    }
  }

  onClickBrand(brandId: number) {
    this.clickBrand.emit(brandId)
  }
}
