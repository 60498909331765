import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AddPromotionToCart, ApplyPromotion, PromotionGifts, PromotionItems, PromotionPrivilegeGiveAways } from '@core/models/retailer/promotion-v2.model';
import { PromotionV2Service } from '@core/services/retailer/promotion-v2.service';

// const mockData: ApplyPromotion = {
//   coverImagePath: "https://aurad.z23.web.core.windows.net/preview/Product/199/CoverImage/bdf462d4-659a-494e-bccc-862da8f02e0f.png",
//   name: 'โค้ก 1.25 ลิตร แพ็ค 12 ขวด และ แฟนต้า ทุกรสชาติ 330 มล. แพ็ค 12 ขวด',
//   bundle: 1,
//   amount: 2000.50,
//   discount: 200.25,
//   stockistProduct: [{ name: '', nameEn: "", productId: 1, stockistProductId: 1, amount: 10, coverImagePath: "https://aurad.z23.web.core.windows.net/preview/Product/509370/CoverImage/ab619cba-f59a-4909-9935-6b57b69b1e8b.png", price: 10 }],
//   promotionPrivilegeGiveAwayGroups: [{
//     id: 1,
//     promotionPrivilegeGiveAways: [
//       {
//         stockistProductId: 6,
//         productId: 1,
//         quantity: 1,
//         name: "เคสไอแพด",
//         nameEn: "",
//         coverImagePathThump: "https://aurad.z23.web.core.windows.net/preview/Product/509370/CoverImage/ab619cba-f59a-4909-9935-6b57b69b1e8b.png"
//       },
//       {
//         giftId: 1,
//         quantity: 2,
//         name: "น้ำแข็ง",
//         nameEn: "",
//         coverImagePathThump: "https://aurad.z23.web.core.windows.net/preview/Product/509174/CoverImage/f51dc270-afe8-4e5b-bcba-117dff158f27.jpg"
//       }
//     ]
//   }, 
//   {
//     id: 2,
//     promotionPrivilegeGiveAways: [
//       {
//         giftId: 1,
//         quantity: 2,
//         name: "coke",
//         nameEn: "",
//         coverImagePathThump: "https://aurad.z23.web.core.windows.net/preview/Product/199/CoverImage/bdf462d4-659a-494e-bccc-862da8f02e0f.png"
//       },
//       {
//         giftId: 1,
//         quantity: 2,
//         name: "เคสไอแพด",
//         nameEn: "",
//         coverImagePathThump: "https://aurad.z23.web.core.windows.net/preview/Product/509370/CoverImage/ab619cba-f59a-4909-9935-6b57b69b1e8b.png"
//       }
//     ]
//   }
// ]
// }

@Component({
  selector: 'app-modal-summary-item-tier',
  templateUrl: './modal-summary-item-tier.component.html',
  styleUrls: ['./modal-summary-item-tier.component.scss']
})
export class ModalSummaryItemTierComponent implements OnInit {
  @Output() clickClose = new EventEmitter<void>();

  @Input() applyPromotionTierData?: ApplyPromotion

  summaryOrder?: ApplyPromotion
  selectedItemList: { [key: number]: PromotionPrivilegeGiveAways } = {}

  constructor(
    private promotionService: PromotionV2Service,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.summaryOrder = this.applyPromotionTierData
    // this.summaryOrder = mockData
  }

  closeModal() {
    this.clickClose.emit()
  }

  formatCurrency(amount?: number) {
    amount = amount ?? 0

    const currencyAmount = new Intl.NumberFormat('th-TH', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
    return currencyAmount
  }

  getSymbol() {
    const currencyFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: 'narrowSymbol',
    });
    return currencyFormatter.formatToParts(1).find(part => part.type === 'currency')?.value || '';
  }

  calculateDiscountPrice() {
    if (this.summaryOrder && this.summaryOrder.discount) {
      const calculatedPrice = this.summaryOrder.amount - this.summaryOrder.discount;
      return calculatedPrice > 0 ? calculatedPrice : 0
    }
    return 0
  }

  onSubmit() {
    const currentTime = new Date()
    if (!this.summaryOrder) return
    const itemList: PromotionItems[] = []
    const giftList: PromotionGifts[] = []


    this.summaryOrder.stockistProduct.forEach((item) => { // เปลี่ยน format product ให้ตรงกับ payload
      const newItem: PromotionItems = {
        name: item.name,
        nameEn: item.nameEn,
        productId: item.productId ?? 0,
        quantity: item.amount,
        stockistProductId: item.stockistProductId ?? 0
      }
      itemList.push(newItem)
    })

    const giftKey = Object.keys(this.selectedItemList)
    giftKey.forEach((key) => {
      const itemData = this.selectedItemList[Number(key)]
      if (itemData) {
        giftList.push({
          quantity: itemData.quantity,
          name: itemData.name,
          nameEn: itemData.nameEn,
          giftId: itemData.giftId,
          productId: itemData.productId,
          stockistProductId: itemData.stockistProductId
        })
      }
    })

    const payload: AddPromotionToCart = {
      stockistId: 5419,
      stockistName: 'test',
      logoPath: 'image',
      createdAt: currentTime,
      itemInCart: [{
        cartId: '',
        promotionId: 1,
        type: 'Tier',
        name: this.summaryOrder.name,
        coverImagePathThumb: this.summaryOrder.coverImagePath,
        discount: this.summaryOrder.discount ?? undefined,
        totalAmount: this.summaryOrder.amount,
        totalAfterDiscount: this.summaryOrder.amount - (this.summaryOrder.discount ?? 0),
        createdAt: currentTime,
        items: itemList,
        gifts: giftList
      }]
    }

    this.promotionService.addPromotionTierToCart(payload).subscribe({
      next: () => {
        this.route.navigate(['/retailer/my-cart'])
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  onSelectGift(giftId: number, giftItem: PromotionPrivilegeGiveAways) {
    if (this.summaryOrder) {
      this.selectedItemList[giftId] = giftItem
    }
  }

  checkIsSelectedGift(giftId: number) {
    return this.selectedItemList[giftId]
  }

  checkIsAllGiftSelected() {
    if (this.summaryOrder) {
      return this.countSelected() === this.summaryOrder?.promotionPrivilegeGiveAwayGroups.length
    }
    return false
  }

  countSelected() {
    return Object.keys(this.selectedItemList).length
  }
}
