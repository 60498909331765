<div style="position: relative;" *ngIf="item">
    <!-- <div *ngIf="!item.favorited" class="favorite-button" (click)="onClickFavorite(item.id,'Add')">
        <img [src]="'assets/icons/UnFavorite.svg'" />
    </div>
    <div *ngIf="item.favorited" class="favorite-button" (click)="onClickFavorite(item.id,'Remove')">
        <img [src]="'assets/icons/Favorite.svg'" />
    </div> -->
    <div class="card card-box" (click)="onClickCard()">
        <img [attr.data-cy]="item.id + '-image'" [src]="productImage" class="card-img-top" alt="product image"
            onerror="this.src='assets/images/image_error.jpg'" />
        <div class="discount-label">
            <div *ngIf="checkHavePromotion(item) && (item.type === 'Tier' || item.type === 'Bundle')">
                <img [src]="imagePromotion" width="40" height="40"/>
            </div>
        </div>

        <span [attr.data-cy]="item.id + '-name'" class="card-text-name" style="margin-bottom: 0.25rem;">{{
            currentLang === 'th'
            ? item.name
            : item.nameEn
            }}</span>
        <div [attr.data-cy]="item.id + '-store-name'" class="card-text-stockist"
            style="margin-bottom: 0.25rem; gap:0.25rem">
            <div class="poster-stockist" [ngStyle]="{'background-image': 'url(' + storeImage + ')'}"
                style="background-size: cover; background-position: center;">
            </div>
            <div>
                {{ item.stockist.name }}
            </div>

        </div>
        <div [attr.data-cy]="item.id + '-label-unit-price'" class="card-text-price-unit">
            {{ 'PAYMENT.UNIT_PRICE' | translate }}
        </div>
        <div [attr.data-cy]="item.id + '-unit-price'" class="card-text-price">
            <span  [style]="'color: red'">฿{{ item.totalAfterDiscount| number:
                '1.2-2' }}</span>
            <span class="discount-price" *ngIf="item.privilegeType === 'Discount'" >
                {{ item.totalAfterDiscount | currency: 'THB' : 'symbol-narrow' }}</span>
        </div>

        <!-- <button
          [attr.data-cy]="item.id + '-show-product-info'"
          *ngIf="item.id"
          class="btn btn-add-product"
        >
          เพิ่มสินค้า
        </button> -->
        <!-- (click)="goToProductDetail(item.code)" -->

        <!-- <button
          [attr.data-cy]="item.id + '-show-product-quantity'"
          *ngIf="item.quantity>0"
          class="btn btn-add-product"
          (click)="goToProductDetail(item.code)"
        >
          X {{item.quantity}}
        </button> -->
    </div>
</div>
