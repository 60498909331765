<button (click)="onClickBrand(item.id)" *ngIf="item" class="btn-cursor">
    <div class="layout-image">
        <img [ngClass]="isLandigPage ? 'poster-landing-page' :'poster'" [src]="imagePath"
            onerror="this.src='assets/images/image_error.jpg'" />
        <div *ngIf="!isLandigPage">
            <p>
                {{ item.nameEN ? (currentLang === 'th' ? item.name : item.nameEN) : item.name }}
            </p>
        </div>
    </div>
</button>