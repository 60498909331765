<div class="modal-wrapper-select-gift">
    <div class="modal-content-select-gift">
        <!-- Close Button -->
        <div class="modal-header">
            <button type="button" class="close" (click)="closeModal()">
                <span>&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="selected-product">{{'SUMMARY.ADDITIONAL_CONDITIONAL_GIFT' | translate}}</p>
            <app-additional-gift-detail (allGiftsSelected)="onAllGiftsSelected($event)" [selectGiftPayload]="selectGiftPayload" (selectedItemAdditional)="onSelectedAdditional($event)"></app-additional-gift-detail>
            <!-- ----------------------------- Submit Button ----------------------------------------------------- -->
            <button [disabled]="!isAllGiftsSelected" (click)="onSummaryOrder()"
                class="btn btn-add-product">
                {{ 'ACTION.ADD_CART' | translate }}
            </button>
        </div>
    </div>
</div>
