import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AdditionalDiscount, AdditionalGiveAways } from '@core/models/retailer/order-preview.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-additional-privilege',
  templateUrl: './additional-privilege.component.html',
  styleUrls: ['./additional-privilege.component.scss']
})
export class AdditionalPrivilegeComponent implements OnInit {
  @Input() additionalDiscounts: AdditionalDiscount[] | undefined
  @Input() additionalGiveAways: AdditionalGiveAways[] | undefined
  activePanels = false;
  panelScrollHeight = 0;
  currentLang = 'th';

  // Get reference to the panel element
  @ViewChild('panelContent') panelContent!: ElementRef;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  toggle() {
    this.activePanels = !this.activePanels;

    if (this.activePanels) {
      // Set the scrollHeight dynamically when the panel is opened
      this.panelScrollHeight = this.panelContent.nativeElement.scrollHeight;
    }
  }

}
