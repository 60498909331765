<ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
  name="spinner" style="margin-top: 0px !important"></ngx-spinner>
<div class="aura-container-custom" *ngIf="!loading">
  <div class="mx-lg-5 set-bottom">
    <nav id="header-page" class="navbar bg-body-tertiary mb-md-4">
      <div class="px-0">
        <div class="back-button justify-content-start pointer-cursor" (click)="goBack()">
          <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
          <span>{{ 'ACTION.BACK_PAGE' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!checkProduct && !promotionTierDetail" class="no-product">
        <div class="pt-3">
          <img src="assets/images/no_product.svg" alt="" />
          <h4>{{ 'TITLE.PRODUCT_EMPTY' | translate }}</h4>
          <p>{{ 'TITLE.PRODUCT_NOT_AREA' | translate }}</p>
        </div>
      </div>
    </nav>
    <div *ngIf="promotionTierDetail && checkProduct">
      <div class="row">
        <div class="col-12 col-md-12 d-flex justify-content-center">
          <div class="carousel-area w-100 h-100">
            <div class="picsum-img-wrapper banner-area">
              <img class="banner-custom w-100 h-100" [src]="promotionTierDetail.coverImagePathThump"
                (error)="handleImageError($event)" alt="slide banner" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-612 align-self-center align-content-end order-sm-2 mt-3">
          <div class="d-flex justify-content-between">
            <p class="fw-bolder fs-hd" [attr.data-cy]="promotionTierDetail.id + '-name'">
              {{
              currentLang === 'th'
              ? promotionTierDetail.name
              : promotionTierDetail.name
              }}
            </p>
            <div>
              <div *ngIf="!promotionTierDetail.favorited" class="favorite-button"
                (click)="onClickFavorite(promotionTierDetail.id,'Add')">
                <img [src]="'assets/icons/UnFavorite.svg'" />
              </div>
              <div *ngIf="promotionTierDetail.favorited" class="favorite-button"
                (click)="onClickFavorite(promotionTierDetail.id,'Remove')">
                <img [src]="'assets/icons/Favorite.svg'" />
              </div>
            </div>
          </div>

          <div class=" d-flex justify-content-between">
            <div>
              <p class="mb-0 fs-sm text-color-grey" [attr.data-cy]="promotionTierDetail.id + '-label-unit-price'">
                {{ 'PAYMENT.UNIT_PRICE' | translate }}
              </p>
              <p class="fw-bolder mb-1" [attr.data-cy]="promotionTierDetail.id + '-unit-price'"
                *ngIf="promotionTierDetail.privilegeType === 'Gift' && promotionTierDetail.price">
                <app-custom-currency [value]="promotionTierDetail.price" integerSize="20px"
                  fractionalSize="16px"></app-custom-currency>
              </p>
              <p class="promotion-text" *ngIf="promotionTierDetail.privilegeType === 'Gift'">
                {{ 'TITLE.PROMOTION_TEXT_GIFT' | translate }}
              </p>

              <p class="fw-bolder mb-1 detail-discount" [attr.data-cy]="promotionTierDetail.id + '-unit-price'"
                *ngIf="promotionTierDetail.privilegeType === 'Discount' && promotionTierDetail.net_price && promotionTierDetail.price">
                <label><app-custom-currency [value]="promotionTierDetail.net_price" integerSize="20px"
                    fractionalSize="16px"></app-custom-currency></label>
                <label class="price fw-normal">{{ promotionTierDetail.price | number: '1.2-2' }}</label><span
                  style="font-size: 12px; color: #B5B5B5;">฿</span>
              </p>
              <p class="promotion-text"
                *ngIf="promotionTierDetail.privilegeType === 'Discount' && promotionTierDetail.discount ">
                {{ 'TITLE.PROMOTION_TEXT_DISCOUNT' | translate }} {{ promotionTierDetail.discount }}฿ 🎉
              </p>
            </div>

            <div>
              <img class="recommend-image"
                [src]="promotionTierDetail.privilegeType === 'Discount' ? 'assets/icons/tier_discount.png' : 'assets/icons/tier_gift.png'"
                onerror="this.src='assets/images/image_error.jpg'">
            </div>
          </div>

          <div class="d-flex align-items-center gap-2 mt-2 mb-3">
            <img class="poster" [src]="storeImage" onerror="this.src='assets/images/image_error.jpg'" />
            <p class="fs-sm mb-0">{{ promotionTierDetail.stockist.name }}</p>
          </div>

          <div class="section-detail">
            <div class="group-detail under-border">
              <div class="fw-bolder fs-15">
                {{ 'TITLE.DETAILE' | translate }}
              </div>
              <p class="remark" [attr.data-cy]="promotionTierDetail.id + '-remark'">
                {{
                currentLang === 'th'
                ? promotionTierDetail.description || '-'
                : promotionTierDetail.description || '-'
                }}
              </p>
            </div>

            <div class="section-promotion" *ngIf="promotionTierDetail">
              <div>
                <div class="mb-4 promotion-period">
                  {{ 'TITLE.PROMOTION_PERIOD' | translate }}
                  {{
                  formatDateThai(
                  promotionTierDetail.startDate
                  )
                  }}
                  -
                  {{
                  formatDateThai(promotionTierDetail.endDate)
                  }}
                </div>

                <table class="promotion-table" *ngIf="promotionTierDetail.privilegeType === 'Discount'">
                  <tr class="table-header">
                    <td class="w-50" id="header">
                      {{ 'TITLE.AMOUNT_TIER' | translate }}
                    </td>
                    <td class="w-50" id="header">
                      {{
                      'TITLE.DISCOUNT'
                      | translate
                      }}
                    </td>
                  </tr>
                  <tr *ngFor="let item of  promotionTierDetail.promotionTierConditions; let i = index">
                    <td>
                      <label *ngIf="i !== 0">></label>
                      {{
                      item.min
                      }}<label *ngIf="promotionTierDetail?.amountPurchaseType === 'Currency'">฿</label>
                      -
                      {{
                      item.max ? item.max : 'TITLE.NOLIMIT' | translate
                      }}<label *ngIf="item.max && promotionTierDetail?.amountPurchaseType === 'Currency'">฿</label>
                      <label class="mx-1" *ngIf="item.max && promotionTierDetail?.amountPurchaseType === 'Unit'">{{
                        'TITLE.UNIT_AMOUNT' | translate }}</label>
                    </td>
                    <td>
                      {{
                      item.amount
                      }}฿
                      <label *ngIf="promotionTierDetail?.amountPurchaseType === 'Unit'">{{ 'TITLE.PER_UNIT' | translate
                        }}</label>
                    </td>
                  </tr>
                </table>

                <table class="promotion-table" *ngIf="promotionTierDetail.privilegeType === 'Gift'">
                  <tr class="table-header">
                    <td class="w-50" id="header">
                      {{ 'TITLE.AMOUNT_TIER' | translate }}
                    </td>
                    <td class="w-50" id="header">
                      {{
                      'TITLE.GIFT'
                      | translate
                      }}
                    </td>
                  </tr>
                  <tr *ngFor="let item of  promotionTierDetail.promotionTierConditions; let i = index">
                    <td>
                      <label *ngIf="i !== 0 ">></label>
                      {{
                      item.min
                      }}<label *ngIf="promotionTierDetail?.amountPurchaseType === 'Currency'">฿</label>
                      -
                      {{
                      item.max ? item.max : 'TITLE.NOLIMIT' | translate
                      }}<label *ngIf="item.max && promotionTierDetail?.amountPurchaseType === 'Currency'">฿</label>
                      <label class="mx-1" *ngIf="item.max && promotionTierDetail?.amountPurchaseType === 'Unit'">{{
                        'TITLE.UNIT_AMOUNT' | translate }}</label>
                    </td>
                    <td>
                      {{
                      item.amount === null ? 0 : item.amount
                      }} {{ 'TITLE.UNIT_GIFT' | translate }}
                    </td>
                  </tr>

                </table>

                <div class="promotion-header" *ngIf="promotionTierDetail.promotionTierProducts?.length">
                  <div>
                    <img [src]="'assets/icons/Icon-list-product.png'" />
                  </div>
                  <p>
                    {{
                    'TITLE.PRODUCT_CHOOSE'
                    | translate
                    }}
                  </p>

                </div>
                <div class="aura-sub-container-custom section-promotion-product content-area mt-2">
                  <div class="shelve">

                    <div class="d-flex align-items-center w-100 mb-2" style="width: 100%;" *ngFor="
                                                let product of promotionTierDetail.promotionTierProducts; let i = index
                                            " [attr.data-cy-row-number]="i + 1">

                      <div class="d-flex align-items-center" (click)="onSelectProduct(product)" style="width: 100%;">
                        <div class="checkbox set-checkbox me-2" align="center">
                          <input type="checkbox" [(ngModel)]="product.isSelect">
                          <label for="'checkedAll-checkbox-' + {{ product.productId }}"></label>
                        </div>
                        <div class="me-2">
                          <img class="product-item pt-1" [src]="product.coverImagePathThump"
                            (error)="handleImageError($event)" alt="slide banner" />
                        </div>
                        <div class="product-name" [style.width]="product.isSelect ? 'calc(100% - 30%);' : 'calc(100% - 20%);'">
                          {{ currentLang === 'th' ? product.name : product.nameEn }}
                        </div>
                      </div>
                      <div style="width: 40%;" *ngIf="product.isSelect">
                        <div class="d-flex justify-content-end" style="width: 100%;">
                          <button class="btn btn-icon" (click)="reduceQuantity(product)">
                            <img src="assets/icons/MinusIcon.svg" />
                          </button>
                          <input #currentProductQuantity class="amount input-item" type="text"
                            value="{{ product.quantity }}"
                            oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);"
                            (input)="productOnInput($event,product)" disabled />
                          <button class="btn btn-icon" (click)="increaseProductQuantity(product)">
                            <img src="assets/icons/PlusIcon.svg" />
                          </button>
                        </div>
                      </div>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-promotion-recommend [id]="promotionTierDetail.id" [type]="type"></app-promotion-recommend>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-area" *ngIf="promotionTierDetail && checkProduct && !loading">
    <!-- <div class="quantity-item">
          <p class="align-self-center mb-0">
              {{ 'TITLE.QUANTITY' | translate }}
          </p>
          <div class="d-flex gap-2 align-items-baseline">
              <button class="btn" (click)="decreaseQuantity()" [disabled]="quantity <= 1">
                  <img src="assets/icons/MinusIcon.svg" />
              </button>
              <input #currentQuantity class="amount" data-cy="item-quantity" type="text"
                  value="{{ quantity }}" [min]="1"
                  oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);" />
              <button class="btn" (click)="increaseQuantity()">
                  <img src="assets/icons/PlusIcon.svg" />
              </button>
          </div>
      </div> -->
    <button [attr.data-cy]="promotionTierDetail.id + '-show-product-info'" *ngIf="promotionTierDetail.id"
      class="btn btn-add-product fs-lg" (click)="applyPromotionTier()"
      [disabled]="isQuantityMinimum || promotionTierProductPayload.length <= 0">
      {{ 'ACTION.CONFIRM' | translate }}
    </button>
    <app-modal-summary-item-tier *ngIf="showModal" [cartId]="cartId ?? ''" (clickClose)="clickCloseModal()"
      [applyPromotionTierData]="applyPromotionTierData" [stockist]="promotionTierDetail.stockist"
      [promotionId]="promotionTierDetail.id"></app-modal-summary-item-tier>
  </div>
</div>