<div *ngIf="productDetail">
  <div *ngFor="let product of productDetail">
    <div class="d-flex justify-content-between text-detail">
      <div>
        {{ product.name }}
      </div>
      <div>
        x{{ product.quantity }}
      </div>
    </div>
  </div>
</div>