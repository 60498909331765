import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectGiftPayload, GiftItem } from '@core/models/retailer/promotion-v2.model';
import { TranslateService } from '@ngx-translate/core';

export interface SelectedGiftDetail {
  stockistId: number;
  promotionId: number
  selectedGift: GiftItem
}

@Component({
  selector: 'app-additional-gift-detail',
  templateUrl: './additional-gift-detail.component.html',
  styleUrls: ['./additional-gift-detail.component.scss']
})
export class AdditionalGiftDetailComponent implements OnInit{
  @Output() allGiftsSelected = new EventEmitter<boolean>();
  @Output() selectedItemAdditional = new EventEmitter<Record<string, SelectedGiftDetail>>();

  @Input() selectGiftPayload: SelectGiftPayload[] = [];

  giftItem?: SelectGiftPayload[] = [];
  selectedItemList: Record<string, SelectedGiftDetail> = {};
  currentLang = 'th';

  constructor(
    private translate: TranslateService,
  ) {
    this.currentLang = this.translate.currentLang
  }

  ngOnInit(): void {
    this.loadData();  
  }

  loadData() {
    this.giftItem = this.selectGiftPayload;
  }

  onGiftSelectionChanged() {
    const isAllSelected = this.checkIsAllGiftSelected();
    this.allGiftsSelected.emit(isAllSelected);
  }

  onSelectGift(stockistIndex: number, additionalIndex: number, giftGroupIndex: number, giftItem: GiftItem, stockistId: number, promotionId:number) {
    const uniqueKey = `${stockistIndex}_${additionalIndex}_${giftGroupIndex}`;
    this.selectedItemList[uniqueKey] = {stockistId, selectedGift: giftItem, promotionId};
    this.onGiftSelectionChanged();
    this.selectedItemAdditional.emit(this.selectedItemList);
  }
  checkIsSelectedGift(stockistIndex: number, additionalIndex: number, giftGroupIndex: number) {
    const uniqueKey = `${stockistIndex}_${additionalIndex}_${giftGroupIndex}`;
    return !!this.selectedItemList[uniqueKey];
  }

  checkIsAllGiftSelected() {
    if (this.giftItem && this.giftItem.length > 0) {
      return this.giftItem.every((item, additionalIndex) =>
        item.additionalPrivileges.every((additional, privilegeIndex) =>
          additional.additionalPrivilegeGroups.every((_, groupIndex) => 
            this.checkIsSelectedGift(additionalIndex, privilegeIndex, groupIndex)
          )
        )
      );
    }
    return false;
  }

  countSelectedForPrivilege(stockistIndex: number, additionalIndex: number) {
    if (!this.giftItem || this.giftItem.length === 0) return 0;

    const item = this.giftItem[stockistIndex];
    if (!item) return 0;

    const additional = item.additionalPrivileges[additionalIndex];
    if (!additional || additional.additionalPrivilegeGroups.length === 0) return 0;

    let count = 0;
    additional.additionalPrivilegeGroups.forEach((_, groupIndex) => {
      const uniqueKey = `${stockistIndex}_${additionalIndex}_${groupIndex}`;
      if (this.selectedItemList[uniqueKey]) {
        count++;
      }
    });

    return count;
  }

  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
  }
}
