<div class="aura-container-custom">
    <div class="pl-1">
        <div class="w-100 search-box" (click)="navigateToSearch()">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}"></app-input-search>
        </div>
    </div>
    <div class="carousel-area">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let item of bannerList; let i = index">
                <div class="picsum-img-wrapper banner-area" (click)="onClickBanner(item)">
                    <img class="banner-custom" [src]="item.imagePath" alt="slide banner" />
                </div>
            </ng-template>
        </ngb-carousel>

        <div class="loading-section" *ngIf="isLoadingBanner">
            <div class="spinner-border"></div>
        </div>
    </div>

    <div class="category">
        <div class="favButton container-fluid">
            <button class="full btn categoryButton" routerLink="/retailer/all-favorite-products">
                <div class="fixIcon">
                    <img src="assets/icons/favIcon.svg" width="36px" height="36px" alt="" />
                    <span class="w-100">{{ "ACTION.FAVORITE" | translate }}</span>
                </div>
            </button>
            <button class="full btn categoryButton" routerLink="/retailer/order-history">
                <div class="fixIcon">
                    <div>
                        <img src="assets/icons/hisIcon.svg" width="36px" height="36px" alt="" />
                    </div>
                    <span class="w-100">{{ "ACTION.HISTORY" | translate }}</span>
                </div>
            </button>
        </div>
    </div>

    <div class="aura-sub-container-custom content-area">
        <div class="container-category">
            <div class="shelve category-gap">
                <div *ngFor="let item of allCategory | slice: 0 : 10; let i = index">
                    <app-card-category [item]="item" (click)="navigateToCategory(item.id)" />
                </div>
            </div>
        </div>
    </div>
    <app-recommend-brand />

    <!-- showCaese V1 -->
    <!-- <div *ngFor="let showCase of showCaseList; let i = index">
        <div *ngIf="showCase.showCaseProduct && showCase.showCaseProduct.length !== 0">
            <div class="title-card d-flex justify-content-between" style="width: 100%;">
                <div class="w-80" style="width: 80%;">{{ currentLang === 'th' ? showCase.name : showCase.nameEN}}</div>
                <div class="w-20" style="width: 20%;" align="right">
                  <button class="titleButton" (click)="navigateToShowCaseDetail(showCase.id)">
                    {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
                  </button>
                </div>
            </div>
            <div class="aura-sub-container-custom content-area">
                <div class="shelve d-flex">
                    <ng-container *ngIf="showCase.showCaseProduct?.length">
                        <div class="card-box-position" *ngFor="
                            let item of showCase.showCaseProduct
                                | slice: 0 : 10;
                            let i = index
                        " [attr.data-cy-row-number]="i + 1">
                            <app-card-item-product class="w-fit" [item]="item" />
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div> -->

    <!-- showCaese V2 -->
    <div *ngFor="let showCase of showCaseListV2; let i = index">
      <div *ngIf="showCase.showCaseProduct && showCase.showCaseProduct.length !== 0">
          <div class="title-card d-flex justify-content-between">
              <div style="width: 80%; ">{{ currentLang === 'th' ? showCase.name : showCase.nameEN}}</div>
              <div style="width: 20%;" align="right">
                <button class="titleButton" (click)="navigateToShowCaseDetailV2(showCase.id)">
                  {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
                </button>
              </div>
          </div>
          <div class="aura-sub-container-custom content-area">
              <div class="shelve d-flex">
                  <ng-container *ngIf="showCase.showCaseProduct?.length">
                      <div class="card-box-position" *ngFor="
                          let item of showCase.showCaseProduct
                              | slice: 0 : 10;
                          let i = index
                      " [attr.data-cy-row-number]="i + 1">
                          <app-card-item-show-cases-product class="w-fit" [item]="item" />
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
    </div>

    <div class="loading-section" *ngIf="checkShowCaseLoading()">
        <div class="spinner-border"></div>
    </div>
    <div class="title-card d-flex justify-content-between">
        <div>{{ "TITLE.PRODUCT" | translate }}</div>
        <button class="titleButton" routerLink="/retailer/all-product">
            {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
        </button>
    </div>

    <div class="aura-sub-container-custom content-area">
        <div class="shelve d-flex">
            <div class="card-box-position" *ngFor="let item of productList | slice: 0 : 10; let i = index"
                [attr.data-cy-row-number]="i + 1">
                <app-card-item-product class="w-fit" [item]="item" />
            </div>

            <div class="no-data-section" *ngIf="!isLoadingProduct && stockistList.length === 0">
                <img src="assets/icons/Not_Found_Item_Icon.svg" />
            </div>

            <div class="loading-section" *ngIf="isLoadingProduct">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>

    <div class="recommendArea">
        <div class="title-card d-flex justify-content-between">
            <div>{{ "TITLE.STOCKIST" | translate }}</div>
        </div>
        <div class="recommendedItem d-flex justify-content-start">
            <div class="banner" *ngFor="let item of stockistList | slice: 0 : 10; let i = index">
                <app-card-stockist (clickStockist)="navigateToStockist($event)" [item]="item" />
            </div>

            <div class="no-data-section" *ngIf="!isLoadingStockist && stockistList.length === 0">
                <img src="assets/icons/Not_Found_Item_Icon.svg" />
            </div>

            <div class="loading-section" *ngIf="isLoadingStockist">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="aura-container-custom footer-position" *ngIf="showButtonMyCart()">
  <button data-cy="show-my-cart-button" class="btn btn-view-product" (click)="goToMyCart()">ดูสินค้าในรถเข็น
    ({{formatCurrency(cartAmount)}})</button>
</div> -->
