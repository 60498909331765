<div *ngIf="product">
  <div class="product-pane">
    <!-- product section -->
    <div class="d-flex align-items-center" style="margin-right: auto">
      <!-- checkbox section -->
      <div class="col-1 checkbox">
        <input [checked]="product.isSelected" (change)="handleSelectedProduct()" type="checkbox"
          id="product-{{product.cartId}}-checkbox">
        <label for="product-{{ product.cartId }}-checkbox" class="checkmark"></label>
      </div>
      <!-- product image section -->
      <div class="">
        <img src="{{product.coverImagePathThumb}}" (error)="handleImageError($event)" alt="product image"
          class="p-2 product_image">
      </div>
      <!-- product description section -->
      <div class="w-100">
        <div class="row">
          <div class="col">
            <div class="product-name row">
              <p class="lh-base card-text-name"> {{ currentLang === 'th' || !product.nameEn ? product.name :
                product.nameEn }}
              </p>
              <button data-id="edit-bundle-promotion" *ngIf="product.type === 'Bundle' || product.type === 'Tier'"
                (click)="editPromotion()" class="btn-qty-hide justify-content-center">
                <img class="dot-icon" src="assets/images/dots-horizontal.svg">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- promotion product list section -->
    <div class="row mt-2">
      <div class="d-flex align-items-baseline" style="margin-right: auto">
        <div class="col-1"></div>
        <div class="col w-100">
          <app-my-cart-product-detail-v2 [productDetail]="product.items"
            [gifts]="product.gifts"></app-my-cart-product-detail-v2>
        </div>
      </div>
    </div>

    <!-- increase/decrease quantity & product amount section -->
    <div class="row mt-3">
      <div class="col-1"></div>
      <div class="col d-flex justify-content-end" style="margin-left: 0px;"
        [ngClass]="{'justify-content-between': product.type !== 'Tier'}">
        <div *ngIf="product.type!=='Tier'" class=" d-flex">
          <button class="btn btn-icon" style="margin-left: -12px" (click)="reduceQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/MinusIcon.svg" />
          </button>
          <input #currentQuantity class="amount input-item" type="text" value="{{ product.quantity }}"
            oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);" (input)="onInput($event)"
            (blur)="handleOnBlur()" [disabled]="isManageMode" />
          <button class="btn btn-icon" (click)="increaseQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/PlusIcon.svg" />
          </button>
        </div>

        <!-- when product type is Bundle and product -->
        <div *ngIf="product.type!=='Tier'" class="d-flex">
          <span class="d-flex flex-column">
            <div *ngIf="product.discount && product.discountPerUnit * product.quantity>0"
              class="d-flex justify-content-end text-description">
              <span class="me-1">{{'SUMMARY.DISCOUNT_AMOUNT'| translate}}</span>
              <app-custom-currency [value]="product.discountPerUnit * product.quantity" integerSize="12px"
                fractionalSize="12px"></app-custom-currency>
            </div>

            <div class="d-flex justify-content-end">
              <div class="d-flex flex-column justify-content-between" style="height: 100%;">
                <p *ngIf="product.discountPerUnit>0" class="net-amount">
                  <app-custom-currency [value]="product.amountPerUnit * product.quantity" integerSize="12px"
                    fractionalSize="12px"></app-custom-currency>
                </p>
              </div>
              <p class="fw-bold" [ngStyle]="{ 'color': product.discountPerUnit ? 'red' : 'inherit' }">
                <app-custom-currency
                  [value]="(product.amountPerUnit * product.quantity)-(product.discountPerUnit*product.quantity)"
                  integerSize="16px" fractionalSize="16px"></app-custom-currency>
              </p>
            </div>
          </span>
        </div>

        <!-- when product type is tier -->
        <div *ngIf="product.type==='Tier'" class="d-flex">
          <span class="d-flex flex-column">
            <div *ngIf="product.discount && product.discount>0" class="d-flex justify-content-end text-description">
              <span class="me-1">{{'SUMMARY.DISCOUNT_AMOUNT'| translate}}</span>
              <app-custom-currency [value]="product.discount" integerSize="12px"
                fractionalSize="12px"></app-custom-currency>
            </div>

            <div class="d-flex justify-content-end">
              <div class="d-flex flex-column justify-content-between" style="height: 100%;">
                <p *ngIf="product.discount && product.discount>0" class="net-amount">
                  <app-custom-currency [value]="product.totalAmount" integerSize="12px"
                    fractionalSize="12px"></app-custom-currency>
                </p>
              </div>
              <p class="fw-bold" [ngStyle]="{ 'color': product.discountPerUnit ? 'red' : 'inherit' }">
                <app-custom-currency [value]="product.totalAfterDiscount" integerSize="16px"
                  fractionalSize="16px"></app-custom-currency>
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- manage product modal -->
  <app-modal-manage-product *ngIf="showManageProductModal" (clickClose)="onClickManageProductModal(false)"
    (removeProduct)="removeProduct($event)" [cartSelected]="selectedCart" [product]="product"></app-modal-manage-product>
</div>