import { Component, OnInit } from '@angular/core';
import { ICart } from '@core/models/retailer/promotion-v2.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { IValidateResponse } from '@core/models/retailer/cart.model';
import { TranslationService } from '@modules/i18n';
import { Router } from '@angular/router';
import { ICreateOrderResponse } from '@core/models/retailer/createOrderResponse.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-summary-order',
  templateUrl: './summary-order.component.html',
  styleUrls: ['./summary-order.component.scss']
})
export class SummaryOrderComponent implements OnInit {

  constructor(
    private route: Router,
    private translate: TranslationService,
    private cartService: CartV2Service,
    private spinner: NgxSpinnerService
  ) {

  }
  isLoading = false;
  expandDetails = false;
  detailIcon = 'assets/images/detail-icon.png'
  carts: ICart[] = [];

  validateDetail: IValidateResponse[] = [];
  isModalOpen = false;

  couponName = '';
  discountCouponAmount = 0;
  discountCouponType = '';

  ngOnInit(): void {
    this.cartService.getVerifyCart().subscribe((res) => {
      this.carts = res;
    })
  }

  handleImageError(event: any) {
    event.target.src = 'assets/images/image_error.jpg';
  }

  openDiscountModal() {
    this.isModalOpen = true;
  }

  handleDiscount(discount: number) {
    this.discountCouponAmount = discount;
    this.isModalOpen = false;
  }

  handleDiscountCode(code: string) {
    this.couponName = code;
  }

  handleDiscountType(type: string) {
    this.discountCouponType = type;
  }

  closeDiscountModal() {
    this.isModalOpen = false;
  }

  getVerifyCartHandler() {
    this.showLoader(true)
    this.cartService.getVerifyCart()
      .subscribe({
        next: (res) => {
          this.onGetVerifyCartSuccess(res)
        },
        error: () => { this.showLoader(false) }
      })
  }
  
  onGetVerifyCartSuccess(res: ICart[]) {
    this.carts = res;
    this.showLoader(false)
  }

  onCreateOrderHandler() {
    this.showLoader(true)
    this.cartService.createOrder().subscribe({
      next:(response) => this.onCreateOrderSuccess(response),
      error:() => this.showLoader(false)
    })
  }

  onCreateOrderSuccess(response: ICreateOrderResponse) {
    const keyCreateOrder = "createOrder"
    const convertedData = JSON.stringify(response);
    localStorage.setItem(keyCreateOrder, convertedData);

    this.showLoader(false)
    this.navigateToOrderSuccess()
  }

  navigateToOrderSuccess() {
    const keyPathOrderSuccess = '/retailer/order-success-new'
    this.route.navigate([keyPathOrderSuccess]).then(() => {
      window.location.reload();
    });
  }

  showLoader(show: boolean) {
    this.isLoading = show
    if (show) {
        this.spinner.show('spinner');
        return
    }
    this.spinner.hide('spinner');
}
  
}
