<div class="aura-container-custom background-order-confirm" >
  <div class="pr-1 d-flex justify-content-between fs-5 background-order-head ">
    <div >
      <!-- <button
      type="button"
      class="btn btn-close btn-sm me-auto"
      routerLink="/retailer"
      aria-label="Close"
      style="border: 2px solid #000000; opacity: 1"
      ></button> -->
      <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
        {{ "TITLE.ORDER_SENT_STORE" | translate }}
      </p>
      <p class="fw-ligh text-head-mid">
        {{ "TITLE.ORDER_NUMBER" | translate }}: {{ retailerPurchaseOrder.id }}
      </p>
      <p class="fw-light text-head-last">
        {{ "TITLE.ORDER_AT" | translate }} {{ retailerPurchaseOrder.createdAt | displayDate }}
      </p>
    </div>
    <div>
      <img
            class=""
            src="assets/images/Order_Created.svg"
            width="52px"
            height="52px"
            alt=""
          />
    </div>
  </div>
  <div class="background-order-sum">
    <div class="d-flex flex-column px-3 pt-2 gap-1">
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.PRODUCT_TOTAL" | translate }} ({{ retailerPurchaseOrder.total_quantity_all }} {{ 'TITLE.UNIT' | translate}})</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(retailerPurchaseOrder.totalAmount) }}
        </p>
      </div>
      <div *ngIf="retailerPurchaseOrder.promotionDiscount>0" class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(retailerPurchaseOrder.promotionDiscount) }}
        </p>
      </div>
        <div *ngIf="retailerPurchaseOrder.couponDiscount>0" class="d-flex flex-row justify-content-between">
          <p class="fw-light mb-0">{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
          <p class="fw-light mb-0">
            {{ formatCurrency(retailerPurchaseOrder.couponDiscount) }}
          </p>
        </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.SHIPPING_COST" | translate }} ({{ "TITLE.ALL_STORE" | translate }})</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(retailerPurchaseOrder.shippingCost) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between mb-2">
        <p class="fs-5 fw-semibold">{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p class="fs-5 fw-semibold">
          {{ formatCurrency(retailerPurchaseOrder.netAmount) }}
        </p>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center gap-2 pt-3 bg-white"
    *ngFor="
      let purchaseOrder of retailerPurchaseOrder.orders;
      let last = last
    "
    [ngStyle]="{ 'border-bottom': !last ? '1px solid #D9D9D9' : 'none' }"
  >
    <div class="d-flex justify-content-between background-order-item-head">
      <div style="width: 75%;">
        <p class="text-number-order-item">
          {{ "TITLE.NUMBER" | translate }}
        </p>
        <p class="fs-5 fw-semibold mb-0">
          {{ purchaseOrder.id }}
        </p>
        <p class="mb-0">{{ "TITLE.ORDER_AT" | translate }} {{ purchaseOrder.createdAt | displayDate }}</p>
      </div>
      <div>
        <button class="button-status">
          {{ "TITLE.WAIT_COMFIRM" | translate }}
        </button>
      </div>
    </div>
    <div class="background-order-item">
      <div
        class="d-flex flex-row pt-3 justify-content-between align-items-center"
      >
        <div class="flex-column">

          <p class="fw-light mb-1">
            <img
            class="mr-5"
            src="assets/images/shipfrom.svg"
            width="20px"
            height="20px"
            alt=""
          /><label class="text-product">{{ "TITLE.DELIVER_FROM" | translate }}</label></p>
          <p class="mb-0 mr-3 text-detail">{{ purchaseOrder.stockistName }}</p>
        </div>
        </div>
        <div class="flex-column">
          <p class="fw-light pt-3 mb-1">
            <img
            class="mr-3"
            src="assets/images/shipaddress.svg"
            width="20px"
            height="20px"
            alt=""
          /><label class="text-product">{{ "TITLE.DELIVERED_AT" | translate }}</label></p>
          <p class="mb-1 text-detail">{{ retailerDetail?.retailerName }}</p>
          <p class="mb-0 fw-light text-detail">{{ getAddress() }}</p>
        </div>
      <div class="fs-6 fw-semibold mt-3">
        <p>{{ "TITLE.PRODUCT" | translate }}</p>
      </div>
    </div>
    <app-show-product-list style="padding: 0rem 1rem;"
      [products]="purchaseOrder.items"
    ></app-show-product-list>
    <div class="background-gift-item" *ngIf="purchaseOrder.promotions.length && isPurchaseOrderHaveGiftPromotion(purchaseOrder)">
      <div class="topic-gift pb-3">{{ "TITLE.GIFT" | translate }}</div>
      <div class="gift-section">
        <div class="gift-item" *ngFor=" let promotion of purchaseOrder.promotions">
          <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex gap-1 align-items-center">
            <img alt="Product image" class="product_image" [src]="promotion.giveAway.coverImagePathThump" onerror="this.src='assets/images/image_error.jpg'" />
            <div>{{promotion.giveAway.name}}</div>
          </div>
          <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex align-items-center">{{promotion.quantity}} {{ "TITLE.ITEM" | translate }}</div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column px-3 pt-2 gap-1 background-order-item-bottom">
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.PRODUCT_TOTAL" | translate }} ({{ purchaseOrder.total_quantity }} {{ "TITLE.ITEM" | translate }})</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(purchaseOrder.amount) }}
        </p>
      </div>
      <div *ngIf="purchaseOrder.promotionDiscount > 0" class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(purchaseOrder.promotionDiscount) }}
        </p>
      </div>
      <div *ngIf="purchaseOrder.couponDiscount > 0" class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(purchaseOrder.couponDiscount) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-light mb-0">{{ "PAYMENT.SHIPPING_COST" | translate }}</p>
        <p class="fw-light mb-0">
          {{ formatCurrency(purchaseOrder.shippingCost) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between pt-3">
        <p class="fs-5 fw-semibold">{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p class="fs-5 fw-semibold">
          {{ formatCurrency(purchaseOrder.netAmount) }}
        </p>
      </div>

      <div *ngIf="last" class="d-flex flex-row justify-content-center pt-3 pl-3">
          <button class="button-home" routerLink="/retailer">
            {{ "ACTION.BACK_PAGE_MAIN" | translate }}
          </button>
      </div>
    </div>
  </div>
</div>
