import { Component, ViewChild, ElementRef, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { Router } from '@angular/router';
import { CancelOrderPayload } from '@core/models/retailer/cart.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@core/services/shared/config.service';

@Component({
  selector: 'app-reason-cancellation-modal',
  templateUrl: './reason-cancellation-modal.component.html',
  styleUrls: ['./reason-cancellation-modal.component.scss']
})
export class ReasonCancellationModalComponent implements OnInit {
  @ViewChild('reasonInput') reasonInput!: ElementRef<HTMLInputElement>;
  @Input() isOpenModal!: boolean;
  @Input() orderNumber!: string;
  @Input() currentOrderStatus = ''
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  reasonChecked = [false, false, false, false, false, false];
  isFormValid = false;
  otherReason = '';
  showError = false;
  reason5Checked = false;
  formattedReason = {};
  currentLang!: string

  reasons = [
    { lc: 'ฉันต้องการเปลี่ยนจำนวนสินค้า', en: 'I want to change the amount' },
    { lc: 'ฉันต้องการแก้ไขรายการสินค้า', en: 'I want to change the order' },
    { lc: 'ฉันต้องการสินค้าด่วน', en: 'I want an urgent delivery' },
    { lc: 'ฉันเปลี่ยนใจ', en: 'I changed my mind' },
    { lc: 'อื่นๆ', en: 'Others' }
  ];

  reasonForm: FormGroup;

  constructor(private fb: FormBuilder, private purchaseOrderService: PurchaseOrderService, private router: Router, private translate: TranslateService,private configService: ConfigService) {
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    });


  }
  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  onReasonChecked(index: number) {
    this.reasonChecked[index] = !this.reasonChecked[index];
    if (index === this.reasons.length - 1) {
      this.reason5Checked = !this.reason5Checked
      if (this.reason5Checked && this.reasonInput) {
        this.reasonInput.nativeElement.focus();
      } else {
        this.validateReason();
      }
    }
    this.isFormValid = this.reasonChecked.some(reason => reason !== false);
  }

    validateReason() {
      this.showError = this.otherReason.trim().length === 0 && this.reason5Checked;
      if (this.isReason5Checked()) {
        this.isFormValid = !!this.otherReason
      }
    }

    isReason5Checked() {
      return this.reason5Checked;
    }

    close() {
      this.isOpenModal = false;
      this.isOpenModalChange.emit(this.isOpenModal);
    }

    cancelOrder() {
      const reasons = [];

      // Push selected reasons to the array
      for (let i = 0; i < this.reasonChecked.length; i++) {
        if (this.reasonChecked[i]) {
          reasons.push({ lc: this.reasons[i].lc, en: this.reasons[i].en });
        }
      }

      // Initialize otherReason with null if it's empty
      const otherReason = this.reason5Checked && this.otherReason.trim() !== '' ? this.otherReason : null;

      if (otherReason == null) {
        this.formattedReason = {
          reason: reasons
        };
      } else {
        this.formattedReason = {
          reason: reasons,
          other: otherReason
        };
      }

      const formattedReasonString = JSON.stringify(this.formattedReason);

      const payload: CancelOrderPayload = {
        cancelOrderReason: formattedReasonString,
      };

      this.purchaseOrderService
        .cancelPurchaseOrder(
          this.orderNumber,
          payload
        ).subscribe({
          next: () => {
            const currentVersion = this.configService.getAppVersion()
            if (this.currentOrderStatus === 'Pending') {
              currentVersion === "v1" ? this.router.navigate([`retailer/order/${this.orderNumber}/processed/cancelled`]) : this.router.navigate([`retailer/order/${this.orderNumber}/processed-cancelled`])
            } else if (this.currentOrderStatus === 'Confirmed') {
              currentVersion === "v1" ? this.router.navigate([`retailer/order/${this.orderNumber}/processed/cancelrequested`]) : this.router.navigate([`retailer/order/${this.orderNumber}/processed-cancelrequested`])
            }

          }, error: (err) => {
            console.error('Error', err)
          }
        })
    }
  }
