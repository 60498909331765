import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerPromotionPurchaseOrder } from '@core/models/retailer/retailer-purchase-order';
import { CartService } from '@core/services/retailer/cart.service';
import { IRetailerAddress } from '@core/models/retailer/cart.model';
import { PurchaseOrder } from '@core/models/retailer/purchase-order.model';

@Component({
    selector: 'app-order-success-new',
    templateUrl: './order-success-new.component.html',
    styleUrls: ['./order-success-new.component.scss'],
})
export class OrderSuccessNewComponent implements OnInit, OnDestroy {
  orderProductNumber = 0;

    constructor(
        private cartService: CartService,
        private route: Router,
    ) {
    }

    retailerPurchaseOrder!: RetailerPromotionPurchaseOrder ;
    retailerDetail?:IRetailerAddress

    createOrder = {
      id: "123",
      couponCode: "AXXX",
      totalOrderAmount: 0,
      promotionDiscount: 0,
      couponDiscount: 0,
      shippingCost: 0,
      netAmount: 0,
      total_quantity_all: 0,
      createdAt: '2024/11/11',
      orderLists: [
          {
              orderId: "0001",
              status: "Pending",
              createdAt: '2024/11/11',
              totalOrderAmount: 2564,
              promotionDiscount: 0,
              couponDiscount: 0,
              netAmount: 2574,
              stockistId: 1,
              stockistName: "xxx",
              total_quantity: 0,
              shippingCost: 10,
              additionalPrivilege: [
                  {
                      Name: "P1",
                      promotionId: 1,
                      discount: 0,
                      selectedGift: [
                          {
                              stockistProductId: 1,
                              giftId: null,
                              productId: 1,
                              quantity: 1,
                              name: "แฟนต้าสตอเบอรี่",
                              nameEn: "Fanta Strawberry"
                          }
                      ]
                  },
                  {
                    Name: "P2",
                    promotionId: 1,
                    discount: 10,
                    selectedGift: [
                    ]
                  },
                  {
                    Name: "P1",
                    promotionId: 1,
                    discount: 0,
                    selectedGift: [
                        {
                            stockistProductId: 1,
                            giftId: null,
                            productId: 1,
                            quantity: 1,
                            name: "แฟนต้าสตอเบอรี่",
                            nameEn: "Fanta Strawberry"
                        }
                    ]
                  },
                  {
                    Name: "P1",
                    promotionId: 1,
                    discount: 10,
                    selectedGift: [
                    ]
                  }
              ],
              itemInCart: [
                  {
                      cartId: 2,
                      promotionId: 2,
                      type: "Bundle",
                      privilegeType: "Gift",
                      name: "โค้ก 1.25 ลิตร",
                      nameEn: "",
                      coverImageThump: "xxxxx",
                      tierLevel: 1,
                      quantity: 2,
                      discount: 0.00,
                      amountPerUnit: 920,
                      totalAmount: 1840.00,
                      totalAfterDiscount: 1840.00,
                      createAt: "2024/11/13",
                      items: [
                          {
                              stockistProductId: 1,
                              productId: 1,
                              name: "โค้ก 1.25 ลิตร แพ็ค 12 ขวด",
                              nameEn: "Coke 1.25 lite",
                              quantity: 1
                          },
                          {
                              stockistProductId: 1,
                              productId: 1,
                              name: "แฟนต้าสตอเบอรี่",
                              nameEn: "Fanta Strawberry",
                              quantity: 2
                          },
                          {
                              stockistProductId: 1,
                              productId: 1,
                              name: "โค้ก 1.25 ลิตร แพ็ค 12 ขวด",
                              nameEn: "Coke 1.25 lite",
                              quantity: 1
                          },
                          {
                              stockistProductId: 1,
                              productId: 1,
                              name: "โค้ก 1.25 ลิตร แพ็ค 12 ขวด",
                              nameEn: "Coke 1.25 lite",
                              quantity: 1
                          }
                      ],
                      gifts: [
                          {
                              stockistProductId: 1,
                              giftId: null,
                              productId: 1,
                              quantity: 1,
                              name: "แฟนต้าสตอเบอรี่",
                              nameEn: "Fanta Strawberry"
                          }
                      ]
                  },
                  {
                      cartId: 3,
                      stockistProductId: 1,
                      productId: 1,
                      type: "Product",
                      privilegeType: "",
                      name: "แฟนต้าสตอเบอรี่",
                      nameEn: "Fanta Strawberry",
                      quantity: 1,
                      amountPerUnit: 724.00,
                      totalAmount: 724.00,
                      createAt: "2024/11/13"
                  }
              ]
          }
      ]
  }

    ngOnDestroy(): void {
      window.localStorage.removeItem('createOrder')
    }

    ngOnInit() {
        this.cartService.getMyCart().subscribe()
        // const orderData = window.localStorage.getItem('createOrder');
        const orderData = this.createOrder

        if (orderData) {
          // this.retailerPurchaseOrder = JSON.parse(orderData)
          this.retailerPurchaseOrder = orderData
          this.cartService.getRetailerAddress().subscribe({next: (res) => {this.retailerDetail = res},error: (err) => {console.error(err)}})
          this.orderProductNumber = this.retailerPurchaseOrder.orderLists.length
          this.sum_data()
        } else {
          // this.route.navigate(['/retailer']);
        }
    }

    isPurchaseOrderHaveGiftPromotion(order: PurchaseOrder): boolean {
      if (order.promotions.length > 0) {
          return order.promotions.some((promotion) => promotion.promotionType === "Gift");
      }
      return false;
  }

    navigateToMainPage() {
      this.cartService.getMyCart().subscribe()
      this.route.navigate(['retailer/products'])
    }

    sum_data() {
      this.retailerPurchaseOrder.totalOrderAmount = 0
      this.retailerPurchaseOrder.shippingCost = 0
      this.retailerPurchaseOrder.couponDiscount = 0
      this.retailerPurchaseOrder.promotionDiscount = 0
      this.retailerPurchaseOrder.netAmount = 0

      this.retailerPurchaseOrder.total_quantity_all = 0

      this.retailerPurchaseOrder.orderLists.forEach(element => {

        element.total_quantity = 0

        this.retailerPurchaseOrder.totalOrderAmount = Number(this.retailerPurchaseOrder.totalOrderAmount.toFixed(2)) + Number(element.totalOrderAmount.toFixed(2))
        this.retailerPurchaseOrder.shippingCost = Number(this.retailerPurchaseOrder.shippingCost.toFixed(2)) + Number(element.shippingCost.toFixed(2))
        this.retailerPurchaseOrder.promotionDiscount = Number(this.retailerPurchaseOrder.promotionDiscount.toFixed(2)) + Number(element.promotionDiscount.toFixed(2))
        this.retailerPurchaseOrder.couponDiscount = Number(this.retailerPurchaseOrder.couponDiscount.toFixed(2)) + Number(element.couponDiscount.toFixed(2))
        this.retailerPurchaseOrder.netAmount = Number(this.retailerPurchaseOrder.netAmount.toFixed(2)) + Number(element.netAmount.toFixed(2))

        element.itemInCart.forEach(item => {

          element.total_quantity = Number(element.total_quantity.toFixed(0)) + Number(item.quantity.toFixed(0))

        });

        this.retailerPurchaseOrder.total_quantity_all = Number(this.retailerPurchaseOrder.total_quantity_all.toFixed(0)) + Number(element.total_quantity.toFixed(0))

      });

    }

    // getRetailerPurchaseOrder(retailerPurchaseOrderNumber: string) {
    //     this.purchaseOrderService
    //         .getRetailerPurchaseOrder(retailerPurchaseOrderNumber)
    //         .pipe(
    //             delay(2000),
    //             tap(() => {
    //                 this.cartService.notifyCartChanged(); // call notifyCart here
    //             }),
    //         )
    //         .subscribe((x) => {
    //             this.retailerPurchaseOrder = x.data;
    //         });
    // }

    formatCurrency(amount: number ) {
        return amount ? `${amount.toFixed(2)}฿` : ''
    }

    getAddress() {
      return this.retailerDetail?.billingAddress + " " + this.retailerDetail?.billingSubdistrict + " " + this.retailerDetail?.billingDistrict + " " + this.retailerDetail?.billingProvince + " " + this.retailerDetail?.billingPostCode
  }

}
