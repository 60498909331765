import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdditionalPrivileges, ICart, SelectGiftPayload } from '@core/models/retailer/promotion-v2.model';
import { AdditionalPrivilege, IVerifyCartPayload} from '@core/models/retailer/verify-cart-payload.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { SelectedGiftDetail } from '../additional-gift-detail/additional-gift-detail.component';

@Component({
  selector: 'app-modal-select-gift',
  templateUrl: './modal-select-gift.component.html',
  styleUrls: ['./modal-select-gift.component.scss']
})
export class ModalSelectGiftComponent implements OnInit {
  @Output() clickClose = new EventEmitter<void>();

  @Input() cart: ICart[] = [];
  @Input() selectGiftPayload: SelectGiftPayload[] = [];

  isAllGiftsSelected = false;
  selectedItemList: Record<string, SelectedGiftDetail> = {};
  additionalPrivileges: AdditionalPrivileges[] = [];

  constructor(
    private route: Router,
    private cartService: CartV2Service,
  ) {}

  ngOnInit(): void {
    this.loadData();  
  }

  loadData() {
    this.additionalPrivileges = this.selectGiftPayload.length
    ? this.selectGiftPayload.map(payload => payload.additionalPrivileges).flat()
    : [];
  }

  onAllGiftsSelected(isSelected: boolean) {
    this.isAllGiftsSelected = isSelected;
  }

  onSelectedAdditional(isAdditional: Record<string, SelectedGiftDetail>) {
    this.selectedItemList = isAdditional
  }

  closeModal() {
    this.clickClose.emit()
  }

  navigateToCategory() {
    this.route.navigate([`/retailer/summary-order`]);
  }

  onSummaryOrder() {
    const payload: IVerifyCartPayload[] = this.cart
        .filter(stockist => stockist.itemInCart.some(item => item.isSelected))
        .map(stockist => {

          const dataA = this.selectGiftPayload.filter(selectGiftItem => selectGiftItem.stockistId === stockist.stockistId).map(selectGiftItem => selectGiftItem.additionalPrivileges.map(additionalPrivilegeItem => {
            const additionalP:AdditionalPrivilege = {
              name: additionalPrivilegeItem.name,
              promotionId: additionalPrivilegeItem.promotionId,
              discount: additionalPrivilegeItem.discount ?? 0,
              selectedGifts: Object.values(this.selectedItemList).filter(selectGiftDetail => selectGiftDetail.stockistId === stockist.stockistId && additionalPrivilegeItem.promotionId === selectGiftDetail.promotionId).map(item => item.selectedGift)
            }
            return additionalP
          }))
          return {
            stockistId: stockist.stockistId,
            additionalPrivilege:dataA[0],
            cartId: stockist.itemInCart
              .filter(item => item.isSelected)
              .map(selectedItem => selectedItem.cartId)
          }
        });
        
    this.cartService.postverifyCart(payload).subscribe({
        next: () => this.navigateToCategory(),
        error: (error) => {
            console.error('Error fetching additional privileges:', error);
        }
    });
  }
}
