import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';
import { ICreateOrderResponse } from '@core/models/retailer/createOrderResponse.model';
import { ICart, IValidatePayload, SelectGiftPayload } from '@core/models/retailer/promotion-v2.model';
import { IVerifyCartPayload } from '@core/models/retailer/verify-cart-payload.model';

@Injectable({
    providedIn: 'root',
})
export class CartV2Service {
    constructor(private http: HttpClient) { }
    private cartChangedSource = new Subject<void>();
    cartChanged = this.cartChangedSource.asObservable();

    private cartItemCount = new BehaviorSubject(0);
    public currentItemCount = this.cartItemCount.asObservable();

    getMyCart(): Observable<ICart[]> {
        return this.http.get<ICart[]>(`${environment.apiAdminV2Url}/retailer/cart`).pipe(
            tap((response) => {
                if (response) {
                    const count = response.reduce((acc, cart) => acc + cart.itemInCart.reduce((accItem, item) => accItem + item.quantity, 0), 0)
                    this.cartItemCount.next(count);
                }
            }),
            map((response) => response)
        );
    }

    getAdditionalPrivilege(payload: IValidatePayload[]): Observable<SelectGiftPayload[]> {
        return this.http.post<SelectGiftPayload[]>(
            `${environment.apiPromotionV2Url}/promotions/additional-privilege`,
            payload
        );
    }

    getVerifyCart(): Observable<ICart[]> {
        return this.http.get<ICart[]>(`${environment.apiAdminV2Url}/retailer/verify-cart`).pipe(
            tap((response) => {
                if (response) {
                    const count = response.reduce((acc, cart) => acc + cart.itemInCart.reduce((accItem, item) => accItem + item.quantity, 0), 0)
                    this.cartItemCount.next(count);
                }
            }),
            map((response) => response)
        );
    }

    postverifyCart(payload: IVerifyCartPayload[]) {
        return this.http.post(
            `${environment.apiAdminV2Url}/retailer/verify-cart`,
            payload
        );
    }

    createOrder() {
        return this.http.post<ICreateOrderResponse>(
            `${environment.apiAdminV2Url}/retailer/order`,null
        );
    }
}

