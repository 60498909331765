import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectGiftPayload, GiftItem } from '@core/models/retailer/promotion-v2.model';
import { TranslateService } from '@ngx-translate/core';

const mockData: SelectGiftPayload = {
  stockistId: 1,
  additionalPrivileges: [{
    name: "Promotion1",
    promotionId: 1,
    discount: undefined ,
    additionalPrivilegeGroups: [
      {
      additionalPrivilegeGroupId: "1",
      giftItem: [
          {
            stockistProductId: 1,
            giftId: 1,
            productId: 1,
            quantity: 1,
            name: "แฟนต้า น้ำอัดลม กลิ่นสตรอเบอร์รี่ 330 มล. แพ็ค 12 ขวด",
            nameEn: "",
            coverImagePathThump: "https://wabi2b.z23.web.core.windows.net/preview/Product/211363/CoverImage/d50f9f11-124f-4b07-a9d3-c49a335a38d2.PNG"
          },
          {
            stockistProductId: 2,
            giftId: 2,
            productId: 2,
            quantity: 1,
            name: "โค้ก น้ำอัดลม 1.25 ลิตร แพ็ค 12 ขวด",
            nameEn: "",
            coverImagePathThump: "https://wabi2b.z23.web.core.windows.net/preview/Product/210932/CoverImage/c8477e97-a718-4d22-8e68-2b87a04510aa.PNG"
          },
        ]
      },
      {
      additionalPrivilegeGroupId: "2",
      giftItem: [
          {
            stockistProductId: 1,
            giftId: 1,
            productId: 1,
            quantity: 1,
            name: "ชเวปส์ น้ำอัดลมกลิ่นมะนาวเปรี้ยวซ่า แพ็ค 12 กระป๋อง",
            nameEn: "",
            coverImagePathThump: "https://wabi2b.z23.web.core.windows.net/preview/Product/226111/CoverImage/e66859cf-410d-4f25-a0ec-36392a1bf1d3.PNG",
          },
        ]
      }
    ]
  },
  {
    name: "Promotion2",
    promotionId: 1,
    discount: undefined ,
    additionalPrivilegeGroups: [
      {
      additionalPrivilegeGroupId: "1",
      giftItem: [
          {
            stockistProductId: 1,
            giftId: 1,
            productId: 1,
            quantity: 1,
            name: "แฟนต้า น้ำอัดลม กลิ่นสตรอเบอร์รี่ 330 มล. แพ็ค 12 ขวด",
            nameEn: "",
            coverImagePathThump: "https://wabi2b.z23.web.core.windows.net/preview/Product/211357/CoverImage/22066a6a-db23-4d9d-88f7-f0f1de2f31ab.PNG"
          }
        ]
      },
      {
      additionalPrivilegeGroupId: "2",
      giftItem: [
        {
          stockistProductId: 2,
          giftId: 2,
          productId: 2,
          quantity: 1,
          name: "โค้ก น้ำอัดลม 1.25 ลิตร แพ็ค 12 ขวด",
          nameEn: "",
          coverImagePathThump: "https://wabi2b.z23.web.core.windows.net/preview/Product/210932/CoverImage/c8477e97-a718-4d22-8e68-2b87a04510aa.PNG"
        },
        ]
      }
    ]
  }]
}

@Component({
  selector: 'app-additional-gift-detail',
  templateUrl: './additional-gift-detail.component.html',
  styleUrls: ['./additional-gift-detail.component.scss']
})
export class AdditionalGiftDetailComponent implements OnInit{
  @Output() allGiftsSelected = new EventEmitter<boolean>();

  giftItem?: SelectGiftPayload
  selectedItemList: Record<string, GiftItem> = {};
  currentLang = 'th';

  constructor(
    private translate: TranslateService,
  ) {
    this.currentLang = this.translate.currentLang
  }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.giftItem = mockData
  }

  onGiftSelectionChanged() {
    const isAllSelected = this.checkIsAllGiftSelected();
    this.allGiftsSelected.emit(isAllSelected);
  }

  onSelectGift(additionalIndex: number, giftGroupIndex: number, giftItem: GiftItem) {
    const uniqueKey = `${additionalIndex}_${giftGroupIndex}`;
    this.selectedItemList[uniqueKey] = giftItem;
    this.onGiftSelectionChanged();
  }

  checkIsSelectedGift(additionalIndex: number, giftGroupIndex: number) {
    const uniqueKey = `${additionalIndex}_${giftGroupIndex}`;
    return !!this.selectedItemList[uniqueKey];
  }

  checkIsAllGiftSelected() {
    if (this.giftItem && this.giftItem.additionalPrivileges.length > 0) {
      return this.giftItem.additionalPrivileges.every((privilege, additionalIndex) =>
        privilege.additionalPrivilegeGroups.every((_, giftGroupIndex) => 
          this.checkIsSelectedGift(additionalIndex, giftGroupIndex)
        )
      );
    }
    return false;
  }

  countSelectedForPrivilege(additionalIndex: number): number {
    const additionalKeyPrefix = `${additionalIndex}_`;
    return Object.keys(this.selectedItemList).filter(key => key.startsWith(additionalKeyPrefix)).length;
  }
}
