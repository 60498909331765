import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddPromotionToCart, ApplyPromotion, ApplyPromotionBundlePayload, PromotionBundleDetail } from '@core/models/retailer/promotion-v2.model';
import { environment } from '@environments/environment';
import { CartV2Service } from './cart-v2.service';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PromotionV2Service {
    basePath = `${environment.apiPromotionV2Url}`;

    constructor(private http: HttpClient,
        private cartService: CartV2Service
    ) { }

    getPromotionBundleById(id: string) {
        return this.http.get<PromotionBundleDetail>(
            `${this.basePath}/retailer/promotions/bundles/${id}`
        );
    }

    addPromotionBundleToCart(promotionItem: AddPromotionToCart) {
        return this.http.post(
            `${this.basePath}/retailer/promotions/bundles/cart`, promotionItem
        ).pipe(tap(() => this.cartService.notifyCartChanged()));
    }

    addPromotionTierToCart(promotionItem: AddPromotionToCart) {
        return this.http.post(
            `${this.basePath}/retailer/promotions/tiers/cart`, promotionItem
        ).pipe(tap(() => this.cartService.notifyCartChanged()));
    }

    applyPromotionBundleToCart(promotionId: number, payload: ApplyPromotionBundlePayload) {
        return this.http.post<ApplyPromotion>(
            `${this.basePath}/retailer/promotions/bundles/${promotionId}/apply`, payload
        )
    }


}
