/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
    Component,
    Input,
} from '@angular/core';

import { IProductItem } from '@core/models/retailer/promotion-v2.model';
@Component({
    selector: 'app-my-cart-product-detail-v2',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class MyCartProductDetailV2Component {
    @Input() productDetail: IProductItem[] | undefined;

    currentLang = 'th';
}
