import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductParams, showCasesStockistProduct } from '@core/models/retailer/product.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { FavoriteService } from '@core/services/retailer/favorite.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-card-item-show-cases-product',
    templateUrl: './card-item-show-cases-product.component.html',
    styleUrls: ['./card-item-show-cases-product.component.scss'],
})
export class CardItemShowCasesProductComponent implements OnInit {
    @Input() item: showCasesStockistProduct | undefined;
    @Input() filter: ProductParams | undefined

    productImage = '';
    storeImage = '';
    currentLang = 'th';
    isLoadingFavorite = false
    imagePromotion = '';

    constructor(
        private router: Router,
        private azureBlobService: AzureBlobService,
        private translate: TranslateService,
        private favoriteService: FavoriteService
    ) { }

    onClickCard() {
        if (this.filter) {
            const encodeFilter = JSON.stringify(this.filter)
            localStorage.setItem('oldFilter', encodeFilter)
        }
        if (this.item?.type == 'Product') {
          this.router.navigateByUrl(`/retailer/product-detail/${this.item?.id}`);
        } else if (this.item?.type == 'Bundle') {
          this.router.navigateByUrl(`/retailer/promotion-bundle/${this.item?.id}`);
        } else if (this.item?.type == 'Tier') {
          this.router.navigateByUrl(`/retailer/promotion-tier/${this.item?.id}`);
        }

    }

    async ngOnInit(): Promise<void> {
        this.currentLang = this.translate.currentLang;
        if (this.item) {
            await this.ImageurlToFile(
                this.item.coverImagePathThumb,
                'Product'
            );
            const stkID = this.item.stockist.id;
            const image = this.item.stockist.logoPath.split("/").pop();
            this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
            // this.item.promotions = [{ discount: 100, discountType: "Currency", id: 1, type: "Gift" }, { discount: 5, discountType: "Currency", id: 1, type: "Discount" }]
        }
    }

    async ImageurlToFile(filePath: string, type: 'Product' | 'Store') {
        if (!filePath) return;
        if (filePath.includes('http')) {
            if (type === 'Product') {
                this.productImage = filePath;
            } else {
                this.storeImage = filePath;
            }
            return;
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile, type);
            });
    }

    displayImage(file: File, type: 'Product' | 'Store') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                if (type === 'Product') {
                    this.productImage = valueToAdd.toString();
                } else {
                    this.storeImage = valueToAdd.toString();
                }
            }
        };
        reader.readAsDataURL(file);
    }

    checkHavePromotion(item: showCasesStockistProduct) {
      if (item.type === 'Bundle' && item.privilegeType === 'Discount') {
        this.imagePromotion = 'assets/icons/bundle_discount.png'
        return true
      } else if (item.type === 'Bundle' && item.privilegeType === 'Gift') {
        this.imagePromotion = 'assets/icons/bundle_gift.png'
        return true
      } else if (item.type === 'Tier' && item.privilegeType === 'Discount') {
        this.imagePromotion = 'assets/icons/tier_discount.png'
        return true
      } else if (item.type === 'Tier' && item.privilegeType === 'Gift') {
        this.imagePromotion = 'assets/icons/tier_gift.png'
        return true
      }

      return false

    }


    // onClickFavorite(stockistProductId: number, type: 'Add' | 'Remove') {
    //     if (this.isLoadingFavorite) return
    //     switch (type) {
    //         case 'Add':
    //             this.isLoadingFavorite = true
    //             this.favoriteService.AddFavorite(stockistProductId).subscribe({
    //                 next: () => {
    //                     if (this.item) {
    //                         this.item.favorited = true
    //                     }
    //                     this.isLoadingFavorite = false
    //                 },
    //                 error: (err) => {
    //                     console.error(err)
    //                     this.isLoadingFavorite = false
    //                 }
    //             })
    //             break;
    //         case 'Remove':
    //             this.isLoadingFavorite = true
    //             this.favoriteService.RemoveFavorite(stockistProductId).subscribe({
    //                 next: () => {
    //                     if (this.item) {
    //                         this.item.favorited = false
    //                     }
    //                     this.isLoadingFavorite = false
    //                 },
    //                 error: (err) => {
    //                     console.error(err)
    //                     this.isLoadingFavorite = false
    //                 }
    //             })
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // checkHavePromotion(type: string) {
    //     if (this.item && this.item.promotions) {
    //         return this.item.promotions.find((x) => x.type === type)
    //     }
    //     return false
    // }

    // getMostDiscountAndUnitType(type: string) {
    //     if (this.item && this.item.promotions && this.checkHavePromotion("Discount")) {
    //         const sortedItem = this.item.promotions.filter(x => x.type === "Discount").sort((a, b) => b.discount - a.discount)[0]
    //         switch (type) {
    //             case "Discount":
    //                 return sortedItem.discount
    //             case "Unit":
    //                 return sortedItem.discountType === 'Currency' ? '฿' : '%'
    //             default:
    //                 return ""
    //         }
    //     } else {
    //         return ""
    //     }
    // }
}
