<div class="aura-container-custom background-order-confirm" >
  <div class="pr-1 d-flex justify-content-between fs-5 background-order-head ">
    <div >
      <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
        {{ "TITLE.ORDER_SENT_STORE" | translate }}
      </p>
      <p class="fw-semibold text-head-mid">
        {{ "TITLE.ORDER_NUMBER" | translate }}: {{ retailerPurchaseOrder.id }}
      </p>
      <p class="fw-semibold text-head-last">
        {{ "TITLE.ORDER_AT" | translate }} {{ retailerPurchaseOrder.createdAt | displayDate }}
      </p>
    </div>
    <div>
      <img
            class=""
            src="assets/images/Order_Created.svg"
            width="52px"
            height="52px"
            alt=""
          />
    </div>
  </div>
  <div class="background-order-sum">
    <div class="d-flex flex-column px-3 pt-2 gap-1">
      <div class="d-flex flex-row justify-content-between ">
        <p class="fw-normal mb-0">{{ "PAYMENT.PRODUCT_TOTAL" | translate }} ({{ retailerPurchaseOrder.totalQuantityAll }} {{ 'TITLE.UNIT' | translate}})</p>
        <p class="fw-normal mb-0">
          {{ formatCurrency(retailerPurchaseOrder.totalOrderAmount) }}
        </p>
      </div>
      <div *ngIf="retailerPurchaseOrder.promotionDiscount>0" class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p class="fw-normal mb-0 text-discount">
          -{{ formatCurrency(retailerPurchaseOrder.promotionDiscount) }}
        </p>
      </div>
        <div *ngIf="retailerPurchaseOrder.couponDiscount>0" class="d-flex flex-row justify-content-between">
          <p class="fw-normal mb-0">{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
          <p class="fw-normal mb-0 text-discount">
            -{{ formatCurrency(retailerPurchaseOrder.couponDiscount) }}
          </p>
        </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.SHIPPING_COST" | translate }} ({{ "TITLE.ALL_STORE" | translate }})</p>
        <p class="fw-normal mb-0">
          {{ formatCurrency(retailerPurchaseOrder.shippingCost) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between mb-2">
        <p class="fs-5 fw-semibold">{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p class="fs-5 fw-semibold">
          {{ formatCurrency(retailerPurchaseOrder.netAmount) }}
        </p>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center gap-2 pt-3 bg-white"
    *ngFor="
      let purchaseOrder of retailerPurchaseOrder.orderLists;
      let last = last
    "
    [ngStyle]="{ 'border-bottom': !last ? '1px solid #D9D9D9' : 'none' }"
  >
    <div class="d-flex justify-content-between background-order-item-head">
      <div style="width: 75%;">
        <p class="text-number-order-item">
          {{ "TITLE.NUMBER" | translate }}
        </p>
        <p class="fs-5 fw-semibold mb-0">
          {{ purchaseOrder.orderId }}
        </p>
        <p class="mb-0">{{ "TITLE.ORDER_AT" | translate }} {{ purchaseOrder.createdAt | displayDate }}</p>
      </div>
      <div>
        <button class="button-status">
          {{ "TITLE.WAIT_COMFIRM" | translate }}
        </button>
      </div>
    </div>
    <div class="background-order-item">
      <div
        class="d-flex flex-row pt-3 justify-content-between align-items-center"
      >
        <div class="flex-column">

          <p class="fw-normal mb-1">
            <img
            class="mr-5"
            src="assets/images/shipfrom.svg"
            width="20px"
            height="20px"
            alt=""
          /><label class="fw-normal text-product">{{ "TITLE.DELIVER_FROM" | translate }}</label></p>
          <p class="mb-0 mr-3 text-detail">{{ purchaseOrder.stockistName }}</p>
        </div>
        </div>
        <div class="flex-column">
          <p class="fw-normal pt-3 mb-1">
            <img
            class="mr-3"
            src="assets/images/shipaddress.svg"
            width="20px"
            height="20px"
            alt=""
          /><label class="text-product">{{ "TITLE.DELIVERED_AT" | translate }}</label></p>
          <p class="mb-1 text-detail">{{ retailerDetail?.retailerName }}</p>
          <p class="mb-0 fw-light text-detail">{{ getAddress() }}</p>
        </div>
      <div class="fs-6 fw-semibold mt-3">
        <p>{{ "TITLE.PRODUCT" | translate }}</p>
      </div>
    </div>

    <app-show-product-promotion-list style="padding: 0rem 1rem;"
      [products]="purchaseOrder.itemInCart"
    ></app-show-product-promotion-list>


    <app-show-additional-privilege-list *ngIf="purchaseOrder.additionalPrivilege && purchaseOrder.additionalPrivilege.length > 0 " [additionalPrivileges]="purchaseOrder.additionalPrivilege"></app-show-additional-privilege-list>

    <div class="d-flex flex-column px-3 pt-2 gap-1 background-order-item-bottom">
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.PRODUCT_TOTAL" | translate }} ({{ purchaseOrder.totalQuantity }} {{ "TITLE.ITEM" | translate }})</p>
        <p class="fw-normal mb-0">
          {{ formatCurrency(purchaseOrder.totalOrderAmount) }}
        </p>
      </div>
      <div *ngIf="purchaseOrder.promotionDiscount > 0" class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p class="fw-normal mb-0 text-discount">
          -{{ formatCurrency(purchaseOrder.promotionDiscount) }}
        </p>
      </div>
      <div *ngIf="purchaseOrder.couponDiscount > 0" class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
        <p class="fw-normal mb-0 text-discount">
          -{{ formatCurrency(purchaseOrder.couponDiscount) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-normal mb-0">{{ "PAYMENT.SHIPPING_COST" | translate }}</p>
        <p class="fw-normal mb-0">
          {{ formatCurrency(purchaseOrder.shippingCost) }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between pt-3">
        <p class="fs-5 fw-semibold">{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p class="fs-5 fw-semibold">
          {{ formatCurrency(purchaseOrder.netAmount) }}
        </p>
      </div>

      <div *ngIf="last" class="d-flex flex-row justify-content-center pt-3 pl-3">
          <button class="button-home" routerLink="/retailer">
            {{ "ACTION.BACK_PAGE_MAIN" | translate }}
          </button>
      </div>
    </div>
  </div>
</div>
