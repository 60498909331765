<div *ngFor="let privilege of giftItem?.additionalPrivileges; let additionalIndex = index">
    <div *ngIf="privilege.additionalPrivilegeGroups"
        class="selected-product-container">
        <div class="gift-list-title d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center gap-2">
                <img [src]="'assets/icons/pink-gift.svg'" />
                <span class="gift-name">{{privilege.name}}</span>
            </div>
            <p class="gift-amount">{{countSelectedForPrivilege(additionalIndex) +'/'+ privilege?.additionalPrivilegeGroups?.length}}</p>
        </div>
        <!-- -------------------------- Gift List -------------------------------------------------- -->
        <div *ngFor="let giftLevel of privilege?.additionalPrivilegeGroups; let giftGroupIndex = index"
            class="selected-gift-container">
            <div class="content-gift-list-item">
                <p class="gift-list-item">{{'SUMMARY.GIFT' | translate}} {{giftGroupIndex + 1}}</p>
                <div class="gift-selected-count">{{checkIsSelectedGift(additionalIndex, giftGroupIndex) ? '1':'0'}}/1</div>
            </div>
            <div *ngFor="let giftItem of giftLevel.giftItem; let giftItemIndex = index">
                <div class="content-gift-list-item">
                    <div class="d-flex align-items-center gap-2">
                        <input type="radio" (click)="onSelectGift(additionalIndex, giftGroupIndex, giftItem)"
                            [id]="'giftItem' + giftItemIndex + '_' + giftGroupIndex + '_' + additionalIndex"
                            [name]="'giftId' + giftGroupIndex + '_' + additionalIndex"
                            [value]="giftItem.giftId" class="custom-radio">
                        <label [for]="'giftItem' + giftItemIndex + '_' + giftGroupIndex + '_' + additionalIndex"></label>
                        <img class="gift-item-image" alt="giftImage" [src]="giftItem.coverImagePathThump" />
                        <span class="gift-name card-text-stockist">{{ currentLang === 'th' ? giftItem.name : giftItem.nameEn }}</span>
                    </div>
                    <span class="gift-amount">x{{giftItem.quantity}}</span>
                </div>
            </div>
        </div>
    </div> 
</div>