<div class="additional-item-share">
  <div class="group-title-additional" (click)="changeShow()">
    <p class="title-name fs-sm fw-semibold mb-0 pt-3">
      สิทธิประโยชน์เพิ่มเติมจากการซื้อโปรโมชัน
    </p>
    <p class="title-arrow">
      <i class="fa fa-chevron-up fw-semibold" aria-hidden="true" *ngIf="isShow"></i>
      <i class="fa fa-chevron-down fw-semibold" aria-hidden="true" *ngIf="!isShow"></i>
    </p>
  </div>

  <ng-container *ngIf="isShow">
    <p class="title-gift fs-sm fw-semibold mb-0 pt-2 pb-2" *ngIf="haveGift">
    ของแถม
    </p>
    <ng-container *ngFor="let additionalPrivilege of additionalPrivileges">
      <div class="group-additional" *ngIf="additionalPrivilege.selectedGift.length > 0">
        <ng-container *ngFor="let gift of additionalPrivilege.selectedGift">
          <p class="additional-item fs-sm mb-0">
            {{ gift.name }}
          </p>
          <span class="quantity fs-sm">
            x{{ gift.quantity }}
          </span>
        </ng-container>
      </div>
    </ng-container>

    <p class="title-gift fs-sm fw-semibold mb-0 pt-2 pb-2" *ngIf="haveDiscount">
      ส่วนลด
    </p>

    <ng-container *ngFor="let additionalPrivilege of additionalPrivileges">
      <div class="group-additional" *ngIf="additionalPrivilege.selectedGift.length <= 0">
          <p class="additional-item fs-sm ">
            จาก{{ additionalPrivilege.Name }}
          </p>
          <span class="quantity fs-sm">
            {{ getFormattedCurrency(additionalPrivilege.discount) }}
          </span>
      </div>
    </ng-container>
  </ng-container>

</div>
