<div class="additional-item-share">
  <div class="group-title-additional" (click)="changeShow()">
    <p class="title-name fs-sm fw-semibold mb-0 pt-3">
      {{ 'ADDITIONAL_PRIVILEGE.TITLE' | translate }}
    </p>
    <p class="title-arrow">
      <i class="fa fa-chevron-up fw-semibol" aria-hidden="true" [style.transform]="!isShow ? 'rotate(180deg)' : 'none'"></i>
    </p>
  </div>

  <ng-container *ngIf="isShow">
    <p class="title-gift fs-sm fw-semibold mb-0 pt-2 pb-2" *ngIf="haveGift">
      {{ 'ADDITIONAL_PRIVILEGE.TITLE_GIFT' | translate }}
    </p>
    <ng-container *ngFor="let additionalPrivilege of additionalPrivileges">
      <div *ngIf="additionalPrivilege.selectedGifts.length > 0">
        <div *ngFor="let gift of additionalPrivilege.selectedGifts" >
          <div class="group-additional">
            <p class="additional-item fs-sm mb-0">
              {{ currentLang === 'th' ? gift.name : gift.nameEn ? gift.nameEn : gift.name }}
            </p>
            <span class="quantity fs-sm">
              x{{ gift.quantity }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <p class="title-gift fs-sm fw-semibold mb-0 pt-2 pb-2" *ngIf="haveDiscount">
      {{ 'ADDITIONAL_PRIVILEGE.TITLE_DISCOUNT' | translate }}
    </p>

    <ng-container *ngFor="let additionalPrivilege of additionalPrivileges">
      <div *ngIf="additionalPrivilege.selectedGifts.length <= 0">
        <div class="group-additional" >
          <p class="additional-item fs-sm ">
            {{ 'ADDITIONAL_PRIVILEGE.FROM' | translate }}{{ additionalPrivilege.name }}
          </p>
          <span class="quantity fs-sm">
            {{ getFormattedCurrency(additionalPrivilege.discount) }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>
