import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IOrderDetailV2 } from '@core/models/retailer/order-preview.model';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-history-detail-deprecated-v2',
  templateUrl: './order-history-detail-deprecated-v2.component.html',
  styleUrls: ['./order-history-detail-deprecated-v2.component.scss']
})
export class OrderHistoryDetailDeprecatedV2Component {
  @Input() orderDetail: IOrderDetailV2 | undefined

  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  getAddress() {
    return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
  }

  navigateToOrderHistory() {
    window.history.back();
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  getTotalQuantity(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.quantity,
        0
      );

      return totalQuantity;
    }

    return 0;
  }
  getTotalAmount(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.amount,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  getNetAmount(): number {
    if (!this.orderDetail) return 0
    const netAmount = this.orderDetail.netAmount;
    return netAmount

  }

  getPaymentInfo(): PaymentInfo {
    return <PaymentInfo>{
      totalQuantity: this.getTotalQuantity(),
      totalAmount: this.getTotalAmount(),
      couponDiscount: this.orderDetail?.couponDiscount,
      promotionDiscount: this.orderDetail?.promotionDiscount,
      shippingCost: this.orderDetail?.shippingCost,
      netAmount: this.getNetAmount(),
    };
  }
}
