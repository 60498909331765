/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderDetail } from '@core/models/retailer/order-preview.model';
import { CartService } from '@core/services/retailer/cart.service';

@Component({
    selector: 'app-order-processed-cancelrequested',
    templateUrl: './order-processed-cancelrequested.component.html',
    styleUrls: ['./order-processed-cancelrequested.component.scss']
})
export class OrderProcessedCancelrequestedComponent implements OnInit {
    data: IOrderDetail | undefined;
    orderId = '';

    constructor(
        private activateRoute: ActivatedRoute,
        private route: Router,
        private cartService: CartService,

    ) { }

    ngOnInit() {
        this.orderId = this.activateRoute.snapshot.paramMap.get('orderid') || '';
        this.getOrderDetail();
    }

    navigateToLanding() {
        this.route.navigate(['/retailer']);
    }

    navigateToOrderHistoryDetail() {
        this.route.navigate([`/retailer/order-history/${this.orderId}`]);
    }

    totalQuantity(): number {
        return this.data?.items.reduce((total, item) => total + item.quantity, 0) || 0;
    }

    getOrderDetail() {
        this.cartService.getOrderById(this.orderId).subscribe({
            next: (res) => {
                this.data = res;
            },
        });
    }

    handleImageError(event: any) {
        event.target.src = 'assets/images/image_error.jpg';
    }

    isPurchaseOrderHaveGiftPromotion(order: IOrderDetail): boolean {
        if (order.promotions.length > 0) {
            return order.promotions.some((promotion) => promotion.promotionType === "Gift");
        }
        return false;
    }
}
