import { ProductParams } from './../../../../../../core/models/retailer/product.model';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductStockist } from '@core/models/retailer/product.model';
import { ProductService } from '@core/services/retailer/product.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

export interface FilterEvent {
    params: ProductParams;
    totalSelected: number;
}

@Component({
    selector: 'app-all-product',
    templateUrl: './all-product.component.html',
    styleUrls: ['./all-product.component.scss'],
})
export class AllProductComponent implements OnInit {
    productList: ProductStockist[] = [];
    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    params: ProductParams = {
        limit: 50,
        page: 1,
        datatable: true,
        orderBy: 'Price_asc'
    };
    showNotFoundIcon = false;
    isOpenFilterModal = false;
    hasMoreData = true;
    total = 0
    filterTotal = 0
    totalOrderPage = 0
    isLoading = false
    currentLang!: string

    constructor(
        private router: ActivatedRoute,
        private productService: ProductService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private location: Location,
    ) { }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang
        this.spinner.show('spinner');
        this.isLoading = true;
        const stockistId = this.router.snapshot.params['stockist-id']
        const categoryId = this.router.snapshot.params['category-id']
        const brandId = this.router.snapshot.params['brand-id']
        const filterHistory = localStorage.getItem('oldFilter')
        if (filterHistory) {
            const filterJson: ProductParams = JSON.parse(filterHistory)
            let totalSelected = 0
            const filter: FilterEvent = {
                params: {
                    page: 1,
                    limit: 50,
                    datatable: true,
                    orderBy: 'Price_asc'
                },
                totalSelected: 0
            }
            if (filterJson.brandIds) {
                filter.params.brandIds = filterJson.brandIds
                totalSelected += filterJson.brandIds.length
            }
            if (filterJson.categoryIds) {
                filter.params.categoryIds = filterJson.categoryIds
                totalSelected += filterJson.categoryIds.length
            }
            if (filterJson.keywords) {
                this.params.keywords = filterJson.keywords
                filter.params.keywords = filterJson.keywords
            }
            filter.totalSelected = totalSelected

            this.filterProduct(filter)
        }
        else if (stockistId || brandId || categoryId) {
            const filter: FilterEvent = {
                params: {
                    page: 1,
                    limit: 50,
                    datatable: true,
                    orderBy: 'Price_asc'
                },
                totalSelected: 0
            }
            if (stockistId) { filter.params.stockistIds = [Number(stockistId)]; filter.totalSelected += 1 }
            if (categoryId) { filter.params.categoryIds = [Number(categoryId)]; filter.totalSelected += 1 }
            if (brandId) { filter.params.brandIds = [Number(brandId)]; filter.totalSelected += 1 }
            this.filterProduct(filter)
        }

        else {
            this.handleSearch();
        }

    }

    search(query: string): void {
        this.params = {
            ...this.params,
            limit: 50,
            page: 1,
            datatable: true,
            keywords: query,
        };
        this.productList = [];
        this.spinner.show('spinner');
        this.isLoading = true;
        this.handleSearch();
    }

    handleSearch() {
        this.spinner.show('spinner');
        this.isLoading = true;
        this.productService
            .getProductsAvailableWithDataTable(this.params)
            .subscribe({
                next: (res) => {
                    this.total = res.total;
                    if (Array.isArray(res.data)) {
                        this.totalOrderPage = res.totalPage
                        if (res.data.length === 0) {
                            this.hasMoreData = false;
                        } else {
                            const newProducts = res.data
                            const existingIds = this.productList.map(
                                (item) => item.id
                            );
                            const uniqueNewProducts = newProducts.filter(
                                (item) => !existingIds.includes(item.id)
                            );

                            this.productList.push(...uniqueNewProducts);
                            this.isLoading = false;
                            this.spinner.hide('spinner');
                        }
                        this.showNotFoundIcon = this.productList.length === 0;
                    }

                    if (!Array.isArray(res.data) || res.data.length === 0) {
                        this.isLoading = false;
                        this.spinner.hide('spinner');
                    }
                },
                error: (err) => {
                    console.error(err)
                    this.isLoading = false;
                    this.spinner.hide('spinner');
                    this.showNotFoundIcon = true
                }
            })
    }

    navigateBack(): void {
        this.location.back()
    }

    showProductList(): boolean {
        return this.productList.length > 0;
    }

    showNotFoundMessage(): boolean {
        return this.showNotFoundIcon && !this.showProductList();
    }

    openFilterModal() {
        this.isOpenFilterModal = true;
    }

    filterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keywords = this.params.keywords;
        this.params = {
            keywords,
            ...filterEvent.params,
        };
        this.productList = [];
        this.hasMoreData = true;
        this.handleSearch();
    }

    onCloseFilterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keywords = this.params.keywords;
        this.params = {
            keywords,
            ...filterEvent.params,
        };
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (window.innerHeight + 64 + window.scrollY >= document.body.offsetHeight && !this.isLoading && this.params?.page && this.params?.page < this.totalOrderPage) {
            this.params.page++;
            this.handleSearch();
        }
    }
}
