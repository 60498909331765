import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';
import { ICreateOrderResponse } from '@core/models/retailer/createOrderResponse.model';
import { CartPayload, ICart, IValidatePayload, IVerifyCartResponse, PromotionBundleDetail, SelectGiftPayload } from '@core/models/retailer/promotion-v2.model';
import { IVerifyCartPayload } from '@core/models/retailer/verify-cart-payload.model';
import { IProductCartPayload, IRetailerAddress, IValidateCouponPayload, IValidateCouponResponse } from '@core/models/retailer/cart.model';

@Injectable({
    providedIn: 'root',
})
export class CartV2Service {
    constructor(private http: HttpClient) { }
    private cartChangedSource = new Subject<void>();
    cartChanged = this.cartChangedSource.asObservable();

    private cartItemCount = new BehaviorSubject(0);
    public currentItemCount = this.cartItemCount.asObservable();

    notifyCartChanged() {
        this.cartChangedSource.next();
    }

    getMyCart(): Observable<ICart[]> {
        return this.http.get<ICart[]>(`${environment.apiAdminV2Url}/retailer/cart`).pipe(
            tap((response) => {
                if (response) {
                    const count = response.reduce((totalCount, cart) => {
                        const cartCount = cart.itemInCart.reduce((itemCount, item) => {
                            if (item.type === 'Tier' && item.items) {
                                const tierCount = item.items.reduce((sum, subItem) => sum + subItem.quantity, 0);
                                return itemCount + tierCount;
                            }
                            return itemCount + item.quantity;
                        }, 0);
                        return totalCount + cartCount;
                    }, 0);
                    this.cartItemCount.next(count);
                }
            }),
            map((response) => response)
        );
    }

    getAdditionalPrivilege(payload: IValidatePayload[]): Observable<SelectGiftPayload[]> {
        return this.http.post<SelectGiftPayload[]>(
            `${environment.apiPromotionV2Url}/promotions/additional-privilege`,
            payload
        );
    }

    getVerifyCart(): Observable<IVerifyCartResponse> {
        return this.http.get<IVerifyCartResponse>(
            `${environment.apiAdminV2Url}/retailer/verify-cart`
        );
    }

    postverifyCart(payload: IVerifyCartPayload[]) {
        return this.http.post(
            `${environment.apiAdminV2Url}/retailer/verify-cart`,
            payload
        ).pipe(tap(() => this.notifyCartChanged()));
    }

    createOrder() {
        return this.http.post<ICreateOrderResponse>(
            `${environment.apiAdminV2Url}/retailer/order`, null
        ).pipe(tap(() => this.notifyCartChanged()));
    }

    updateCartItems(
        payload: CartPayload[]
    ) {
        return this.http
            .patch<CartPayload[]>(`${environment.apiAdminV2Url}/retailer/cart`, payload)
            .pipe(tap(() => this.notifyCartChanged()));
    }

    getRetailerAddress() {
        return this.http.get<IRetailerAddress>(
            `${environment.apiAdminUrl}/retailer/my-address`
        )
    }

    updateShoppingCartItem(payload: IProductCartPayload) {
        return this.http.put(`${environment.apiAdminV2Url}/cart`, payload).pipe(tap(() => this.notifyCartChanged()));
    }

    couponValidate(payload: IValidateCouponPayload) {
        return this.http.post<IValidateCouponResponse>(`${environment.apiAdminV2Url}/retailer/coupon/validate`, payload)
    }

    getBundleCartByCartId(cartId: string): Observable<PromotionBundleDetail> {
        return this.http.get<PromotionBundleDetail>(`${environment.apiAdminV2Url}/retailer/bundle-cart/${cartId}`)
    }

    getTierCartByCartId(cartId: string): Observable<PromotionBundleDetail> {
      return this.http.get<PromotionBundleDetail>(`${environment.apiAdminV2Url}/retailer/tier-cart/${cartId}`)
    }
}
