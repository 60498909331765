<div class="row gap-2 align-items-end mt-2 mb-4">
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.TOTAL_ALL" | translate }} ({{ paymentInfo.totalQuantity }} {{ "TITLE.ITEM" | translate }})</p>
        <p data-cy="totalProductPrice">
            {{formatCurrency(paymentInfo.totalAmount)}}
        </p>
    </div>
    <div *ngIf="paymentInfo.promotionDiscount > 0" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p class="color-red">
            -{{formatCurrency(paymentInfo.promotionDiscount)}}
        </p>
    </div>
    <div *ngIf="paymentInfo.couponDiscount > 0" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
        <p class="color-red">
            -{{formatCurrency(paymentInfo.couponDiscount)}}
        </p>
    </div>
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.SHIPPING_COST" | translate }}</p>
        <p>
            {{formatCurrency(paymentInfo.shippingCost)}}
        </p>
    </div>
    <div class="d-flex justify-content-between fw-semibold">
        <p>{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p data-cy="totalPrice">
            {{formatCurrency(paymentInfo.netAmount)}}
        </p>
    </div>
</div>
