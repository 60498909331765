<div *ngIf="isLoading" class="overlay">
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="true"
    name="spinner" style="margin-top: 0px !important"></ngx-spinner>
</div>

<div class="aura-container-custom">
    <nav id="header-page" class="navbar">
        <div class="back-container justify-content-start " (click)="goBack()">
            <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
        </div>
    </nav>

    <div *ngIf="checkProduct && data" class="row">
        <div data-id="promotion-img-area" class="col-12 col-md-6 d-flex justify-content-center">
            <div data-id="img-area" class="image-area">
                <img [src]="this.data.coverImagePath" class="image-custom w-100 h-100"
                    data-id="promotion-cover-img" />
            </div>
        </div>

        <!--------------------------------------- Promotion detail area --------------------------------------->
        <div data-id="promotion-detail-area" class="col-12 col-md-6  order-sm-2 promotion-detail-wrapper">
            <div class="d-flex justify-content-between w-100">
                <p data-id="promotion-name" class="fw-bolder fs-hd" [attr.data-id]="data && data.id + '-name'"
                    [ngStyle]="{wordbreak: 'break-word'}"> {{ data && data.name }}</p>
                <div data-id="favorite-button">
                    <div *ngIf="!data.favorited" (click)="onClickFavorite(data.id,'Add')">
                        <img [src]="'assets/icons/UnFavorite.svg'" />
                    </div>
                    <div *ngIf="data.favorited" (click)="onClickFavorite(data.id,'Remove')">
                        <img [src]="'assets/icons/Favorite.svg'" />
                    </div>
                </div>
            </div>

            <div *ngIf="data.privilegeType === 'Gift'" class="promotion-price" data-id="promotion-price-gift">
                <div class="d-flex flex-column">
                    <p class="text-unit-price mb-0 fs-xs" [attr.data-id]="data.id + '-label-unit-price'">
                        {{ 'PAYMENT.UNIT_PRICE' | translate }}
                    </p>
                    <p class="fw-bolder mb-0" [attr.data-id]="data.id + '-unit-price'">
                        <app-custom-currency [value]="data.price" integerSize="20px"
                            fractionalSize="16px"></app-custom-currency>
                    </p>
                    <p class="text-color-coke-red mb-0 fs-sm" [attr.data-id]="data.id + '-label-bundle-gift'">
                        {{ 'TITLE.PROMOTION_TEXT_GIFT' | translate }}
                    </p>
                </div>
                <img [src]="'assets/images/bundle-include-gift.svg'" />
            </div>

            <div *ngIf="data.privilegeType === 'Discount'" class="promotion-price" data-id="promotion-price-discount">
                <div class="d-flex flex-column">
                    <p class="text-unit-price mb-0 fs-xs" [attr.data-id]="data.id + '-label-unit-price'">
                        {{ 'PAYMENT.UNIT_PRICE' | translate }}
                    </p>
                    <p class="mb-0 d-flex gap-1 align-items-center" [attr.data-id]="data.id + '-unit-price'">
                        <app-custom-currency class="text-color-coke-red fw-bolder" [value]="data.price - data.discount"
                            integerSize="20px" fractionalSize="16px"></app-custom-currency>
                        <app-custom-currency class="text-strikethrough" [value]="data.price" integerSize="14px"
                            fractionalSize="14px"></app-custom-currency>
                    </p>
                    <p class="text-color-coke-red mb-0 fs-sm" [attr.data-id]="data.id + '-label-bundle-gift'">
                        {{ 'SUMMARY.DISCOUNT_AMOUNT' | translate }} {{ data.discount }}฿ 🎉
                    </p>
                </div>
                <img [src]="'assets/images/bundle-include-discount.svg'" />
            </div>

            <div class="stockist-detail">
                <img class="stk-icon" [src]="data.stockist.coverImagePathThump"
                    onerror="this.src='assets/images/image_error.jpg'" />
                <p class="fs-sm mb-0">{{ data.stockist.name }}</p>
            </div>
            <div class="stockist-underline"></div>

            <!--------------------------------------- Promotion name area --------------------------------------->
            <div class="promotion-name">
                <div class="fw-md fs-15">{{ 'TITLE.PROMOTION_DETAIL' | translate }}</div>
                <div class="fw-rg fs-sm">{{ data.description }}</div>
                <div class="text-promotion-period fs-xs">{{ 'TITLE.PROMOTION_PERIOD' | translate }} : {{ data.startDate|
                    date: 'dd' }} - {{ formatDateThai(data.endDate) }}</div>
            </div>

            <div class="header-select-product">
                <div class="icon-group">
                    <img class="icon" [src]="'assets/images/Icon-Bundle.svg'"/>
                    <p class="text-select-product fw-rg fs-sm mb-0">{{ 'TITLE.PRODUCT_CHOOSE' | translate }}</p>
                </div>
                <p class="fw-rg fs-sm mb-0"> {{ totalSelectedQuantities }}/{{totalConditionAmount}}</p>
            </div>

            <div *ngFor="let group of data.promotionBundleGroups; let groupIndex = index" class="select-product-area">
                <div class="counter-row">
                    <p class="fw-bd fs-md mb-0">{{ 'PROMOTION_BUNDLE.SET' | translate}} {{groupIndex+1}}</p>
                    <div class="select-counter fw-rg fs-xs">{{selectedQuantities[groupIndex]}}/{{group.conditionAmount}}
                    </div>
                </div>

                <!--------------------------------------- Select item area --------------------------------------->
                <div *ngFor="let product of group.promotionBundleGroupItems; let productIndex = index">
                    <div class="checkbox-item">
                        <div class="item-content" (click)="!product.isSelected && isMaximumGroupQty(groupIndex) ? null : onProductSelection(groupIndex, productIndex)">
                            <input class="form-check-input" type="checkbox" [value]="product.isSelected"
                                [checked]="product.isSelected"
                                [disabled]="!product.isSelected && isMaximumGroupQty(groupIndex)" />
                            <img class="product-icon w-100" [src]="product.coverImagePathThump"
                                onerror="this.src='assets/images/image_error.jpg'"
                                [class.faded]="!product.isSelected && isMaximumGroupQty(groupIndex)">
                            <span class="product-name"
                                [class.faded]="!product.isSelected && isMaximumGroupQty(groupIndex)">{{product.name}}</span>
                        </div>


                        <div *ngIf="product.isSelected" class="input-quantity">
                            <button class="btn-qty-hide" (click)="removeQuantity(groupIndex, product)">
                                <img src="assets/icons/MinusIcon.svg" />
                            </button>
                            <app-input #currentQuantity class="amount fs-sm fw-rg" data-cy="item-quantity" type="text"
                                [(ngModel)]="product.quantity" [labelMode]="LabelMode" [isDisabled]="true"/>
                            <button class="btn-qty-hide" (click)="addQuantity(groupIndex, product)"
                                [disabled]="isMaximumGroupQty(groupIndex)">
                                <img *ngIf="!isMaximumGroupQty(groupIndex)" [src]="'assets/icons/PlusIcon.svg'"
                                    alt="Plus Icon" />
                                <img *ngIf="isMaximumGroupQty(groupIndex)" [src]="'assets/icons/PlusIcon.svg'"
                                    [class.faded]="isMaximumGroupQty(groupIndex)" alt=" Fade Plus Icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div data-id="promotion-recommend-area" class="pt-3">
                <app-promotion-recommend  [id]="data.id" [type]="type"></app-promotion-recommend>
            </div>
        </div>
    </div>
    <div *ngIf="!checkProduct && !data && translationsLoaded" class="no-product">
        <div class="pt-3">
            <img src="assets/images/no_product.svg" alt="" />
            <h4>{{ 'TITLE.PRODUCT_EMPTY' | translate }}</h4>
            <p>{{ 'TITLE.PRODUCT_NOT_AREA' | translate }}</p>
        </div>
    </div>

    <div class="footer-area">
        <div class="input-quantity justify-content-between">
            <span>{{'PROMOTION_BUNDLE.BUNDLE_QTY' | translate}}</span>
            <div class="d-flex gap-2">
                <button class="btn-qty-hide" [disabled]="bundleQuantity <= 1" (click)="removeBundleQuantity()">
                    <img *ngIf="bundleQuantity > 1" src="assets/icons/MinusIcon.svg" />
                    <img *ngIf="bundleQuantity <= 1" class="faded" src="assets/icons/MinusIcon.svg" />
                </button>
                <app-input #currentBundleQuantity class="bundle-amount fs-sm fw-rg" data-cy="bundle-quantity" type="text"
                    [min]="1" [labelMode]="LabelMode" [(ngModel)]="bundleQuantity" customMask="0*" />
                <button class="btn-qty-hide" (click)="addBundleQuantity()">
                    <img [src]="'assets/icons/PlusIcon.svg'" />
                </button>
            </div>    
        </div>
        
        <button class="btn btn-add-product fs-lg" (click)="onSubmit()"  [disabled]="totalSelectedQuantities !== totalConditionAmount">
            {{ 'ACTION.CONFIRM' | translate }}
        </button>
        <app-modal-summary-item-bundle *ngIf="showModal" [applyResult]="applyResult" [stockist]="data?.stockist"
        [promotionId]="data?.id" [cartId]="cartId ?? ''" (clickClose)="clickCloseModal()"></app-modal-summary-item-bundle>
    </div>
</div>