<div class="vh-100 align-items-center justify-content-center w-100">
    <div class="anchor-gradient w-100">
        <div class="row align-items-center justify-content-center">
            <div class="d-flex justify-content-center mt-5 mb-5">
                <img class="img-fluid" src="assets/images/WABI2b_Horizontal.svg" alt="notFound" height="48px" />
            </div>

            <div class="card-body">
                <h3 class="header-login">ยินดีต้อนรับสู่ {{appName}}</h3>
                <p class="subheader-login mt-1">
                    เลือกประเภทร้านค้าของคุณเพื่อเข้าสู่ระบบ
                </p>

                <div class="col justify-content-center mt-4" (click)="onStockistsLogin()">
                    <button mat-raised-button class="custom-button mb-3">
                        <img class="img-icon" src="assets\images\Stockist_Box_Icon.png" alt="notFound" />
                        <div class="button-text-container">
                            <p class="button-text">ร้านค้าส่ง</p>
                            <p class="secondary-button-text">
                                จัดเตรียมและจัดส่งสินค้าในพื้นที่จัดส่งที่คุณกำหนด
                            </p>
                        </div>
                    </button>
                </div>

                <div class="col justify-content-center">
                    <button mat-raised-button class="custom-button" (click)="navigateToNewPage()">
                        <img class="img-icon" src="assets\images\Retailer_Cart_Icon.png" alt="notFound" />
                        <div class="button-text-container">
                            <p class="button-text">ร้านค้าปลีก</p>
                            <p class="secondary-button-text">
                                ค้นหาและทำการสั่งซื้อสินค้าที่คุณต้องการ
                            </p>
                        </div>
                    </button>
                </div>
            </div>
        </div>

    </div>
    <footer class="footer anchor-footer ">
        <div class="container d-flex justify-content-center gap-2">
            <p class="footer-text ">ยังไม่มีบัญชีกับ {{appName}} ใช่หรือไม่?</p>
            <a routerLink="auth/register" class="register-button">ลงทะเบียน</a>
        </div>
    </footer>
</div>