import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promotionRecommend, promotionRecommendParams } from '@core/models/retailer/promotion-recommend.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionRecommendService {

  constructor(private http: HttpClient) { }

  getPromotionRecommend(params: promotionRecommendParams): Observable<promotionRecommend[]> {
    return this.http.get<promotionRecommend[]>(
      `${environment.apiPromotionV2Url}/retailer/promotions/recommends/promotion`, {
        params: ClearParams(params),
      }
    );
  }
}
