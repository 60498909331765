import { Component, OnInit } from '@angular/core';
import { APP_NAME } from '@helpers/app.constants';
import { Title } from '@angular/platform-browser';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ProductService } from '@core/services/retailer/product.service';
import { ProductParams } from '@core/models/retailer/product.model';
import { NavigationStart, Router } from '@angular/router';
// import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private afMessaging: AngularFireMessaging, private titleService: Title, private productService: ProductService, private router: Router) { }

  ngOnInit(): void {
    if (!this.ignoreReceiveMessage()) {
      // this.requestPermission();
      this.receiveMessage();
      this.titleService.setTitle(APP_NAME);
    }
    const token = localStorage.getItem('token');
    this.router.events.subscribe(eventRouter => {
      if (token) {
        if (eventRouter instanceof NavigationStart) {
          const parms: ProductParams = {
            limit: 10
          }
          this.productService.getProductsAvailable(parms).subscribe()
          this.productService.getStockistsAvailable().subscribe()
        }

      }
    });

  }

  ignoreReceiveMessage(): boolean {
    return window.navigator.userAgent.includes('aura-native-ios');
  }

  // requestPermission() {
  //   this.afMessaging.requestToken.subscribe(
  //     (token) => {
  //       console.error('Permission granted! Save to the server!', token);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }

  // TODO: Should be refactor this code
  receiveMessage() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.afMessaging.messages.subscribe((payload: any) => {
      console.error('new message received. ', payload);
    });
  }
}
