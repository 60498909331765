import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductParams, ProductStockist } from '@core/models/retailer/product.model';
import { CmsService } from '@core/services/retailer/cms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { FilterEvent } from '../all-product/all-product.component';

@Component({
    selector: 'app-banner-promotion-product',
    templateUrl: './banner-promotion-product.component.html',
    styleUrls: ['./banner-promotion-product.component.scss'],
})
export class BannerPromotionProductComponent implements OnInit {
    bannerId = 0;
    productList: ProductStockist[] = [];
    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    params: ProductParams = {
        limit: 50,
        page: 1,
        datatable: true,
    };
    showNotFoundIcon = false;
    hasMoreData = true;
    total = 0
    totalOrderPage = 0
    isLoading = false
    filterTotal = 0;

    constructor(
        public cmsService: CmsService,
        private spinner: NgxSpinnerService,
        private route: Router,
        private router: ActivatedRoute,

    ) { }

    ngOnInit(): void {
        const bannerId = this.router.snapshot.params['bannerid']
        if (bannerId) {
            this.bannerId = Number(bannerId)
        }
        const filterHistory = localStorage.getItem('oldFilter')
        if (filterHistory) {
            const filterJson: ProductParams = JSON.parse(filterHistory)
            const filter: FilterEvent = {
                params: {
                    page: 1,
                    limit: 50,
                    datatable: true,
                },
                totalSelected: 0
            }
            if (filterJson.keywords) {
                this.params.keywords = filterJson.keywords
                filter.params.keywords = filterJson.keywords
            }
            this.filterProduct(filter)
        } else {
            this.handleSearch();
        }
    }

    filterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keywords = this.params.keywords;
        this.params = {
            keywords,
            ...filterEvent.params,
        };
        this.productList = [];
        this.hasMoreData = true;
        this.handleSearch();
    }

    search(query: string): void {
        this.params = {
            ...this.params,
            limit: 50,
            page: 1,
            datatable: true,
            keywords: query,
        };
        this.productList = [];
        this.handleSearch();
    }

    handleSearch() {
        this.isLoading = true
        this.spinner.show('spinner');
        this.cmsService.getBannerPromotionProduct(this.params, this.bannerId).subscribe({
            next: (res) => {
                this.total = res.total;
                if (Array.isArray(res.data)) {
                    this.totalOrderPage = res.totalPage
                    this.productList = this.productList.concat(res.data)
                    this.showNotFoundIcon = this.productList.length === 0;
                }
                this.spinner.hide('spinner');
                this.isLoading = false
            },
            error: (err) => {
                console.error(err)
                this.spinner.hide('spinner');
                this.isLoading = false
            }
        })
    }

    navigateBack(): void {
        this.route.navigate(['/products']);
    }

    showProductList(): boolean {
        return this.productList.length > 0;
    }

    showNotFoundMessage(): boolean {
        return this.showNotFoundIcon && !this.showProductList();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (window.innerHeight + 10 + window.scrollY >= document.body.offsetHeight && !this.isLoading && this.params?.page && this.params?.page < this.totalOrderPage) {
            this.params.page++;
            this.handleSearch();
        } else {
            this.hasMoreData = false
        }
    }
}
