<div>
  <div class="mt-3">
    <!-- stockist label -->
    <div class="col d-flex align-items-center checkbox border-bottom pb-2">
      <input [(ngModel)]="checkedStockist" (ngModelChange)="onSelectStockists()" id="store-{{stockistId}}-checkbox"
        (check)="checkedStockist" type="checkbox">
      <label for="store-{{stockistId}}-checkbox" class="me-2"></label>
      <img class="px-2" src="assets\icons\Store_Icon.svg" alt="notFound" />
      <label for="store-{{stockistId}}-checkbox" class="fs-6 fw-semibold mt-1">{{stockistName}}</label>
    </div>

    <!-- product list section -->
    <div class="col">
      <app-my-cart-product-v2 *ngFor="let product of productList" [product]="product"
        (productChanged)="onChangeProduct($event)" (productSelected)="onSelectProduct($event)"
        [isManageMode]="isManageMode"></app-my-cart-product-v2>
    </div>
  </div>
</div>
<div class="bottom-border remove-padding"></div>