<nav class="navbar bg-body-tertiary" [ngStyle]="{
        'background-color': 'white',
        'padding-left': '1rem',
        'padding-right': '1rem',
        position: 'fixed',
        top: '10',
        width: '100%',
        'margin-top': '-1rem'
    }">

  <div class="back-button justify-content-start pointer-cursor" routerLink="/retailer/my-cart-v2">
    <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
    <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
  </div>
</nav>

<div class="aura-container-custom pt-4 px-0 pb-4" style="background-color: #EEE;padding-top: 4px;">
  <div class="d-flex align-items-center fs-4" [ngStyle]="{ width: '100%','background-color':'white' }">
    <p class="fs-5 fw-semibold mb-0 me-auto title-session px-3">
      {{ "TITLE.CHECK_ORDER" | translate }}
    </p>
  </div>

  <div class="d-flex flex-column justify-content-center pt-3 gap-2"
    style="margin-bottom: 30rem;background-color: #FFFF;">
    <div class="d-flex gap-2 fs-6 text-wrap px-3">
      <img class="mr-3" src="assets/images/shipaddress.svg" width="20px" height="20px" alt="" />
      <div>
        <div class="fs-sm fw-rg">{{ "TITLE.DELIVERED_AT" | translate }}</div>
        <div>
          <span class="fs-md fw-md">
            --
            <br /></span>
          <span class="fs-sm fw-rg">
            -
          </span>
        </div>
      </div>
    </div>
    <div class="cart-loop" *ngFor="let cart of carts; let i = index" [attr.data-cy-row-number]="i+1">
      <div class="d-flex gap-2 fs-6 pt-2 text-wrap align-items-center top-border px-3">
        <img src="assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{cart.stockistName}}</p>
      </div>
      <div class="bottom-border"></div>
      <div class="item-cart" *ngFor="let itemInCart of cart.itemInCart; let i = index" [attr.data-cy-row-number]="i+1">

        <div class="product-pane px-3">
          <div class="d-flex align-items-baseline" style="margin-right: auto">
            <div class="">
              <img class="product-image" width="80px" src="{{ itemInCart.coverImagePathThumb }}"
                (error)="handleImageError($event)" alt="notFound" />
            </div>
            <div class="w-100">
              <div class="row">
                <div class="product-name px-3">
                  <p class="pt-3 card-text-name">{{itemInCart.name}}</p>
                </div>
              </div>
            </div>
            <div class="col-1 product-qty">x{{itemInCart.quantity}}</div>
          </div>
          <div>
            <div class="row d-flex">
              <app-my-cart-product-detail-v2 [productDetail]="itemInCart.items"></app-my-cart-product-detail-v2>
            </div>
            <div class="row"></div>

            <div class="d-flex gap-2 text-wrap align-items-start sum-row">
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <p class="mb-2"></p>
                  <span>{{itemInCart.amountPerUnit}}฿/ชุด</span>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="mb-2">รวม</p>
                  <span>{{itemInCart.totalAfterDiscount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex gap-2 text-wrap align-items-start sum-row px-3">
        <div class="w-100">
          <div class="d-flex justify-content-between">
            <p class="mb-2">ยอดรวมสินค้า</p>
            <span>mock฿</span>
          </div>
          <div class="d-flex justify-content-between">
            <p class="mb-2">ค่าจัดส่ง</p>
            <span>mock฿</span>
          </div>
          <div class="d-flex justify-content-between">
            <p>ยอดรวม</p>
            <span>mock฿</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="footer-page" class="footer-position">
  <div class="d-flex flex-column w-100 px-ß">
    <div class="d-flex justify-content-between fw-light coupon-session" (click)="openDiscountModal()">
      <div class="fw-bold coupon">
        <img src="assets/icons/tag.png" /> {{ 'MODAL_COUPON.TITLE' | translate}}
      </div>
      <div class="d-flex gap-3 align-items-center coupon-inside">
        <div class="coupon-code">{{couponName}}</div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
    <div class="bottom-border"></div>
    <div class="d-flex" [class.more-detail]="!expandDetails" [class.more-detail-active]="expandDetails"
      (click)="expandDetails = !expandDetails">
      <div class="d-flex justify-content-between align-items-center">
        <div class="more-detail-title">
          สิทธิประโยชน์เพิ่มเติมจากการซื้อโปรโมชัน
        </div>
        <i class="fa-regular fa-angle-down down" [style.transform]="expandDetails ? 'rotate(180deg)' : 'none'"></i>
      </div>
      <div *ngIf="expandDetails" class="additional">
          <div class="w-100">
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-title-aditional-privilege">ของแถม</div>
            </div>
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-detail-aditional-privilege">โค้ก น้ำอัดลม 1.25 ลิตร แพ็ค 12 ขวด</div>
              <div class="sub-detail-aditional-privilege">x2</div>
            </div>
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-detail-aditional-privilege">โค้ก น้ำอัดลม 1.25 ลิตร แพ็ค 12 ขวด</div>
              <div class="sub-detail-aditional-privilege">x2</div>
            </div>
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-title-aditional-privilege">ส่วนลด</div>
            </div>
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-detail-aditional-privilege">จากโปรโมชันโค้ก</div>
              <div class="sub-detail-aditional-privilege">-100.00฿</div>
            </div>
            <div class="d-flex justify-content-between pt-12px">
              <div class="sub-detail-aditional-privilege">จากโปรโมชันโค้ก</div>
              <div class="sub-detail-aditional-privilege">-50.00฿</div>
            </div>
          </div>
      </div>
    </div>
    <div class="d-flex justify-content-between left-right-footer sub-detail-payment">
      <div>
        {{ "PAYMENT.TOTAL_ALL" | translate }} ({{ "TITLE.UNIT" | translate }})
      </div>
      <!-- <div>{{ formatCurrency(sumTotalPrice()) }}</div> -->
      mock
    </div>
    <div class="d-flex justify-content-between left-right-footer sub-detail-payment">
      <div>
        {{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}
      </div>
      1,800.00฿
      <!-- <div>{{ formatCurrency(sumTotalDiscountOrder()) }}</div> -->
    </div>
    <div class="d-flex justify-content-between left-right-footer  sub-detail-payment">
      <div>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</div>
      <!-- <div>{{ formatCurrency(discountCouponAmount) }}</div> -->
      <!-- <div *ngIf="discountCouponType === 'Percent'">%{{ discountCouponAmount }}</div> -->

    </div>
    <div class="d-flex justify-content-between left-right-footer sub-detail-payment">
      <div>{{ "PAYMENT.SHIPPING_COST" | translate }} ({{ "TITLE.ALL_STORE" | translate }})</div>
      <!-- <div>{{ formatCurrency(sumTotalDeliveryFee()) }}</div> -->
      30.00฿
    </div>
    <div class="d-flex justify-content-between left-right-footer sub-detail-payment-total">
      <div>{{ "PAYMENT.NET_TOTAL" | translate }}</div>
      <!-- <div>{{ formatCurrency(sumTotalPrice()+sumTotalDeliveryFee()-discountCouponAmount-sumTotalDiscountOrder()) }}</div> -->
      1,830.00฿
    </div>
    <button type="button" class="btn confirm-order-btn" (click)="onCreateOrderHandler()" data-cy="confirm-order-btn">
      {{ "ACTION.CONFIRM_ORDER" | translate }}
    </button>
  </div>
  <app-modal-coupon *ngIf="isModalOpen" [dataOrder]="validateDetail" [totalPriceOrder]="0"
    [oldDiscountCode]="couponName" (discountApplied)="handleDiscount($event)"
    (discountCode)="handleDiscountCode($event)" (discountType)="handleDiscountType($event)"
    (modalClose)="closeDiscountModal()" />
</div>
<div class="overlay" *ngIf="isLoading" >
  <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;" /> 
 </div>
