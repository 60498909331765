import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ListProductStockistResponse } from "@core/models/retailer/product.model";
import { environment } from "@environments/environment";
import { ClearParams } from "@modules/shared/clear-params";
import { Observable } from "rxjs";

export interface IFavoriteParams {
    limit?: number
    page?: number
    keywords?: string
    datatable?: boolean
}

@Injectable({
    providedIn: 'root',
})

export class FavoriteService {
    cmsBaseURL = `${environment.apiCmsUrl}/`;
    productBaseURL = `${environment.apiProductUrl}/`;
    customerBaseURL = `${environment.apiUrl}/`;
    constructor(
        private http: HttpClient
    ) { }

    AddFavorite(stockistProductId: number) {
        return this.http.post(
            `${this.customerBaseURL}favorite?stockistProductId=${stockistProductId}`, {}
        );
    }

    RemoveFavorite(stockistProductId: number) {
        return this.http.delete(
            `${this.customerBaseURL}favorite?stockistProductId=${stockistProductId}`
        );
    }

    GetFavoriteProduct(params: IFavoriteParams): Observable<ListProductStockistResponse> {
        return this.http.get<ListProductStockistResponse>(`${this.productBaseURL}favorite`, {
            params: ClearParams(params),
        })
    }
}