import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PromotionV2Service } from '@core/services/retailer/promotion-v2.service';
import { ApplyPromotion, ApplyPromotionBundlePayload, ApplyPromotionBundlePayloadGroups, PromotionBundleDetail, PromotionBundleGroup, PromotionBundleGroupItem } from '@core/models/retailer/promotion-v2.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { FavoriteService } from '@core/services/retailer/favorite.service';
import { FormGroup } from '@angular/forms';
import { LabelMode } from '@modules/shared/_enums/label-mode.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { environment } from '@environments/environment';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';

@Component({
    selector: 'app-promotion-bundle-detail',
    templateUrl: './promotion-bundle-detail.component.html',
    styleUrls: ['./promotion-bundle-detail.component.scss'],
})
export class PromotionBundleDetailComponent implements OnInit {
    data?: PromotionBundleDetail;
    currentLang?: string;
    isLoadingFavorite = false;
    showModal = false;
    promotionId?: number
    cartId?: string;
    applyResult?: ApplyPromotion
    promotionBundleGroupPayload: ApplyPromotionBundlePayloadGroups[] = [];
    form!: FormGroup;
    LabelMode: LabelMode = LabelMode.NO_LABEL;
    selectedQuantities: number[] = [];
    bundleQuantity = 1;
    type = 'Bundle';
    isLoading = false
    checkProduct = false
    translationsLoaded = false;
    productImage = '';
    storeImage = '';

    constructor(
        private location: Location,
        private promotionService: PromotionV2Service,
        private translate: TranslateService,
        private activeRoute: ActivatedRoute,
        private favoriteService: FavoriteService,
        private cdr: ChangeDetectorRef,
        private spinner: NgxSpinnerService,
        private azureBlobService: AzureBlobService,
        private cartService: CartV2Service,
    ) { }
    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.translate.onLangChange.subscribe(() => {
            this.translationsLoaded = true;
        });
        const promotionId =
            this.activeRoute.snapshot.paramMap.get('promotion-id');

        const cartId = this.activeRoute.snapshot.paramMap.get('cart-id');

        if (promotionId) {
            this.promotionId = Number(promotionId)
            this.getPromotionBundleById(promotionId);
        }

        if (cartId) {
            this.cartId = cartId;
            this.getBundleCartByCartId(cartId);
        }
    }

    onSubmit(): void {
        this.promotionBundleGroupPayload = [];
        this.isLoading = true
        this.spinner.show('spinner');
        
        this.data?.promotionBundleGroups.forEach((group) => {
            const selectedItems= group.promotionBundleGroupItems
            .filter(item => item.isSelected && item.quantity != null )
            .map(item => ({
                id: item.id,
                quantity: item.quantity as number,
                stockistProductId: item.stockistProductId,
                productId: item.productId,
            }));
            if (selectedItems.length > 0) {
                this.promotionBundleGroupPayload.push({
                    id: group.id,
                    promotionBundleGroupItems: selectedItems
                });
            }
            
        })

        if (this.promotionId) {
            const finalPayload: ApplyPromotionBundlePayload = {
                bundleQty: this.bundleQuantity,
                promotionBundleGroups: this.promotionBundleGroupPayload
            }

            this.promotionService.applyPromotionBundleToCart(this.promotionId, finalPayload).subscribe({
                next: (res) => {
                    if (res) {
                        res.coverImagePath = this.data?.coverImagePath ?? '';
                        this.applyResult = res
                        this.showModal = true;
                        this.isLoading = false
                        this.spinner.hide('spinner');
                    }
                }, error: (err) => { console.error(err) }
            })   
        }
    }

    clickCloseModal(): void {
        this.showModal = false;
    }

    goBack(): void {
        this.location.back();
    }

    getPromotionBundleById(Id: string): void {
        this.isLoading = true
        this.spinner.show('spinner');
        this.promotionService.getPromotionBundleById(Id).subscribe({
            next: async (res) => {
                const transformResponse = await this.transformDataResponse(res);
                this.data = transformResponse
                this.checkProduct = !!res
                this.initProductSelection();
            },
            error: (err) => {
                console.error('Failed to load promotion data:', err);
                this.data = undefined; 
                this.checkProduct = false;
                this.spinner.hide('spinner');
                this.isLoading = false;
            }
        })
    }

    getBundleCartByCartId(cartId: string): void {
        this.isLoading = true
        this.spinner.show('spinner');
        this.cartService.getBundleCartByCartId(cartId).subscribe({
            next: async (res) => {
                const transformResponse = await this.transformDataResponse(res);
                this.data = transformResponse
                this.bundleQuantity = res.bundle ?? 1
                this.promotionId = transformResponse.id
                this.checkProduct = !!res
                this.initProductSelection();
            },
            error: (err) => {
                console.error('Failed to load promotion data:', err);
                this.data = undefined; 
                this.checkProduct = false;
                this.spinner.hide('spinner');
                this.isLoading = false;
            }
        })
    }

    initProductSelection() {
        if (this.data?.promotionBundleGroups) {
            this.sumSelectedQuantityByGroup();
            this.data.promotionBundleGroups.forEach((group) => {
                group.promotionBundleGroupItems.forEach((item) => {
                    item.isSelected = (item.quantity !== undefined && item.quantity != 0) // ถ้า get quantity มาเป็น undefind หรือ 0 จะไม่ให้มัน select
                })
            })
            this.spinner.hide('spinner');
            this.isLoading = false
        }
    }

    sumSelectedQuantityByGroup() {
        this.selectedQuantities = [];
        this.data?.promotionBundleGroups.map((group: PromotionBundleGroup) => {
            let totalSum = 0
            group.promotionBundleGroupItems.map((item: PromotionBundleGroupItem) => {
                totalSum += item.quantity ?? 0
            })
            this.selectedQuantities.push(totalSum);
        })
    }

    async transformDataResponse(res: PromotionBundleDetail) {
        if (!res.coverImagePath || res.coverImagePath.trim().length === 0) {
            res.coverImagePath = '/assets/images/image-placeholder.png';
        }
        // check if coverImagePathThump is empty, if not then call the azure blob service getLogoImageStockists
        if (!res.stockist?.coverImagePathThump || res.stockist.coverImagePathThump.trim().length === 0) {
            res.stockist = res.stockist || {}; // Ensure stockist object exists or else unittest will fail
            res.stockist.coverImagePathThump = '/assets/images/image_error.jpg'; 
        } else {
            await this.ImageurlToFile(
                res.stockist.coverImagePathThump,
                'Store'
            );
            const stkID = res.stockist.id;
            const image = res.stockist.coverImagePathThump.split("/").pop();
            this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
            res.stockist.coverImagePathThump = this.storeImage
        }
        return res
    }

    get totalConditionAmount(): number {
        if (!this.data || !this.data.promotionBundleGroups) {
            return 0;
        }
        return this.data.promotionBundleGroups.reduce(
            (sum, group) => sum + group.conditionAmount,
            0
        );
    }
    get totalSelectedQuantities(): number {
        return this.selectedQuantities.reduce((sum, quantity) => sum + quantity, 0);
    }
    
    isMaximumGroupQty(groupIndex: number): boolean {
        const group = this.data?.promotionBundleGroups[groupIndex];
        if (!group) return false;
    
        // Calculate the total quantity selected in this group
        const totalSelectedQuantity = group.promotionBundleGroupItems.reduce(
            (sum, item) => sum + (item.isSelected ? (item.quantity || 0) : 0),
            0
        );
    
        // Check if total selected quantity exceeds or equals the conditionAmount
        return totalSelectedQuantity >= group.conditionAmount;
    }
    
    formatDateThai(value: string) {
        const monthsThaiShort = [
            'ม.ค.',
            'ก.พ.',
            'มี.ค.',
            'เม.ย.',
            'พ.ค.',
            'มิ.ย.',
            'ก.ค.',
            'ส.ค.',
            'ก.ย.',
            'ต.ค.',
            'พ.ย.',
            'ธ.ค.',
        ];
        const monthEnNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const date = new Date(value);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear() + 543;
        const isThai = this.translate.currentLang === 'th';
        const monthName = isThai ? monthsThaiShort[month] : monthEnNames[month];
        return `${day} ${monthName} ${year}`;
    }

    onClickFavorite(stockistProductId: number, type: 'Add' | 'Remove') {
        if (this.isLoadingFavorite) return
        switch (type) {
            case 'Add':
                this.isLoadingFavorite = true
                this.favoriteService.AddFavorite(stockistProductId).subscribe({
                    next: () => {
                        if (this.data) {
                            this.data.favorited = true
                        }
                        this.isLoadingFavorite = false
                    },
                    error: (err) => {
                        console.error(err)
                        this.isLoadingFavorite = false
                    }
                })
                break;
            case 'Remove':
                this.isLoadingFavorite = true
                this.favoriteService.RemoveFavorite(stockistProductId).subscribe({
                    next: () => {
                        if (this.data) {
                            this.data.favorited = false
                        }
                        this.isLoadingFavorite = false
                    },
                    error: (err) => {
                        console.error(err)
                        this.isLoadingFavorite = false
                    }
                })
                break;
            default:
                break;
        }
    }
    
    onProductSelection(groupIndex: number, productIndex: number) {
        const product = this.data?.promotionBundleGroups[groupIndex].promotionBundleGroupItems[productIndex];

        if (product) {
            product.isSelected = !product.isSelected;

            // If the product is selected, init quantity to 1
            if (product.isSelected) {
                product.quantity = product.quantity || 1;
                this.selectedQuantities[groupIndex] += product.quantity;
            } else if (!product.isSelected) {
                // Reset quantity when deselected
                this.selectedQuantities[groupIndex] -= product.quantity || 0;
                product.quantity = null; 
            }

            const totalSelectedQuantity = this.getSelectedQuantity(groupIndex);
            const group = this.data?.promotionBundleGroups[groupIndex];
            if (group && totalSelectedQuantity > group.conditionAmount) {
                product.isSelected = !product.isSelected; // Revert toggle
            if (product.isSelected) {
                product.quantity = 1; // Reset to 1 if the product is re-selected
            } else {
                product.quantity = null; // Reset to null if the product is deselected
            }
            }

            this.cdr.detectChanges();
        }
    }
    removeQuantity(groupIndex: number, product: PromotionBundleGroupItem) {

        if (product.quantity && product.quantity > 1) {
            product.quantity -= 1;
            this.selectedQuantities[groupIndex] -= 1;
        } else {
            this.selectedQuantities[groupIndex] -= product.quantity || 0;
            product.quantity = null;
            product.isSelected = false;
        }
        this.cdr.detectChanges();
    }
    
    addQuantity(groupIndex: number, product: PromotionBundleGroupItem) {
        const group = this.data?.promotionBundleGroups[groupIndex];
        if (!group) return;

        const currentTotal = this.getSelectedQuantity(groupIndex);
        const maxQuantity = group.conditionAmount;

        if (currentTotal < maxQuantity) {
            product.quantity = (product.quantity ?? 0) + 1;
            this.selectedQuantities[groupIndex] += 1;
            this.cdr.detectChanges();
        }
    }
    removeBundleQuantity() {
        if (this.bundleQuantity > 1) {
            this.bundleQuantity -= 1;
        }
    }
    addBundleQuantity() {
        this.bundleQuantity += 1;
    }
    
    getSelectedQuantity(groupIndex: number): number {
        const group = this.data?.promotionBundleGroups[groupIndex];
        if (!group) return 0;

        return group.promotionBundleGroupItems.reduce((sum, item) => {
            return sum + (item.isSelected ? (item.quantity || 0) : 0);
        }, 0);
    }

    // <---------- Method for transform stk icon img ---------->
    async ImageurlToFile(filePath: string, type: 'Product' | 'Store') {
        if (!filePath) return;
        if (filePath.includes('http')) {
            if (type === 'Product') {
                this.productImage = filePath;
            } else {
                this.storeImage = filePath;
            }
            return;
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile, type);
            });
    }

    displayImage(file: File, type: 'Product' | 'Store') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                if (type === 'Product') {
                    this.productImage = valueToAdd.toString();
                } else {
                    this.storeImage = valueToAdd.toString();
                }
            }
        };
        reader.readAsDataURL(file);
    }
}
