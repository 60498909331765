import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    IValidateOrderPayload,
} from '@core/models/retailer/cart.model';
import { Product } from '@core/models/retailer/product.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartPayload, ICart, IProductCartSelect, IValidatePayload, SelectGiftPayload } from '@core/models/retailer/promotion-v2.model';
import { CartV2Service } from '@core/services/retailer/cart-v2.service';
import { IVerifyCartPayload } from '@core/models/retailer/verify-cart-payload.model';

export interface CheckStockist {
    stockistsId: number;
    checked: boolean;
}

export interface UpdateCartAmount {
    productId: number;
    newQuntity: number;
}

@Component({
    selector: 'app-my-cart',
    templateUrl: './my-cart.component.html',
    styleUrls: ['./my-cart.component.scss'],
})
export class MyCartV2Component implements OnInit, OnDestroy {
    constructor(
        private route: Router,
        private cartService: CartV2Service,
        private spinner: NgxSpinnerService

    ) { }
    isEmpty = true;
    cart: ICart[] = [];
    totalCart = 0;
    stockistProductList: IStockistProduct[] = [];
    selectedProduct: IValidateOrderPayload[] = [];
    reOrderProducts: IProductCartSelect[] = [];
    isManageMode = false;
    checkedAllStockist = false;
    isOnInit = false;
    showModal = false;
    isLoading = false;

    ngOnDestroy(): void {
        window.localStorage.removeItem('productData');
    }

    ngOnInit(): void {
        this.reOrderProducts = JSON.parse(
            localStorage.getItem('productData') ?? '[]'
        );
        this.isLoading = true
        this.spinner.show('spinner');
        this.getMyCart();
    }

    goToProductsPage() {
        this.route.navigate(['retailer/products']);
    }

    goToReviewOrderPage() {
        this.route.navigate(['retailer/review-order']);
    }

    updateCartAmout(amount: number): number {
        return (this.totalCart = amount);
    }

    getMyCart() {
        this.cartService.getMyCart().subscribe({
            next:(res) => {
                this.cart = res;

                if (res && res.length > 0) {
                    this.isEmpty = false;
                } else {
                    this.isEmpty = true;
                }
                this.isLoading = false
                this.spinner.hide('spinner');
                this.isOnInit = true;
            },error:() => {
                this.isLoading = false
                this.spinner.hide('spinner');
            }
        });
    }

    clickCloseModal(): void {
        this.showModal = false;
    }

    onSelectStockist(stockistsData: CheckStockist) {
        const index = this.cart.findIndex(
            (x) => x.stockistId === stockistsData.stockistsId
        );
        if (index !== -1) {
            if (!stockistsData.checked) {
                this.cart[index].itemInCart.forEach((item) => {
                    item.isSelected = true;
                });
            } else {
                this.cart[index].itemInCart.forEach((item) => {
                    item.isSelected = false;
                });
            }
        }
        this.checkedAllStockist = this.cart.every((stockist) =>
            stockist.itemInCart.every((product) => product.isSelected === true)
        );
    }

    onSelectProduct(productData: IProductCartSelect) {
        const indexStockists = this.cart.findIndex((i) =>
            i.itemInCart.find(
                (o) => o.cartId === productData.cartId
            )
        );

        const indexProduct = this.cart[indexStockists].itemInCart.findIndex(
            (i) => i.cartId === productData.cartId

        );
        const isSelected =
            this.cart[indexStockists].itemInCart[indexProduct].isSelected;
        this.cart[indexStockists].itemInCart[indexProduct].isSelected = !isSelected;
        if (!this.isManageMode) {
            this.updateCartAmout(this.calculateTotalPrice());
        }
    }

    onSelectAll() {
        this.cart.forEach((stockist) => {
            stockist.itemInCart.forEach((item) => {
                item.isSelected = this.checkedAllStockist;
            });
        });
    }

    onProductChanges(updateAmount: CartPayload) {
        const indexStockists = this.cart.findIndex((i) =>
            i.itemInCart.find(
                (o) => o.cartId === updateAmount.stockistProductId

            )
        );
        const indexProduct = this.cart[indexStockists].itemInCart.findIndex(
            (i) => i.cartId === updateAmount.stockistProductId
        );
        this.cart[indexStockists].itemInCart[indexProduct].quantity =
            updateAmount.quantity;
        this.updateCartAmout(this.calculateTotalPrice());
        // const payload = [updateAmount];
        // this.cartService.updateCartItem(payload).subscribe(() => {
        //     this.getMyCart();
        // });
    }

    calculateTotalPrice(): number {
        return this.cart.reduce((totalPrice, stockistProduct) => {
            return stockistProduct.itemInCart
                .filter((data) => data.isSelected)
                .reduce((subtotal, product) => {
                    return subtotal + product.amountPerUnit * product.quantity;
                }, totalPrice);
        }, 0);
    }

    onReviewOrder() {
        this.showLoader(true)
        const payload: IVerifyCartPayload[] = this.cart
            .filter(stockist => stockist.itemInCart.some(item => item.isSelected))
            .map(stockist => ({
                stockistId: stockist.stockistId,
                additionalPrivilege: [],
                cartId: stockist.itemInCart
                    .filter(item => item.isSelected)
                    .map(selectedItem => selectedItem.cartId)
            }));
        this.cartService.postverifyCart(payload).subscribe({
            next: () => this.navigateToSummary(),
            error: (error) => {
                this.showLoader(false)
                console.error('Error fetching additional privileges:', error);
            }
        })
    }

    navigateToSummary() {
        this.showLoader(false)
        this.route.navigate(['/retailer/summary-order']);
    }

    onError() {
        this.showLoader(false)
        console.error('Error fetching additional privileges:');
    }

    private getaditionalPrivilegeHandler() {
        const dataForCheck: IValidatePayload[] = this.cart
            .filter(stockist => stockist.itemInCart.some(item => item.isSelected))
            .map(stockist => ({
                stockistId: stockist.stockistId,
                cartId: stockist.itemInCart
                    .filter(item => item.isSelected)
                    .map(selectedItem => selectedItem.cartId)
            }));

        this.route.navigate(['/retailer/summary-order']);

        this.cartService.getAdditionalPrivilege(dataForCheck).subscribe({
            next: (res: SelectGiftPayload[]) => {
                const hasAdditionalPrivilege = res.some(item => item.additionalPrivileges?.some(privilege => privilege.additionalPrivilegeGroups.length !== 0
                )
                );

                if (hasAdditionalPrivilege) {
                    this.showModal = true;
                } else {
                    this.route.navigate(['/retailer/summary-order']);
                }
            },
            error: (error) => {
                console.error('Error fetching additional privileges:', error);
            }
        });
    }

    onDeleteOrder() {
        let dataForCheck: CartPayload[] = [];
        const stockistsData = this.cart.filter((i) =>
            i.itemInCart.some((b) => b.isSelected === true)
        );
        stockistsData.forEach((stockist) => {
            const itemFiltered = stockist.itemInCart
                .filter((product) => product.isSelected === true)
                .map((filtedProduct) => {
                    return {
                        stockistProductId: filtedProduct.cartId,
                        quantity: 0,
                    } as CartPayload;
                });
            dataForCheck = dataForCheck.concat(itemFiltered);
        });
        // this.cartService.updateCartItem(dataForCheck).subscribe(() => {
        //     this.getMyCart();
        // });
    }

    manageCart() {
        this.isManageMode = !this.isManageMode;
        this.cart.forEach((stockist) => {
            stockist.itemInCart.forEach((item) => {
                item.isSelected = false;
            });
        });
        this.checkedAllStockist = false;
    }
    isButtonEnabled(): boolean {
        return this.cart.some((stockist) =>
            stockist.itemInCart.some((item) => item.isSelected)
        );
    }

    showLoader(show: boolean) {
        this.isLoading = show
        if (show) {
            this.spinner.show('spinner');
            return
        }
        this.spinner.hide('spinner');
    }
}

export interface IStockistProduct {
    stockistId: number;
    stockistName: string;
    items: Product[];
}

export class StockistProduct implements IStockistProduct {
    stockistId: number;
    stockistName: string;
    items: Product[];

    constructor(data?: IStockistProduct) {
        this.stockistId = data?.stockistId || 0;
        this.stockistName = data?.stockistName || '';
        this.items = data?.items || [];
    }
}

export type StockistProducts = {
    stockistId: number;
    products: Product[];
};
