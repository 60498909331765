import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IOrderDetailV2 } from '@core/models/retailer/order-preview.model';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-history-detail-cancelrequested-v2',
  templateUrl: './order-history-detail-cancelrequested-v2.component.html',
  styleUrls: ['./order-history-detail-cancelrequested-v2.component.scss']
})
export class OrderHistoryDetailCancelrequestedV2Component {
  @Input() orderDetail: IOrderDetailV2 | undefined

  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  getAddress() {
    return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
  }

  navigateToOrderHistory() {
    const page = window.localStorage.getItem('page');

    if (page) {
      if (page === 'notification') {
        this.router.navigate(['retailer/my-notification']);
      } else if (page === 'history') {
        this.router.navigate(['retailer/order-history']);
      }
    } else {
      this.router.navigate(['retailer/order-history']);
    }

    window.localStorage.removeItem('page')
  }

  formatTime(officeTime: string): string {
    const isThai = this.translate.currentLang === 'th';
    if (officeTime) {
      const [startTime, endTime] = officeTime.split("-");
      const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
      const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
      return isThai ? `${formattedStartTime} - ${formattedEndTime} น.` : `${formattedStartTime} - ${formattedEndTime}`;
    }
    return '';

  }

  navigateToOldOrder() {
    this.router.navigateByUrl(`retailer/order-history/${this.orderDetail?.refOrderId}`).then(() => window.location.reload())
  }

  getTotalQuantity(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.quantity,
        0
      );

      return totalQuantity;
    }

    return 0;
  }
  getTotalAmount(): number {
    if (this.orderDetail && this.orderDetail.items) {
      const totalQuantity = this.orderDetail.items.reduce(
        (total, item) => total + item.amount,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  getNetAmount(): number {
    if (!this.orderDetail) return 0
    const netAmount = this.orderDetail.netAmount;
    return netAmount

  }

  getPaymentInfo(): PaymentInfo {
    return <PaymentInfo>{
      totalQuantity: this.getTotalQuantity(),
      totalAmount: this.getTotalAmount(),
      couponDiscount: this.orderDetail?.couponDiscount,
      promotionDiscount: this.orderDetail?.promotionDiscount,
      shippingCost: this.orderDetail?.shippingCost,
      netAmount: this.getNetAmount(),
    };
  }

}
