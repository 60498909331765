// English
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },

    LABEL_LIST: {
      NO_DATA: 'No data',
    },

    PAGINATOR: {
      FIRST_PAGE: 'First page',
      ITEM_PER_PAGE: 'Items per page:',
      LAST_PAGE: 'Last page',
      NEXT_PAGE: 'Next page',
      PREVIOUS_PAGE: 'Previous page',
      FROM: 'From',
      ITEM: 'Items',
    },

    PRODUCT: {
      PRODUCT: 'Product',
      BRAND_TITLE: 'Brand',
      MANUFACTURER_TITLE: 'Manufacturer',
      CATEGORY_TITLE: 'Category',
      CREATE_PRODUCT: 'Create Product',
      CREATE_BRAND: 'Create Brand',
      CREATE_MANUFACTURER: 'Create Manufacturer',
    },

    VALIDATOR: {
      REQUIRED: 'Please fill out the list',
      MIN: 'Please enter a larger value',
      MAX: 'Please enter a value less than this',
      EMAIL: 'Invalid email',
      MUST_MATCH: "Passwords don't match",
      PATTERN: 'Please specify in letters (A-Z, a-z, ก-ฮ) and thai vowels only.',
      MIN_LENGTH: 'Input must be at least',
      MAX_LENGTH: 'Input must be at least',
      LENGTH_VAR: 'characters long',
      DUPLICATE: 'Duplicate Data',
      DUPLICATE_PHONE: 'This mobile number is already in use',
      DUPLICATE_TIME: 'Closing time is the same as opening time',
      SIZE_FILE: 'File is too large.',
      TYPE_FILE:
        'File has invalid extension. Only jpg. and png. are allowed.',
      UPLOAD_FILE: 'File upload fail.',
      REQUIRED_IMAGE: 'Please input image',
      REQUIRED_CATEGORY: 'Please select category',
      LIMIT_MIN: 'Please input image at least ',
      LIMIT_MAX: 'Please input image not more than ',
      UNIT_IMAGE: ' image',
      DUPLICATE_EAN: 'This EAN is already in use.',
      DATE_MAX: 'Please select a larger date.',
      PROMOTION_PRODUCT: 'Please select product.',
      PROMOTION_GIFT: 'Please select gift.',
      PROMOTION_OVERLAPPING_RANGE: 'Ranges should not overlap',
      PROMOTION_INVALID_RANGE:
        'Minimum value should not be greater than the maximum value',
      PROMOTION_MIN_EQUAL_MAX:
        'Minimum value should not be equal to the maximum value',
      PROMOTION_INVALID_MAX:
        'Maximum value should be a valid number or "Unlimit"',
      PROMOTION_INVALID_PRODUCT:
        'Select products with the same price for tier promotion conditions with multiple tiers.',
    },
    SHOW_CASE: {
      LIST: 'รายการ',
      NO_PRODUCT: 'ไม่พบสินค้า',
      NO_PRODUCT_DESC: 'ลองค้นหาด้วยคำอื่นๆ',
    },

    LOGIN: {
      TITLE: 'Welcome to',
      TITLE_SUB: 'Login and order products now.',
      COUNTRY: 'Country',
      ENTER_PHONE: 'Enter your mobile phone number.',
      PHONE: 'mobile phone number.',
      ERROR_NETWORK: 'Please check your internet connection.',
      ERROR_PHONE: 'No user account found.',
      ERROR_PHONE_1: 'Mobile phone number is invalid.',
      ERROR_PHONE_2: 'Please enter your phone number.',
      FOOTER: "Don't have an account with",
      FOOTER_1: 'yet?',
      REGISTER: 'register',
      TITLE_PASSWORD: 'Enter password',
      TITLE_PASSWORD_2: 'Enter your password for login.',
      PASSWORD: 'Password',
      ERROR_PASSWORD_1: 'Please enter your password.',
      ERROR_PASSWORD_2: 'The password is incorrect. Please check your password again.',
      NEXT: 'Next',
      FORGET_PASSWORD: 'Forgot your password?',
      RESET_PASSWORD: 'Reset password',
      OTP_TITLE_1: 'Send OTP code to mobile number',
      OTP_TITLE_2: 'OTP will expire within 5 minutes.',
      ENTER: 'Enter OTP',
      ERROR_OTP: 'Not receive the code?',
      ERROR_OTP_1: 'Please enter OTP',
      ERROR_OTP_2: 'OTP is invalid',
      SEND_OTP: 'Send OTP again',
      ERROR_SET_PASSWORD_1: 'Please enter your password and confirm it.',
      ERROR_SET_PASSWORD_2: 'Please enter your password to confirm.',
      ERROR_SET_PASSWORD_3: 'Password and Confirm Password do not match.',
      ERROR_SET_PASSWORD_4: 'Please enter a password of 8 characters or more.',
      ERROR_SET_PASSWORD_5: 'Password must be in English and be at least 8 characters long, one uppercase letter, one lowercase letter. and one number',
      ERROR_SET_PASSWORD_6: 'Something went wrong Please try again.',
      CONFIRM_PASSWORD: 'Confirm password',
      SET_PASSWORD: 'Set password',
      SET_PASSWORD_LOGIN: 'Set a password for login.',
    },

    ACTION: {
      BACK_PAGE: 'Back to page',
      BACK_PAGE_MAIN: 'Back to main page',
      BACK_PAGE_HISTORY: 'go to history page',
      FAVORITE: 'Favorite Product',
      HISTORY: 'Order History',
      ALL: 'All',
      ADD_CART: 'Add to Cart',
      CONFIRM_CANCELLATION: 'Confirm order cancellation',
      CLEAR: 'Clear',
      CONFIRM: 'Confirm',
      CANCEL: 'Cancel',
      CONFIRM_ORDER: 'Confirm order',
      MANAGE: 'manage'
    },

    SIDE_BAR: {
      PROFILE: 'Profile',
      HISTORY: 'Order History',
      FAVORIST: 'Favorist',
      QUESTION: 'Question',
      LANGUAGE: 'Language',
      CONTACT: 'Contact us',
      CHAT: 'Chat with customer service',
      CALL: 'Call customer service',
      BUSINESS_HOURE: ' every day 08:00 - 20:00 hrs.',
      LOGOUT: 'Logout'
    },

    TITLE: {
      ORDER: 'Order',
      PRODUCT: 'Products',
      RECOMMEND_BRAND: 'Recommend Brands',
      STOCKIST: 'Wholesaler',
      EXAMINE_PRODUCT: 'Check product list',
      EMPTY: 'empty',
      UNIT: 'Unit',
      PRODUCT_EMPTY: 'Product Empty',
      PRODUCT_NOT_AREA: 'Or this product is not available for delivery in your area.',
      FILTER: 'Filter',
      TRY_SEARCH: 'Try searching with other words.',
      PURCHASE_CANCEL: 'Cancel order',
      FROM_STORE: 'from Wholesaler',
      CANCEL_REASON: 'Please enter the reason for canceling.',
      PLEASE_SPECIFY: 'Please specify',
      ADDITIONAL_REASON: 'additional reasons',
      SORT_BY: 'Sort by',
      NUMBER: 'Order Number',
      NO_ORDER: 'NO Orders',
      CANCEL: 'cancel',
      CANCEL_RTL: 'by retailer',
      CANCEL_STK: 'by Wholesaler',
      ORDER_AT: 'Order at',
      CANCEL_AT: 'Cancel at',
      DELIVER_FROM: 'Ship from',
      GIFT: 'Gift',
      ITEM: 'item',
      ORDER_CHANGE: 'Order changed',
      VIEW_ORDER: 'View original order',
      ORDER_AGAIN: 'Purchase Order Again',
      WANT_CANCEL: 'Cancel requested',//รอ
      WANT_CANCEL_AT: 'Cancel requested at', //
      CANCEL_ALERT: 'Canceling an order will require waiting for Wholesaler to respond first. If there is no response within 24 hours, the order will be automatically canceled.',
      EXPECTED_DELIVERY_DATE: 'Expected delivery date',
      CONFIRMED: 'Confirmed',
      CONFIRM_AT: 'Confirm at',
      CANCEL_NOT_APPROVE: 'The merchant did not approve the cancellation request. and will be delivered on the specified date',
      ORDER_LATE: 'The order has been delayed from the expected delivery date. If you want to track the parcel, please contact the staff.',
      CANCEL_ALERT2: 'Cancellation of an order must wait for approval by Wholesaler first because it is Verified status',
      WANT_CANCEL_PURCHASE: 'Request to cancel order',
      SUCCESSFUL_DELIVERY: 'Delivered',
      DELIVERY_AT: 'Delivery at',
      WAIT_COMFIRM: 'Wating Confirm',
      ORDER_CANCEL: 'order has been cancelled.',
      REQUEST_CANCEL_SEND: 'A cancellation request has been sent to the Wholesaler.',
      ORDER_DETAIL: 'View order details',
      ORDER_SENT_STORE: 'Order has been sent to Wholesaler.',
      ORDER_NUMBER: 'Order number',
      DETAILE: 'details',
      PROMOTION: 'promotion',
      GIFT_LIST: 'List gifts',
      QUANTITY: 'quantity',
      UNIT_AMOUNT: 'unit',
      AMOUNT: 'amount',
      GIFT_1: 'Can choose 1 free item.',
      EVERY_AMOUNT: 'every amount',
      PROMOTION_PERIOD: 'Promotion period',
      PARTICIPATING_PRODUCTS: 'Participating products',
      ORDER_CANCEL_REASON: 'Order cancellation reason',
      ORDER_CANCEL_ALERT: 'This order will be canceled immediately after filling in the order cancellation reason.',
      OTHER_REASON_ALERT: 'If Other is selected, you will need to provide additional reasons.',
      CHECK_ORDER: 'Check order',
      DELIVERED_AT: 'Delivered at',
      GIFT_2: 'Choose to receive 1 free gift.',
      ALL_STORE: 'Including all Wholesalers',
      MAP: 'Map',
      BEEN_CANCEL: 'has been canceled',
      MODAL_CANCEL: 'A cancellation request has been sent to the Wholesaler. | Please wait for a response.',
      ERROR_COUPON_1: 'Coupon not found',
      ERROR_COUPON_2: 'Sorry, you are not eligible to use this coupon',
      ERROR_COUPON_3: 'Coupon is expired',
      ERROR_COUPON_4: 'Coupon quota limit reached',
      ERROR_COUPON_5: 'Coupon quota per retailer limit reached',
      ERROR_COUPON_6: 'Your product items are not included in the coupon list',
      NOLIMIT: 'no limit',
      EMPTY_ORDER: 'No order',
      BRANDS_EMPTY: 'Brands Empty',
      EMPTY_FAVORITE: 'No favorite items yet',
      EMPTY_FAVORITE_DES: 'You can browse products and add them to your favorites by tapping the heart icon.',
      BATH: 'Bath',
      UNIT_GIFT: 'Unit',
      AMOUNT_TIER: 'Amount',
      DISCOUNT:'Discount per unit',
      PROMOTION_DETAIL: 'Promotion details',
      PRODUCT_CHOOSE: 'Select product',
      PROMOTION_TEXT_GIFT: 'Purchase all conditions and receive a free gift. 🎁',
      PROMOTION_TEXT_DISCOUNT: 'Save money'
    },

    CANCEL_TITLE: {
      TILTE1: 'If the Wholesaler does not respond within 7 days',
      TITLE2: 'system will automatically cancel purchase order.'
    },

    PLACEHOLDER: {
      ADDITIONAL: 'Enter reason',
      SEARCH: 'Search'
    },

    MENU: {
      CATEGORY: 'category',
      BRAND: 'brand',
      STORE: 'Wholesaler'
    },

    MODAL_COUPON: {
      TITLE: 'discount coupon',
      APPLY: 'Apply',
      ACTION: 'Order without using a discount coupon.',
      PLACEHOLDER: 'Enter the coupon code.'
    },

    CART: {
      TITLE: 'My Cart',
      EMPTY_CART1: 'no products in cart',
      EMPTY_CART2: 'You have not added any products to your cart.',
      TOTAL_NET: 'net total',
      CHECK_ORDER: 'Check order',
      SELECT_ALL: 'select all',
      DEL: 'Delete',
      GO_PRODUCT: 'go to product page',
      CLOSE: 'Confirm product removal from cart.',
      BIRNG: 'you want to bring',
      OUT: 'Out of cart?',
    },

    NOTIFICATION: {
      TITLE_EMPTY1: 'There are no new messages.',
      TITLE_EMPTY2: 'Update interesting news here soon!',
    },

    PAYMENT: {
      PRODUCT_TOTAL: 'Product total',
      TOTAL_ALL: 'Total all product',
      PROMOTION_DISCOUNT: 'Promotion discount',
      PROMOTION_COUPON: 'Coupon discount',
      SHIPPING_COST: 'Shipping cost',
      NET_TOTAL: 'Net total',
      UNIT_PRICE: 'Unit price',
      UNIT: 'Unit',
      SET: 'Set',
      FREE: 'Free'
    },

    PROFILE: {
      ADDRESS: 'Address',
      BILLING_ADDRESS: 'Billing Address',
      DATE_TIME: 'Date/Time open',
      BIND_AN_ACCOUNT: 'Binf an account',
      ACCOUNT_LINKED: 'Account linked',
      CANCEL_CONNECT: 'Cancel account connection',
      MODAL: 'Want to cancel account binding?',
      MODAL_1: `When confirming the cancellation of the account link\n You will not receive notifications of activities that occur on\n Wabi2b-TH, such as notifications of orders\nor anything similar on\nChat Line. \n\nConfirm account cancellation.?`,
    },

    FILTER: {
      LOW: 'price : low-high',
      HIGH: 'price : high-low'
    },

    HISTORY_STATUS: {
      PENDING: 'Pending',
      CONFIRM: 'Confirmed',
      REQUEST: 'Cancel requested',
      CANCEL: 'Cancel',
      DELIVER: 'Delivered',
      DEPRECATED: 'Deprecated'
    },

    ORDER_ERROR: {
      ERROR_PRODUCT: `Unable to order again\nBecause the product is not yet available.`
    },

    REGISTER: {
      REGISTER_INFORMATION: 'Registrant information',
      NAME: 'Store name',
      FIRSTNAME: 'First name',
      LASTNAME: 'Last name',
      COUNTRY: 'Country',
      PHONE: 'Mobile phone number',
      ERROR_PHONE: 'This mobile number is already in use.',
      STORE_PHONE: 'Store phone number',
      EMAIL: 'Email',
      SHIP_ADDRESS: 'Shipping address',
      POST_CODE: 'Post code',
      SUBDISTRICT: 'Subdistrict',
      DISTRICT: 'District',
      PROVINCE: 'Province',
      ADDRESS: 'Address',
      LOCALTION: 'Search location',
      CURRENT_LOCALTION: 'Current location',
      ACCEPT_NEWS: 'I would like to receive promotional information, news, and events from',
      ACCEPT_TERM: 'Terms and Condition',
      ACCEPT_PRIVACY: 'Privacy Policy',
      ACCEPT: 'I have read and consented to',
      REGISTER: 'Confirm registration',
      REGISTER_SUCCESS_TITLE: 'Registered successfully',
      REGISTER_SUCCESS_DESC: 'Please wait for our staff to contact you back',
    },

    REGISTER_HINT: {
      NAME: 'Enter store name',
      FIRSTNAME: 'Enter First name',
      LASTNAME: 'Enter Last name',
      PHONE: 'Enter mobile phone number',
      STORE_PHONE: 'Enter store phone number',
      EMAIL: 'Enter email',
      POST_CODE: 'Enter post code',
      SUBDISTRICT: 'Select Subdistrict',
      DISTRICT: 'Select district',
      PROVINCE: 'Select province',
      ADDRESS: 'Enter address',

    },

    REGISTER_SUCCRESS: {
      TITLE_1: 'your store',
      TITLE_2: 'preliminary registration has been completed.',
      TITLE_3: 'Please wait for contact.',
      TITLE_4: 'from',
      TITLE_5: 'within 24 hours',
    },

    PRIVACY_MODAL: {
      TITLE: 'PRIVACY POLICIES'
    },

    MODAL_TERMS: {
      TITLE: 'TERMS AND CONDITIONS',
    },

    PROMOTION_RECOMMEND: {
      TITLE: 'Recommended promotions',
      TITLE_SUB: 'from the same product',
      DISCOUNT: 'Max discount',
      GIFT: 'Max gift',
      UNIT: 'type'
    },

    PROMOTION_TIER_MESSAGE: {
      TITLE: 'An error occurred.',
      MESSAGE: 'An error occurred. Please try again.',
      CONFIRM: 'Close'
    },

    SUMMARY: {
      DISCOUNT_AMOUNT: "Savings",
      GIFT_TITLE: 'Earn a Free Gift with Purchase',
      SELECTED_ITEM: "Selected Items",
      SELECT_GIFT_TITLE: 'Choose Your Free Gift',
      GIFT: 'Gift',
      ADDITIONAL_CONDITIONAL_GIFT: 'The order meets the condition, receiving extra giveaways'
    },

    PROMOTION_BUNDLE: {
      SET: 'Bundle',
      BUNDLE_QTY: 'Bundle Quantity',
    }

  },

};
