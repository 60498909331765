<div>
    <div *ngIf="orderData">
        <app-order-history-detail-pending [orderDetail]="orderData" *ngIf="orderData.status === 'Pending'" />
        <app-order-history-detail-cancelled [orderDetail]="orderData" *ngIf="orderData.status === 'Cancelled'" />
        <app-order-history-detail-confirmed [orderDetail]="orderData" *ngIf="orderData.status === 'Confirmed'" />
        <app-order-history-detail-deprecated [orderDetail]="orderData" *ngIf="orderData.status === 'Deprecated'" />
        <app-order-history-detail-cancelrequested [orderDetail]="orderData"
            *ngIf="orderData.status === 'CancelRequested'" />
        <app-order-history-detail-delivered [orderDetail]="orderData" *ngIf="orderData.status === 'Delivered'" />
    </div>

    <div *ngIf="orderDataV2">
        <app-order-history-detail-pending-v2 [orderDetail]="orderDataV2" *ngIf="orderDataV2.status === 'Pending'" />
        <app-order-history-detail-cancelled-v2 [orderDetail]="orderDataV2" *ngIf="orderDataV2.status === 'Cancelled'" />
        <app-order-history-detail-confirmed-v2 [orderDetail]="orderDataV2" *ngIf="orderDataV2.status === 'Confirmed'" />
        <app-order-history-detail-deprecated-v2 [orderDetail]="orderDataV2"
            *ngIf="orderDataV2.status === 'Deprecated'" />
        <app-order-history-detail-cancelrequested-v2 [orderDetail]="orderDataV2"
            *ngIf="orderDataV2.status === 'CancelRequested'" />
        <app-order-history-detail-delivered-v2 [orderDetail]="orderDataV2" *ngIf="orderDataV2.status === 'Delivered'" />
    </div>

    <div class="overlay" *ngIf="isLoading">
        <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate"
            [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    </div>
</div>