/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductDetailDataInterface, ProductParams, ProductStockist } from '@core/models/retailer/product.model';
import { discountTier } from '@core/models/retailer/promotion.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteService } from '@core/services/retailer/favorite.service';
import { PromotionTierService } from '@core/services/retailer/promotion-tier.service';
import { promotionTier, promotionTierPayload, promotionTierProductPayload, promotionTierProducts } from '@core/models/retailer/promotion-tier.model';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTag } from '@core/models/shares/enum';
import { ApplyPromotion } from '@core/models/retailer/promotion-v2.model';
import { environment } from '@environments/environment';
// import { CartV2Service } from '@core/services/retailer/cart-v2.service';

@Component({
  selector: 'app-promotion-tier-detail',
  templateUrl: './promotion-tier-detail.component.html',
  styleUrls: ['./promotion-tier-detail.component.scss'],
})
export class PromotionTierDetailComponent implements OnInit {
  @ViewChild('currentQuantity') currentQuantityInput: ElementRef | undefined;
  @ViewChild('currentProductQuantity') currentProductQuantityInput: ElementRef | undefined;

  data?: ProductDetailDataInterface;
  promotionTierDetail?: promotionTier;
  quantity = 1;
  currentQuantityProductInCart = 0;
  productNumber_incart = '';
  productImage = ''
  storeImage = ''
  checkProduct = false;
  loading = true;
  productList: ProductStockist[] = [];
  isLoadingFavorite = false
  params: ProductParams = {
    limit: 200,
  };
  currentLang!: string
  id = 17;
  type = 'Tier'
  promotionTierProductPayload: promotionTierProductPayload[] = []
  applyPromotionTierData?: ApplyPromotion;
  showModal = false;
  cartId?: string;
  minLevel = 0
  isQuantityMinimum = true

  constructor(
    private modalService: NgbModal,
    private azureBlobService: AzureBlobService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private promotionTierService: PromotionTierService,
    private location: Location,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private favoriteService: FavoriteService,
    // private cartService: CartV2Service,

  ) { }
  private modalRef: NgbModalRef | undefined;

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.activeRoute.params.subscribe((params) => {
      const promotionId = params['promotion-id'];
      if (promotionId) {
        this.getPromotionTierById(promotionId);
      }
    });

    const cartId = this.activeRoute.snapshot.paramMap.get('cart-id');


    if (cartId) {
      this.cartId = cartId;
      // this.getTierCartByCartId(cartId);
    }

    // Scroll to the top of the page when navigation ends
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  clickCloseModal(): void {
    this.showModal = false;
  }

  productOnInput(event: Event, product: promotionTierProducts) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.replace(/[^0-9]/g, '');
    inputElement.value = inputValue;
    product.quantity = Number(inputValue);
    if (product.quantity < 1 || !product.quantity) {
      inputElement.value = ''
      product.quantity = null
      this.promotionTierProductPayload.forEach(element => {
        if (element.stockistProductId === product.stockistProductId) {
          element.quantity = undefined
        }
      });
    } else {
      this.promotionTierProductPayload.forEach(element => {
        if (element.stockistProductId === product.stockistProductId) {
          element.quantity = Number(product.quantity)
        }
      });
    }
  }

  @HostListener('input', ['$event'])
  onInput(item: promotionTierProducts) {
    if (this.currentQuantityInput) {
      const inputElement = this.currentQuantityInput.nativeElement;

      const inputValue = inputElement.value;
      if (/[^0-9]/.test(inputValue)) {
        inputElement.value = inputValue.replace(/[^0-9]/g, '');
      }
      const numericValue = Number(inputElement.value);
      item.quantity = isNaN(numericValue) ? 1 : numericValue;
    }
  }

  handleImgError(event: ErrorEvent): void {
    (event.target as HTMLImageElement).src =
      'assets/images/image-placeholder.png';
  }

  getPromotionTierById(Id: number): void {
    this.spinner.show('spinner');
    this.loading = true;
    this.promotionTierService.getPromotionsById(Id).subscribe({
      next: (response) => {

        if (response.coverImagePathThump === null) {
          response.coverImagePathThump = 'assets/images/image_error.jpg'
        }
        this.checkProduct = true
        if (response.price && response.discount) {
          response.net_price = response.price - response.discount
        }
        const stkID = response.stockist.id;
        const image = response.stockist.coverImagePathThump.split("/").pop();
        this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
        this.promotionTierDetail = response

        if (
          this.promotionTierDetail &&
          this.promotionTierDetail.promotionTierConditions
        ) {
          for (const item of this.promotionTierDetail.promotionTierConditions) {
            this.minLevel = item.min ? item.min : 0;
            break;
          }
        }

        if (
          this.promotionTierDetail &&
          this.promotionTierDetail.promotionTierProducts
        ) {
          this.promotionTierDetail.promotionTierProducts.forEach(
            (product: promotionTierProducts) => {
              if (product.quantity && product.quantity > 0) {
                product.isSelect = true;
                this.promotionTierProductPayload.push({
                  stockistProductId: product.stockistProductId,
                  productId: product.productId,
                  quantity: product.quantity,
                });
                this.checkPayloadQuantity()
              }
            }
          );
        }

        this.spinner.hide('spinner');
        this.loading = false;
      },
      error: () => {
        this.spinner.hide('spinner');
        this.loading = false;
      }
    })
  }

  async transformDataResponse(res: promotionTier) {
    if (!res.coverImagePathThump || res.coverImagePathThump.trim().length === 0) {
      res.coverImagePathThump = '/assets/images/image-placeholder.png';
    }
    // check if coverImagePathThump is empty, if not then call the azure blob service getLogoImageStockists
    if (!res.stockist?.coverImagePathThump || res.stockist.coverImagePathThump.trim().length === 0) {
      res.stockist = res.stockist || {}; // Ensure stockist object exists or else unittest will fail
      res.stockist.coverImagePathThump = '/assets/images/image_error.jpg';
    } else {
      await this.ImageurlToFile(
        res.stockist.coverImagePathThump,
        'Store'
      );
      const stkID = res.stockist.id;
      const image = res.stockist.coverImagePathThump.split("/").pop();
      this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
      res.stockist.coverImagePathThump = this.storeImage
    }
    return res
  }

  increaseQuantity(): void {
    this.quantity++;
  }

  increaseProductQuantity(product: promotionTierProducts): void {
    if (!product) return;

    if (!product.quantity) {
      product.quantity = 1
    }

    product.quantity = product.quantity + 1;

    this.promotionTierProductPayload.forEach(element => {

      if (element.stockistProductId === product.stockistProductId) {
        element.quantity = Number(product.quantity)
      }

    });
    this.checkPayloadQuantity()
  }

  decreaseQuantity(): void {
    if (this.quantity > 0) {
      this.quantity--;
    }
  }
  goBack(): void {
    this.location.back();
  }

  async applyPromotionTier() {
    this.spinner.show('spinner');
    if (this.promotionTierDetail) {
      if (this.promotionTierProductPayload.length > 0) {
        for (const element of this.promotionTierProductPayload) {
          if (!element.quantity) {
            this.spinner.hide('spinner');
            this.openModal()
            return
          }
        }

        const updateItems: promotionTierPayload = {
          id: Number(this.promotionTierDetail.id),
          promotionTierProducts: this.promotionTierProductPayload,
        };
        this.promotionTierService.applyPromotionTier(updateItems).subscribe({
          next: (res) => {
            this.applyPromotionTierData = res
            this.showModal = true;
            this.spinner.hide('spinner');
          },
          error: () => {
            this.spinner.hide('spinner');
            this.openModal()
          }
        });
      }
    } else {
      this.spinner.hide('spinner');
      this.openModal()
    }
  }

  openModal() {
    this.modalRef = this.modalService.open(ModalMessageComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Success,
      title: this.translate.instant('PROMOTION_TIER_MESSAGE.TITLE'),
      message: `${this.translate.instant('PROMOTION_TIER_MESSAGE.MESSAGE')}`,
      confirmLabel: this.translate.instant('PROMOTION_TIER_MESSAGE.CONFIRM'),
    };
  }

  formatDateThai(value: string) {
    const monthsThaiShort = [
      "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
    ];

    const monthEnNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear() + 543;
    const isThai = this.translate.currentLang === 'th';
    const monthName = isThai ? monthsThaiShort[month] : monthEnNames[month];
    return `${day} ${monthName} ${year}`;
  }

  async ImageurlToFile(filePath: string, type: 'Product' | 'Store') {
    if (!filePath) return;
    if (filePath.includes('http')) {
      if (type === 'Product') {
        this.productImage = filePath;
      } else {
        this.storeImage = filePath;
      }
    }
    this.azureBlobService
      .getLogoImageStockists(filePath)
      .subscribe((res: File) => {
        const customFile: File = res as File;
        this.displayImage(customFile, type);
      });
  }
  displayImage(file: File, type: 'Product' | 'Store') {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const arrayBuffer = reader.result as ArrayBuffer;
        const valueToAdd = arrayBuffer || '';
        if (type === 'Product') {
          this.productImage = valueToAdd.toString();
        } else {
          this.storeImage = valueToAdd.toString();
        }
      }
    };
    reader.readAsDataURL(file);
  }

  getDiscountPrice(discounts: discountTier[] | undefined) {
    if (discounts && discounts.length > 0) {
      const lastDiscount = discounts[discounts.length - 1];
      return lastDiscount.type === 'Percent' ? `${lastDiscount.discount}%` : `${lastDiscount.discount}฿`;
    }
    return null;
  }

  handleImageError(event: any) {
    event.target.src = 'assets/images/image_error.jpg';
  }

  handleClickProduct(stockistProductId: number | undefined) {
    if (stockistProductId) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.router.navigate([`retailer/product-detail/${stockistProductId}`]);
    }
  }

  checkProductImage() {

    if (this.promotionTierDetail?.coverImagePathThump) {
      return true;
    }

    return false;
  }

  onClickFavorite(stockistProductId: number, type: 'Add' | 'Remove') {
    if (this.isLoadingFavorite) return
    switch (type) {
      case 'Add':
        this.isLoadingFavorite = true
        this.favoriteService.AddFavorite(stockistProductId).subscribe({
          next: () => {
            if (this.data) {
              this.data.favorited = true
            }
            this.isLoadingFavorite = false
          },
          error: (err) => {
            console.error(err)
            this.isLoadingFavorite = false
          }
        })
        break;
      case 'Remove':
        this.isLoadingFavorite = true
        this.favoriteService.RemoveFavorite(stockistProductId).subscribe({
          next: () => {
            if (this.data) {
              this.data.favorited = false
            }
            this.isLoadingFavorite = false
          },
          error: (err) => {
            console.error(err)
            this.isLoadingFavorite = false
          }
        })
        break;
      default:
        break;
    }
  }

  onSelectProduct(product: promotionTierProducts) {

    if (product) {
      if (!product.isSelect) {
        product.isSelect = true 
        product.quantity = 1;

        this.promotionTierProductPayload.push({
          stockistProductId: product.stockistProductId,
          productId: product.productId,
          quantity: 1,
        });
      }
      else {
        product.isSelect = false
        product.quantity = 0;
        this.promotionTierProductPayload = this.promotionTierProductPayload.filter(element => element.stockistProductId != product.stockistProductId)
      }

      this.checkPayloadQuantity()
    }
  }

  reduceQuantity(product: promotionTierProducts): void {
    if (!product) return;

    if (product.quantity) {
      const reducibleSelectedProduct = product.quantity > 1;
      if (reducibleSelectedProduct) {
        product.quantity--;
        this.promotionTierProductPayload.forEach(element => {

          if (element.stockistProductId === product.stockistProductId) {
            element.quantity = Number(product.quantity)
          }
        });
        this.checkPayloadQuantity()
        return;
      }

      const removableWhenQuantityIsOne = product.quantity === 1;
      if (removableWhenQuantityIsOne) {
        product.isSelect = false
        this.promotionTierProductPayload = this.promotionTierProductPayload.filter(x => {
          return x.stockistProductId !== product.stockistProductId
        })
        this.checkPayloadQuantity()
        return;
      }
    }
  }

  formatDiscount(discount: number | undefined) {
    if (discount) {
      const discount_decimal = discount.toFixed(2)
      const decimal = discount_decimal.split('.')

      return decimal[1]
    } else {
      return null
    }

  }

  checkPayloadQuantity() {
    let quantityAll = 0
    if (this.promotionTierDetail?.amountPurchaseType === 'Unit') {
      for (const item of this.promotionTierProductPayload) {
        quantityAll = quantityAll + Number(item.quantity)
      }
      this.isQuantityMinimum = this.minLevel > quantityAll ? true : false

    } else if (this.promotionTierDetail?.amountPurchaseType === 'Currency') {

      for (const item of this.promotionTierProductPayload) {
        quantityAll = quantityAll + Number(item.quantity)

      }
      const price = this.promotionTierDetail?.price ?? 0;
      const totalAmount = price * quantityAll;
      this.isQuantityMinimum = this.minLevel > totalAmount ? true : false //true กดไม่ได้ ,false กดได้
    }

  }
}
