import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-product-summary',
  templateUrl: './image-product-summary.component.html',
  styleUrls: ['./image-product-summary.component.scss']
})
export class ImageProductSummaryComponent {
  @Input() imageSrc = ""
  @Input() amount = 0

  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
  }
}
