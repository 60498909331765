import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationDetail } from '@core/models/retailer/notification.model';
import { NotificationService } from '@core/services/retailer/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-my-notification-detail',
  templateUrl: './my-notification-detail.component.html',
  styleUrls: ['./my-notification-detail.component.scss'],
})
export class MyNotificationDetailComponent implements OnInit {
  currentLang!: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
  }

  notification: NotificationDetail = {
    createdAt: (new Date()).toString(),
    detail: '',
    detailEn: ''
  };

  loading_data = false

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang || 'en';
    this.notificationService.getMyNotificationCount().subscribe()
    this.getNotification();
  }

  getNotification() {
    this.spinner.show('spinner');
    const notificationDataType = this.route.snapshot.params['notificationDataType']; // id or code
    const notificationParam = this.route.snapshot.params['notificationParam'];
    if ((notificationDataType != "code" && notificationDataType != "id") ||  !notificationParam) {
      this.stopLoading();
      return
    }
    this.notificationService.getNotificationDetail(notificationDataType, notificationParam).subscribe({
      next: (res) => this.onGetNotificationDetailSuccess(res),
      error: () => this.stopLoading()
    })
  }

  onGetNotificationDetailSuccess(res: NotificationDetail) {
    this.notification = res
    this.stopLoading()
  }

  stopLoading() {
    this.loading_data = true
    this.spinner.hide('spinner');
  }

  goToProductsPage() {
    this.router.navigate(['retailer/my-notification']);
  }
}
