/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryList } from '@core/models/retailer/category.model';
import { IShowCaseAvailiable, IShowCaseAvailiableV2 } from '@core/models/retailer/cms.model';
import { IBannerList } from '@core/models/retailer/cms.model';
import {
    ProductParams,
    ProductStockist,
} from '@core/models/retailer/product.model';
import { Stockist } from '@core/models/retailer/stockist.model';
import { CartService } from '@core/services/retailer/cart.service';
import { CmsService } from '@core/services/retailer/cms.service';
import { NotificationService } from '@core/services/retailer/notification.service';
import { ProductService } from '@core/services/retailer/product.service';
import { ProfileService } from '@core/services/retailer/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    isLoadingProduct = false;
    isLoadingStockist = false;
    isLoadingBanner = false;

    cartAmount = 0;
    productList: ProductStockist[] = [];
    allCategory: CategoryList[] = [];
    showCaseList: IShowCaseAvailiable[] = [];
    showCaseListV2: IShowCaseAvailiableV2[] = [];
    params: ProductParams = {
        limit: 50,
        //offset: 0,
    };
    currentLang = 'th'


    categoryList = [
        {
            name: 'เครื่องดื่มแอลกอฮอล์และบุหรี่',
            iconPath: 'assets/icons/categoryIcon.svg',
        },
        { name: 'อาหาร', iconPath: 'assets/icons/categoryIcon.svg' },
        { name: 'เครื่องปรุงอาหาร', iconPath: 'assets/icons/categoryIcon.svg' },
        { name: 'น้ำเปล่า', iconPath: 'assets/icons/categoryIcon.svg' },
        { name: 'น้ำแร่', iconPath: 'assets/icons/categoryIcon.svg' },
        { name: 'น้ำอัดลม', iconPath: 'assets/icons/categoryIcon.svg' },
    ];
    bannerList: IBannerList[] = [];

    stockistList: Stockist[] = [];

    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    image_Logo_Url: string[] = [];

    constructor(
        private route: Router,
        private productService: ProductService,
        private cartService: CartService,
        private cmsService: CmsService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private profileService: ProfileService,


    ) {
        if (this.translate) {
            this.currentLang = this.translate.currentLang
        }
        this.notificationService.getMyNotificationCount().subscribe();
    }

    ngOnInit(): void {
        // console.log("Hello Hi")
        this.getProducts();
        this.getStockists();
        this.getShowCaseAvailable();
        this.getShowCaseAvailablev2();
        this.getListBanner();
        this.profileService.getProfile().subscribe({
            error: () => {
                this.profileService.clearSession();
                this.route.navigate(['/auth']);
            }
        })
        this.getCategories()
    }

    goToProductDetail(productCode: string) {
        this.route.navigate([`retailer/product-detail/${productCode}`]);
    }

    goToMyCart() {
        this.route.navigate(['retailer/my-cart-v2']);
    }

    getCartAmount() {
        this.cartService.getCartAmount().subscribe((res) => {
            if (res && res.data) {
                this.cartAmount = res.data.cartAmount;
            }
        });
    }

    getProducts() {
        this.isLoadingProduct = true;
        this.productService.getProductsAvailable(this.params).subscribe({
            next: (res) => {
                if (Array.isArray(res)) {
                    this.productList = res;
                }
                this.isLoadingProduct = false;
            },
            error: () => {
                this.isLoadingProduct = false;
            },
        });
    }
    getStockists() {
        this.isLoadingStockist = true;
        this.productService.getStockistsAvailable().subscribe({
            next: (res) => {
                if (Array.isArray(res)) {
                    this.stockistList = res.map(
                        (stockist) => new Stockist(stockist)
                    );
                }
                this.isLoadingStockist = false;
            },
            error: () => {
                this.isLoadingStockist = false;
            },
        });
    }

    getCategories() {
        this.productService.getCategoryList().subscribe({
            next: (res) => {
                if (res && Array.isArray(res.category)) {
                    this.allCategory = res.category;
                }
            },
        });
    }

    getShowCaseAvailable() {
        this.cmsService.getShowCaseAvailiable().subscribe(async (res) => {
            this.showCaseList = res;
            for (const showCase of this.showCaseList) {
                showCase.isLoading = true;
                this.cmsService
                    .getShowCaseProduct(showCase.id, this.params)
                    .subscribe({
                        next: (res) => {
                            showCase.showCaseProduct = res.data;
                            showCase.isLoading = false;
                        },
                        error: () => {
                            showCase.showCaseProduct = [];
                            showCase.isLoading = false;
                        },
                    });
            }
        });
    }

    getShowCaseAvailablev2() {
        this.cmsService.getShowCaseAvailiableV2().subscribe(async (res) => {
            this.showCaseListV2 = res;
            for (const showCase of this.showCaseListV2) {
                showCase.isLoading = true;
                this.cmsService
                    .getShowCaseProductV2(showCase.id, this.params)
                    .subscribe({
                        next: (res) => {
                            showCase.showCaseProduct = res.data;
                            showCase.isLoading = false;
                        },
                        error: () => {
                            showCase.showCaseProduct = [];
                            showCase.isLoading = false;
                        },
                    });
            }
        });
    }

    checkShowCaseLoading(): boolean {
        return this.showCaseList.some(e => e.isLoading == true);
    }

    showButtonMyCart() {
        return this.cartAmount > 0;
    }

    formatCurrency(amount: number) {
        return new Intl.NumberFormat('th-TH', {
            style: 'currency',
            currency: 'THB',
        }).format(amount);
    }

    navigateToSearch(): void {
        this.route.navigate(['retailer/search']);
    }

    navigateToShowCaseDetail(showCaseNumber: number): void {
        this.route.navigateByUrl(`/retailer/show-case/${showCaseNumber}`);
    }

    navigateToShowCaseDetailV2(showCaseNumber: number): void {
        this.route.navigateByUrl(`/retailer/show-case-promotion/${showCaseNumber}`);
    }

    navigateToStockist(stockistId: number) {
        this.route.navigate([`/retailer/all-product`, { 'stockist-id': stockistId }]);
    }

    navigateToCategory(categoryId: number) {
        this.route.navigate([`/retailer/all-product`, { 'category-id': categoryId }]);
    }

    getListBanner() {
        this.isLoadingBanner = true;
        this.cmsService.getShowcaseList().subscribe({
            next: async (res) => {
                if (Array.isArray(res)) {
                    this.bannerList = await res.map((data) => data);
                }
                this.isLoadingBanner = false;
            },
            error: () => {
                this.isLoadingBanner = false;
            },
        });
    }
    onClickBanner(item: IBannerList): void {
        if (item.type == 'Showcase') {
            this.route.navigate(['retailer/show-case/', item.showCaseId]);
        }
        if (item.type == 'Promotion') {
            this.route.navigate([`retailer/banner/${item.id}/products`]);
        }

        return;
    }
}
