<div *ngIf="productDetail">
  <div *ngFor="let product of productDetail">
    <div class="d-flex justify-content-between text-detail">
      <div>
        {{ currentLang === 'th' ? product.name : product.nameEn }}
      </div>
      <div>
        x{{ product.quantity }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="gifts">
  <div *ngFor="let gift of gifts">
    <div class="d-flex justify-content-between text-detail">
      <div class="text-detail-gift">
        {{ currentLang === 'th' ? gift.name : gift.nameEn }}
      </div>
      <div class="text-detail-gift">
        x{{ gift.quantity }}
      </div>
    </div>
  </div>
</div>