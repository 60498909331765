import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AddPromotionToCart, ApplyPromotion, PromotionGifts, PromotionItems, PromotionPrivilegeGiveAways, Stockist } from '@core/models/retailer/promotion-v2.model';
import { PromotionV2Service } from '@core/services/retailer/promotion-v2.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-summary-item-bundle',
  templateUrl: './modal-summary-item-bundle.component.html',
  styleUrls: ['./modal-summary-item-bundle.component.scss']
})
export class ModalSummaryItemBundleComponent implements OnInit {
  @Output() clickClose = new EventEmitter<void>();
  @Input() stockist?: Stockist
  @Input() promotionId?: number
  @Input() applyResult?: ApplyPromotion

  summaryOrder?: ApplyPromotion
  selectedAmount = 0
  selectedItemList: { [key: number]: PromotionPrivilegeGiveAways } = {}
  currentLang = 'th';

  constructor(
    private translate: TranslateService,
    private promotionService: PromotionV2Service,
    private route: Router
  ) {
    this.currentLang = this.translate.currentLang
  }

  ngOnInit(): void {

    this.loadData()
  }

  loadData() {
    this.summaryOrder = this.applyResult
  }

  closeModal() {
    this.clickClose.emit()
  }

  formatCurrency(amount?: number | null) {
    amount = amount ?? 0

    const currencyAmount = new Intl.NumberFormat('th-TH', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
    return currencyAmount
  }

  getSymbol() {
    const currencyFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: 'narrowSymbol',
    });
    return currencyFormatter.formatToParts(1).find(part => part.type === 'currency')?.value || '';
  }

  calculateDiscountPrice() {
    if (this.summaryOrder && this.summaryOrder.discount) {
      const calculatedPrice = this.summaryOrder.amount - this.summaryOrder.discount;
      return calculatedPrice > 0 ? calculatedPrice : 0
    }
    return 0
  }

  onSubmit() {
    const currentTime = new Date()
    if (!this.summaryOrder || !this.stockist) return
    const itemList: PromotionItems[] = []
    const giftList: PromotionGifts[] = []


    this.summaryOrder.stockistProduct.forEach((item) => { // เปลี่ยน format product ให้ตรงกับ payload
      const newItem: PromotionItems = {
        name: item.name,
        nameEn: item.nameEn,
        productId: item.productId ?? 0,
        quantity: item.amount,
        stockistProductId: item.stockistProductId ?? 0
      }
      itemList.push(newItem)
    })

    const giftKey = Object.keys(this.selectedItemList)
    giftKey.forEach((key) => {
      const itemData = this.selectedItemList[Number(key)]
      if (itemData) {
        giftList.push({
          quantity: itemData.quantity,
          name: itemData.name,
          nameEn: itemData.nameEn,
          giftId: itemData.giftId,
          productId: itemData.productId,
          stockistProductId: itemData.stockistProductId
        })
      }
    })

    const payload: AddPromotionToCart = {
      stockistId: this.stockist.id,
      stockistName: this.stockist.name,
      logoPath: this.stockist.coverImagePathThump,
      createdAt: currentTime,
      itemInCart: [{
        cartId: '',
        promotionId: this.promotionId ?? 0,
        type: 'Bundle',
        name: this.summaryOrder.name,
        coverImagePathThumb: this.summaryOrder.coverImagePath,
        quantity: this.summaryOrder.bundle,
        amountPerUnit: this.summaryOrder.amount / this.summaryOrder.bundle,
        discount: this.summaryOrder.discount ?? undefined,
        totalAmount: this.summaryOrder.amount,
        totalAfterDiscount: this.summaryOrder.amount - (this.summaryOrder.discount ?? 0),
        createdAt: currentTime,
        items: itemList,
        gifts: giftList
      }]
    }

    this.promotionService.addPromotionBundleToCart(payload).subscribe({
      next: () => {
        this.route.navigate(['/retailer/my-cart-v2'])
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  onSelectGift(giftId: number, giftItem: PromotionPrivilegeGiveAways) {
    if (this.summaryOrder) {
      this.selectedItemList[giftId] = giftItem
    }
  }

  checkIsSelectedGift(giftId: number) {
    return this.selectedItemList[giftId]
  }

  checkIsAllGiftSelected() {
    if (this.summaryOrder) {
      return this.countSelected() === this.summaryOrder?.promotionPrivilegeGiveAwayGroups.length
    }
    return false
  }

  countSelected() {
    return Object.keys(this.selectedItemList).length
  }

}
