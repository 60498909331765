<div class="modal-wrapper-promotion">
    <div class="modal-content-promotion">
        <!-- Close modal Button -->
        <div class="modal-header-promotion justify-content-between">
            <div class="d-flex">
                <img class="trash-icon" src="assets/images/trash.svg">
                <p class="trash-desc" (click)="openModal()">{{ 'MANAGE_PRODUCT.REMOVE_FROM_CART' |
                    translate}}</p>
            </div>
            <button type="button" class="close" (click)="closeModal()">
                <span>&times;</span>
            </button>
        </div>
        <!-- Edit and more order button -->
        <div class="modal-body">
            <button type="button" (click)="navigateToEditPromotionDetail()"
                class="btn btn-block btn-edit-product mb-2">{{ 'MANAGE_PRODUCT.EDIT' | translate}}
            </button>
            <button *ngIf="cartSelected?.type === 'Bundle'" type="button" (click)="navigateToBundlePromotionDetail()"
                class="btn btn-block btn-secondary-button">{{ 'MANAGE_PRODUCT.ORDER_MORE' | translate}}
            </button> 
        </div>
    </div>
</div>