import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { promotionRecommend, promotionRecommendParams } from '@core/models/retailer/promotion-recommend.model';
import { PromotionRecommendService } from '@core/services/retailer/promotion-recommend.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-promotion-recommend',
    templateUrl: './promotion-recommend.component.html',
    styleUrls: ['./promotion-recommend.component.scss'],
})
export class PromotionRecommendComponent implements OnInit {
    @Input() id: number | undefined = 0;
    @Input() type: string | undefined = '';
    @Input() PromotionRecommendData = [];

    imagePromotion = ''
    loading_data = false
    PromotionRecommendList: promotionRecommend[] = [];
    params: promotionRecommendParams = {
      id: 0,
      type: ''
    }

    constructor(
      private promotionRecommendService: PromotionRecommendService,
      private translate: TranslateService,
      private spinner: NgxSpinnerService,
      private router: Router,
    ) { }


    async ngOnInit(): Promise<void> {
      this.params = {
        id: this.id,
        type: this.type
      }
      await this.getPromotionRecommend()
    }

    async getPromotionRecommend() {
      this.spinner.show('spinner');
      this.loading_data = true
      if (this.params.id && this.params.type) {
        this.promotionRecommendService.getPromotionRecommend(this.params).subscribe({
          next: (res) => this.onGetPromotionRecommendSuccess(res),
          error: () => this.stopLoading()
        });
      }

    }

    async checkPromotion(item: promotionRecommend) {
      if (item.type === 'Bundle' && item.privilegeType === 'Discount') {
        item.imagePromotion = 'assets/icons/bundle_discount.png'
      } else if (item.type === 'Bundle' && item.privilegeType === 'Gift') {
        item.imagePromotion = 'assets/icons/bundle_gift.png'
      } else if (item.type === 'Tier' && item.privilegeType === 'Discount') {
        item.imagePromotion = 'assets/icons/tier_discount.png'
      } else if (item.type === 'Tier' && item.privilegeType === 'Gift') {
        item.imagePromotion = 'assets/icons/tier_gift.png'
      }
    }

    async onGetPromotionRecommendSuccess(res: promotionRecommend[]) {
      this.stopLoading()
      await res.forEach(item => {
        this.ImageurlToFile(item.coverImagePathThumb, item)
        this.checkPromotion(item)
      });
      this.PromotionRecommendList = res
    }

    async ImageurlToFile(filePath: string, item: promotionRecommend) {
      if (!filePath) return;
      if (filePath.includes('http')) {
          item.promotionCoverImage = filePath
          return;
      }
    }

    stopLoading() {
      this.spinner.hide('spinner');
      this.loading_data = false
    }

    onClickCard(item: promotionRecommend ) {

      if (item.type === 'Product') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/retailer/product-detail/${item?.id}`);
        });
      } else if (item.type === 'Bundle') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/retailer/promotion-bundle/${item?.id}`);
        });
      } else if (item.type === 'Tier') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/retailer/promotion-tier/${item?.id}`);
        });
      }
    }

}
