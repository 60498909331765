import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductParams, ProductStockist } from '@core/models/retailer/product.model';
import { FavoriteService } from '@core/services/retailer/favorite.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { FilterEvent } from '../all-product/all-product.component';

@Component({
  selector: 'app-all-favorite-product',
  templateUrl: './all-favorite-product.component.html',
  styleUrls: ['./all-favorite-product.component.scss']
})
export class AllFavoriteProductComponent implements OnInit {
  productList: ProductStockist[] = [];
  image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
  params: ProductParams = {
    limit: 50,
    page: 1,
    datatable: true,
    keywords: ''
  };
  showNotFoundIcon = false;
  isOpenFilterModal = false;
  hasMoreData = true;
  total = 0
  filterTotal = 0
  totalOrderPage = 0
  isLoading = false
  currentLang!: string

  constructor(
    private route: Router,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private favoriteService: FavoriteService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
    this.spinner.show('spinner');
    this.isLoading = true;
    const filterHistory = localStorage.getItem('oldFilter')
    if (filterHistory) {
      const filterJson: ProductParams = JSON.parse(filterHistory)
      const filter: FilterEvent = {
        params: {
          page: 1,
          limit: 50,
          datatable: true,
          orderBy: 'Price_asc'
        },
        totalSelected: 0
      }
      if (filterJson.keywords) {
        filter.params.keywords = filterJson.keywords
      }

      this.filterProduct(filter)
    }

    else {
      this.handleSearch();
    }

  }

  search(query: string): void {
    this.params = {
      ...this.params,
      limit: 50,
      page: 1,
      datatable: true,
      keywords: query,
    };
    this.productList = [];
    this.spinner.show('spinner');
    this.isLoading = true;
    this.handleSearch();
  }

  handleSearch() {
    this.spinner.show('spinner');
    this.isLoading = true;
    this.favoriteService
      .GetFavoriteProduct(this.params)
      .subscribe({
        next: (res) => {
          this.total = res.total;
          if (Array.isArray(res.data)) {
            this.totalOrderPage = res.totalPage
            if (res.data.length === 0) {
              this.hasMoreData = false;
            } else {
              const newProducts = res.data
              const existingIds = this.productList.map(
                (item) => item.id
              );
              const uniqueNewProducts = newProducts.filter(
                (item) => !existingIds.includes(item.id)
              );

              this.productList.push(...uniqueNewProducts);
              this.isLoading = false;
              this.spinner.hide('spinner');
            }
            this.showNotFoundIcon = this.productList.length === 0;
          }

          if (!Array.isArray(res.data) || res.data.length === 0) {
            this.isLoading = false;
            this.spinner.hide('spinner');
          }
        },
        error: (err) => {
          console.error(err)
          this.isLoading = false;
          this.spinner.hide('spinner');
          this.showNotFoundIcon = true
        }
      })
  }

  navigateBack(): void {
    this.route.navigate(['/products']);
  }

  showProductList(): boolean {
    return this.productList.length > 0;
  }

  showNotFoundMessage(): boolean {
    return this.showNotFoundIcon && !this.showProductList();
  }

  filterProduct(filterEvent: FilterEvent) {
    this.filterTotal = filterEvent.totalSelected;
    const keywords = this.params.keywords;
    this.params = {
      keywords: keywords,
      ...filterEvent.params,
    };
    this.productList = [];
    this.hasMoreData = true;
    this.handleSearch();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.innerHeight + 64 + window.scrollY >= document.body.offsetHeight && !this.isLoading && this.params?.page && this.params?.page < this.totalOrderPage) {
      this.params.page++;
      this.handleSearch();
    }
  }
}
