import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promotionTier, promotionTierPayload } from '@core/models/retailer/promotion-tier.model';
import { ApplyPromotion } from '@core/models/retailer/promotion-v2.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PromotionTierService {
  basePath = `${environment.apiPromotionV2Url}`;
  constructor(private http: HttpClient) {}

  getPromotionsById(Id: number): Observable<promotionTier> {
    return this.http.get<promotionTier>(
      `${this.basePath}/retailer/promotions/tier/${Id}`
    );
  }

  applyPromotionTier(
    payload: promotionTierPayload
  ): Observable<ApplyPromotion> {
      return this.http
          .post<ApplyPromotion>(`${this.basePath}/retailer/promotions/tier/${payload.id}/apply`, payload)
  }

}
