<div *ngIf="!(!isLoading && recommendBrand && recommendBrand.length === 0)">
    <div class="title-card d-flex justify-content-between">
        <div>{{ "TITLE.RECOMMEND_BRAND" | translate }}</div>
        <button class="titleButton" routerLink="/retailer/all-recommend-brands">
            {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
        </button>
    </div>
    <div class="aura-sub-container-custom content-area">
        <div class="shelve d-flex">
            <ng-container *ngIf="!isLoading && recommendBrand && recommendBrand.length > 0">
                <div class="card-box-position" *ngFor="let brand of recommendBrand; let i = index"
                    [attr.data-cy-row-number]="i + 1">
                    <app-card-brand [isLandigPage]="true" [item]="brand" (clickBrand)="onClickBrand($event)" />
                </div>
            </ng-container>


            <div class="loading-section" *ngIf="isLoading">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>
</div>