<div *ngFor="let product of products" class="product-item-share ">
    <div>
        <div *ngIf="product.quantity !== 0" class="layout-product">

            <div class="layout-image d-flex align-items-center gap-3">
                <img alt="Product image" class="product_image " width="60px" height="60px"
                    [src]="product.coverImagePathThumb" onerror="this.src='assets/images/image_error.jpg'" />
                <p class="layout-name">
                    {{ currentLang === 'th' ? product.name : product.nameEn }}
                </p>
            </div>

            <p *ngIf="!(product.type === 'Tier')" class=" layout-quantity product-name fs-sm fw-semibold mb-0">
                x{{ product.quantity }}
            </p>

        </div>
    </div>

    <div *ngIf="product.type === 'Bundle' || product.type === 'Tier'">
        <ng-contenainer *ngFor="let item of product.items">
            <div class="group-bundle">
                <p class="product-item fs-sm mb-0">
                    {{ currentLang === 'th' ? item.name : item.nameEn ? item.nameEn : item.name }}
                </p>
                <span class="price fs-sm">
                    x{{ item.quantity }}
                </span>
            </div>
        </ng-contenainer>
        <ng-contenainer *ngFor="let gift of product.gifts">
            <div class="group-bundle-gift">
                <p class="product-item fs-sm mb-0">
                    {{ 'PAYMENT.FREE' | translate }}! {{ gift.name }}
                </p>
                <span class="price fs-sm">
                    x{{ gift.quantity }}
                </span>
            </div>
        </ng-contenainer>
    </div>

    <div>
        <div class="group-price">
            <p class="product-name fs-sm fw-semibold mb-0 pt-3">
                {{ 'TITLE.SUM' | translate }}
            </p>
            <span class="price fs-6 fw-semibold">
                <div class="product-price" align="right">
                    <p class="mb-0 fs-sm text-color-grey" [attr.data-cy]="product.cartId + '-label-unit-price'">
                        {{ getFormattedCurrency(product.amountPerUnit) }}/{{ (product.type !== 'Bundle' ? 'PAYMENT.UNIT'
                        :
                        'PAYMENT.SET') | translate }}
                    </p>
                    <span class="discount-price" *ngIf="product.discount"> {{ getFormattedCurrency(product.totalAmount)
                        }}</span>
                    <span>{{ product.discount? (getFormattedCurrency(product.totalAfterDiscount))
                        :getFormattedCurrency(product.totalAmount) }} </span>
                </div>
            </span>
        </div>
    </div>

</div>