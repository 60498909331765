<div class="background-order-confirm margin-btm" *ngIf="data">
    <div class="d-flex justify-content-between fs-5 background-order-head">
        <div>
            <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
                {{ "TITLE.REQUEST_CANCEL_SEND" | translate }}
            </p>
            <p class="fw-ligh text-head-mid">{{ "TITLE.NUMBER" | translate }} {{data.id}}</p>
            <p class="fw-light text-head-last">
                {{ "TITLE.WANT_CANCEL_AT" | translate }} {{data.cancelRequestedDate| displayDate}}
            </p>
        </div>
        <div>
            <img class="" src="assets/images/Order Cancel.svg" width="52px" height="52px" alt="Not found" />
        </div>
    </div>
    <div class="fs-sm d-flex align-items-center gap-2"
        style="background-color: #EFEFEF;padding: 10px;border-left: 4px solid #505050; color: #505050;">
        <img src="assets/icons/receipt-cancle.svg" width="16px" height="16px" />
        <p class="remove-padding ">
            {{ "TITLE.CANCEL_ALERT" | translate }}
        </p>

    </div>
    <div class="d-flex gap-2 fs-6 py-2 text-wrap align-item-center px-3">
        <img src="\assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{data.retailerName}}</p>
    </div>
    <div class="bottom-border"></div>

    <div class="mt-3">
        <div class="px-3">
            <div class="fs-6 fw-semibold product-list-label pb-3">
                <p>{{ "ORDER_INFO.PENDING.PRODUCT_LIST" | translate }}</p>
                <app-show-product-promotion-order-list [products]="data.items"></app-show-product-promotion-order-list>
            </div>
        </div>
        <div class="px-3">
            <app-additional-privilege [additionalDiscounts]="data.additionalDiscounts" [additionalGiveAways]="data.additionalGiveAways" *ngIf="data.additionalDiscounts.length > 0 || data.additionalGiveAways.length >0"></app-additional-privilege>
            <div>
                <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
            </div>
        </div>
    </div>

</div>

<div id="footer-page" class="footer-position">
    <div class="d-flex flex-column w-100 px-3 pt-3 background-order-confirm">
        <div class="d-flex flex-column gap-3 mb-3">
            <button type="button" class="btn detail-order-btn" (click)="navigateToOrderHistoryDetail()">
                {{ "TITLE.ORDER_DETAIL" | translate }}
            </button>
            <button type="button" class="btn btn-secondary-button" (click)="navigateToLanding()">
                {{ "ACTION.BACK_PAGE_MAIN" | translate }}
            </button>
        </div>

    </div>
</div>