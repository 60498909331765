import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    getAppVersion() {
        const version = localStorage.getItem('app-version');
        return version ? version : 'v1';
    }

    setAppVersion(version: string) {
        localStorage.setItem('app-version', version);
    }

    getApiUrl(path = '') {
        if (path.startsWith('customer')
            || path.startsWith('cms')
            || path.startsWith('promotion')) {

            if (this.getAppVersion() === 'v2')
                return `${environment.baseApiUrl}/v2/${path}`;
        }
        return `${environment.baseApiUrl}/${path}`;
    }
}