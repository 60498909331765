// ภาษไทย
export const locale = {
  lang: 'th',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },

    LABEL_LIST: {
      NO_DATA: 'ไม่มีข้อมูล',
    },

    PAGINATOR: {
      FIRST_PAGE: 'หน้าแรก',
      ITEM_PER_PAGE: 'รายการต่อหน้า:',
      LAST_PAGE: 'หน้าสุดท้าย',
      NEXT_PAGE: 'หน้าถัดไป',
      PREVIOUS_PAGE: 'หน้าก่อนหน้า',
      FROM: 'จาก',
      ITEM: 'รายการ',
    },

    PRODUCT: {
      PRODUCT: 'สินค้า',
      BRAND_TITLE: 'แบรนด์',
      MANUFACTURER_TITLE: 'ผู้ผลิต',
      CATEGORY_TITLE: 'หมวดหมู่',
      CREATE_PRODUCT: 'เพิ่มสินค้า',
      CREATE_BRAND: 'เพิ่มแบรนด์',
      CREATE_MANUFACTURER: 'เพิ่มผู้ผลิต',
    },

    VALIDATOR: {
      REQUIRED: 'กรุณากรอกข้อมูล',
      MIN: 'กรุณากรอกค่าที่มากกว่า',
      MAX: 'กรุณากรอกค่าที่น้อยกว่านี้',
      EMAIL: 'กรุณาตรวจสอบอีเมล',
      MUST_MATCH: 'รหัสผ่านไม่ตรงกัน',
      PATTERN: 'กรุณาระบุเป็นตัวอักษร (A-Z, a-z, ก-ฮ) และพยัญชนะไทย เท่านั้น',
      MIN_LENGTH: 'จำนวนขั้นต่ำ',
      MAX_LENGTH: 'จำนวนไม่เกิน',
      LENGTH_VAR: 'ตัว',
      DUPLICATE: 'ข้อมูลซ้ำ',
      DUPLICATE_PHONE: 'หมายเลขโทรศัพท์มือถือนี้ถูกใช้ไปแล้ว',
      DUPLICATE_TIME: 'เวลาปิดซ้ำกับเวลาเปิด',
      SIZE_FILE: 'ไฟล์มีขนาดใหญ่เกินไป',
      TYPE_FILE: 'ประเภทไฟล์ไม่ถูกต้อง',
      UPLOAD_FILE: 'ไม่สามารถอัพโหลดรูปได้',
      REQUIRED_IMAGE: 'กรุณาใส่รูปภาพ',
      REQUIRED_CATEGORY: 'กรุณาเลือกหมวดหมู่สินค้า',
      LIMIT_MIN: 'กรุณาใส่รูปอย่างน้อย ',
      LIMIT_MAX: 'กรุณาใส่รูปไม่เกิน ',
      UNIT_IMAGE: ' รูป',
      DUPLICATE_EAN: 'EAN นี้ถูกใช้ไปแล้ว',
      DATE_MAX: 'กรุณาเลือกวันที่ที่มากกว่า',
      PROMOTION_PRODUCT: 'กรุณาเลือกสินค้า',
      PROMOTION_GIFT: 'กรุณาเลือกของแถม',
      PROMOTION_OVERLAPPING_RANGE:
        'ไม่สามารถกรอกจำนวนที่มีอยู่ในเงื่อนไขอื่นได้',
      PROMOTION_INVALID_RANGE:
        'ไม่สามารถกรอกจำนวนขั้นต่ำสูงกว่าจำนวนสูงสุดได้',
      PROMOTION_MIN_EQUAL_MAX:
        'ไม่สามารถกรอกจำนวนขั้นต่ำเท่ากับจำนวนสูงสุดได้',
      PROMOTION_INVALID_MAX: 'จำนวนสูงสุดต้องเป็นตัวเลขหรือ "ไม่จำกัด"',
      PROMOTION_INVALID_PRODUCT:
        'เลือกสินค้าที่มีราคาเดียวกัน สำหรับเงื่อนไขโปรโมชันขั้นบันไดที่มีมากกว่า 1 ขั้น',
    },
    SHOW_CASE: {
      LIST: 'รายการ',
      NO_PRODUCT: 'ไม่พบสินค้า',
      NO_PRODUCT_DESC: 'ลองค้นหาด้วยคำอื่นๆ',
    },

    LOGIN: {
      TITLE: 'ยินดีต้อนรับสู่',
      TITLE_SUB: 'เข้าสู่ระบบและสั่งซื้อสินค้าได้เลย',
      COUNTRY: 'ประเทศ',
      ENTER_PHONE: 'กรอกหมายเลขโทรศัพท์มือถือ',
      PHONE: 'หมายเลขโทรศัพท์มือถือ',
      ERROR_NETWORK: 'กรุณาเชื่อมต่ออินเตอร์เน็ต',
      ERROR_PHONE: 'ไม่พบบัญชีผู้ใช้งาน',
      ERROR_PHONE_1: 'หมายเลขโทรศัพท์มือถือไม่ถูกต้อง',
      ERROR_PHONE_2: 'กรุณากรอกหมายเลขโทรศัพท์',
      FOOTER: 'ยังไม่มีบัญชีกับ',
      FOOTER_1: 'ใช่หรือไม่?',
      REGISTER: 'ลงทะเบียน',
      TITLE_PASSWORD: 'ใส่รหัสผ่าน',
      TITLE_PASSWORD_2: 'ใส่รหัสผ่านสำหรับการเข้าใช้งาน',
      PASSWORD: 'รหัสผ่าน',
      ERROR_PASSWORD_1: 'กรุณากรอกรหัสผ่าน',
      ERROR_PASSWORD_2: 'รหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบรหัสผ่านอีกครั้ง',
      NEXT: 'ถัดไป',
      FORGET_PASSWORD: 'ลืมรหัสผ่านใช่ไหม?',
      RESET_PASSWORD: 'รีเซ็ตรหัสผ่าน',
      OTP_TITLE_1: 'ส่งรหัส OTP ไปยังหมายเลขโทรศัพท์มือถือ',
      OTP_TITLE_2: 'รหัส OTP ของคุณจะหมดอายุภายใน 5 นาที',
      ENTER: 'ใส่รหัส OTP',
      ERROR_OTP: 'ไม่ได้รับรหัส OTP?',
      ERROR_OTP_1: 'กรุณากรอก OTP',
      ERROR_OTP_2: 'รหัส OTP ไม่ถูกต้อง',
      SEND_OTP: 'ส่งรหัส OTP อีกครั้ง',
      ERROR_SET_PASSWORD_1: 'กรุณากรอกรหัสผ่านและยืนยันรหัสผ่าน',
      ERROR_SET_PASSWORD_2: 'กรุณากรอกยืนยันรหัสผ่าน',
      ERROR_SET_PASSWORD_3: 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
      ERROR_SET_PASSWORD_4: 'กรุณากรอกรหัสผ่าน 8 ตัวขึ้นไป',
      ERROR_SET_PASSWORD_5: 'รหัสผ่านต้องเป็นภาษาอังกฤษและมีความยาวอย่างน้อย 8 ตัวอักษร ตัวพิมพ์ใหญ่หนึ่งตัว, ตัวพิมพ์เล็กหนึ่งตัว และตัวเลขหนึ่งตัว',
      ERROR_SET_PASSWORD_6: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
      CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
      SET_PASSWORD: 'ตั้งรหัสผ่าน',
      SET_PASSWORD_LOGIN: 'ตั้งรหัสผ่านสำหรับการเข้าใช้งาน',

    },
    ACTION: {
      BACK_PAGE: 'กลับไปหน้าก่อนหน้า',
      BACK_PAGE_MAIN: 'กลับหน้าหลัก',
      BACK_PAGE_HISTORY: 'ไปที่หน้าประวัติคำสั่งซื้อ',
      FAVORITE: 'รายการที่ชื่นชอบ',
      HISTORY: 'ประวัติคำสั่งซื้อ',
      ALL: 'ดูทั้งหมด',
      ADD_CART: 'เพิ่มลงรถเข็น',
      CONFIRM_CANCELLATION: 'ยืนยันยกเลิกคำสั่งซื้อ',
      CLEAR: 'ล้าง',
      CONFIRM: 'ยืนยัน',
      CANCEL: 'ยกเลิก',
      CONFIRM_ORDER: 'ยืนยันคำสั่งซื้อ',
      MANAGE: 'จัดการ'
    },

    SIDE_BAR: {
      PROFILE: 'ข้อมูลส่วนตัว',
      HISTORY: 'ประวัติคำสั่งซื้อ',
      FAVORIST: 'รายการที่ชื่นชอบ',
      QUESTION: 'คำถามที่พบบ่อย',
      LANGUAGE: 'เปลี่ยนภาษา',
      CONTACT: 'ติดต่อเรา',
      CHAT: 'แชทกับศูนย์บริการลูกค้า',
      CALL: 'โทรติดต่อศูนย์บริการลูกค้า',
      BUSINESS_HOURE: ' ทุกวัน เวลาทำการ 08:00 น. - 20:00 น.',
      LOGOUT: 'ออกจากระบบ'
    },

    TITLE: {
      ORDER: 'คำสั่งซื้อ',
      PRODUCT: 'รายการสินค้า',
      RECOMMEND_BRAND: 'แบรนด์แนะนำ',
      STOCKIST: 'ร้านค้าแนะนำ',
      EXAMINE_PRODUCT: 'ตรวจสอบรายการสินค้า',
      EMPTY: 'หมด',
      UNIT: 'รายการ',
      PRODUCT_EMPTY: 'ไม่พบสินค้า',
      PRODUCT_NOT_AREA: 'หรือสินค้านี้ไม่พร้อมส่งในพื้นที่ของคุณ',
      FILTER: 'ตัวกรอง',
      TRY_SEARCH: 'ลองค้นหาด้วยคำอื่นๆ',
      PURCHASE_CANCEL: 'ยกเลิกคำสั่งซื้อ',
      FROM_STORE: 'จากร้านค้า',
      CANCEL_REASON: 'กรุณาใส่เหตุผลที่ต้องการยกเลิก',
      PLEASE_SPECIFY: 'โปรดระบุ',
      ADDITIONAL_REASON: 'เหตุผลเพิ่มเติม',
      SORT_BY: 'เรียงตาม',
      NUMBER: 'หมายเลขคำสั่งซื้อ',
      NO_ORDER: 'ยังไม่มีคำสั่งซื้อ',
      CANCEL: 'ยกเลิก',
      CANCEL_RTL: 'โดยร้านค้าปลีก',
      CANCEL_STK: 'โดยร้านค้าส่ง',
      ORDER_AT: 'สั่งซื้อเมื่อ',
      CANCEL_AT: 'ยกเลิกเมื่อ',
      DELIVER_FROM: 'จัดส่งจาก',
      GIFT: 'ของแถม',
      ITEM: 'ชิ้น',
      ORDER_CHANGE: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
      VIEW_ORDER: 'ดูคำสั่งซื้อเดิม',
      ORDER_AGAIN: 'สั่งซื้ออีกครั้ง',
      WANT_CANCEL: 'ขอยกเลิก',
      WANT_CANCEL_AT: 'ขอยกเลิกเมื่อ',
      CANCEL_ALERT: 'การยกเลิกคำสั่งซื้อจะต้องรอร้านค้าตอบกลับก่อน หากไม่มีการตอบกลับภายใน 24 ชม. คำสั่งซื้อจะถูกยกเลิกอัตโนมัติ',
      EXPECTED_DELIVERY_DATE: 'วันที่คาดว่าจะจัดส่ง',
      CONFIRMED: 'ยืนยันแล้ว',
      CONFIRM_AT: 'ยืนยันเมื่อ',
      CANCEL_NOT_APPROVE: 'ร้านค้าไม่อนุมัติคำขอยกเลิก และจะทำการจัดส่งตามวันที่กำหนดไว้',
      ORDER_LATE: 'คำสั่งซื้อมีการล่าช้าจากวันที่คาดว่าจะจัดส่ง ต้องการติดตาม พัสดุ โปรดติดต่อเจ้าหน้าที่',
      CANCEL_ALERT2: 'การยกเลิกคำสั่งซื้อจะต้องรอร้านค้าอนุมัติก่อนเนื่องจากเป็น สถานะยืนยันแล้ว',
      WANT_CANCEL_PURCHASE: 'ขอยกเลิกคำสั่งซื้อ',
      SUCCESSFUL_DELIVERY: 'จัดส่งสำเร็จ',
      DELIVERY_AT: 'จัดส่งเมื่อ',
      WAIT_COMFIRM: 'รอยืนยัน',
      ORDER_CANCEL: 'คำสั่งซื้อถูกยกเลิกแล้ว',
      REQUEST_CANCEL_SEND: 'คำขอยกเลิกถูกส่งไปร้านค้าแล้ว',
      ORDER_DETAIL: 'ดูรายละเอียดคำสั่งซื้อ',
      ORDER_SENT_STORE: 'คำสั่งซื้อส่งไปยังร้านค้าแล้ว',
      ORDER_NUMBER: 'คำสั่งซื้อที่',
      DETAILE: 'รายละเอียด',
      PROMOTION: 'โปรโมชัน',
      GIFT_LIST: 'รายการของแถม',
      QUANTITY: 'จำนวน',
      UNIT_AMOUNT: 'หน่วย',
      AMOUNT: 'ปริมาณ',
      GIFT_1: 'เลือกของแถมได้ 1 รายการ',
      EVERY_AMOUNT: 'ทุกจำนวน',
      PROMOTION_PERIOD: 'ช่วงเวลาโปรโมชัน',
      PARTICIPATING_PRODUCTS: 'สินค้าที่เข้าร่วม',
      ORDER_CANCEL_REASON: 'เหตุผลยกเลิกคำสั่งซื้อ',
      ORDER_CANCEL_ALERT: 'คำสั่งซื้อนี้จะถูกยกเลิกทันทีหลังจากกรอกเหตุผลยกเลิกคำสั่งซื้อ',
      OTHER_REASON_ALERT: 'หากเลือก อื่นๆ คุณจำเป็นต้องระบุเหตุผลเพิ่มเติมด้วย',
      CHECK_ORDER: 'ตรวจสอบคำสั่งซื้อ',
      DELIVERED_AT: 'จัดส่งที่',
      GIFT_2: 'เลือกรับของแถม 1 อย่าง',
      ALL_STORE: 'รวมทุกร้านค้า',
      MAP: 'ดูแผนที่',
      BEEN_CANCEL: 'ถูกยกเลิกแล้ว',
      MODAL_CANCEL: 'คำขอยกเลิก ถูกส่งไปยังร้านค้าแล้ว | กรุณารอการตอบกลับ',
      ERROR_COUPON_1: 'ไม่พบรหัสคูปอง กรุณาตรวจสอบความถูกต้องแล้วลองใหม่อีกครั้ง',
      ERROR_COUPON_2: 'ขออภัย รหัสคูปองไม่ถูกต้อง หรือคุณยังไม่ได้รับสิทธิ์เพื่อใช้งานรหัสคูปองนี้',
      ERROR_COUPON_3: 'รหัสคูปองนี้หมดอายุแล้ว',
      ERROR_COUPON_4: 'ใช้สิทธิ์รหัสคูปองนี้ครบแล้ว หรือถูกใช้งานหมดแล้ว',
      ERROR_COUPON_5: 'ใช้สิทธิ์รหัสคูปองนี้ครบแล้ว หรือถูกใช้งานหมดแล้ว',
      ERROR_COUPON_6: 'รายการสินค้าของคุณไม่เข้าร่วมรายการกับคูปองนี้',
      NOLIMIT: 'ไม่จำกัด',
      EMPTY_ORDER: 'ยังไม่มีคำสั่งซื้อ',
      BRANDS_EMPTY: 'ไม่พบแบรนด์',
      EMPTY_FAVORITE: 'ยังไม่มีรายการที่ชื่นชอบ',
      EMPTY_FAVORITE_DES: 'คุณสามารถเลือกชมสินค้าและเพิ่มลงในรายการที่ชื่นชอบด้วยการแตะรูปหัวใจ',
      BATH: 'บาท',
      UNIT_GIFT: 'อย่าง',
      AMOUNT_TIER: 'จำนวน',
      DISCOUNT: 'ส่วนลด',
      PROMOTION_DETAIL: 'รายละเอียดโปรโมชัน',
      PRODUCT_CHOOSE: 'เลือกสินค้า',
      PROMOTION_TEXT_GIFT: 'ซื้อครบเงื่อนไขได้รับของแถม 🎁',
      PROMOTION_TEXT_DISCOUNT: 'ประหยัดลง',
      SUM: 'รวม',
      PER_UNIT: 'ต่อหน่วย'
    },


    CANCEL_TITLE: {
      TILTE1: 'หากร้านค้าไม่มีการตอบกลับภายใน 7 วัน',
      TITLE2: 'ระบบจะยกเลิกคำสั่งซื้ออัตโนมัติ'
    },

    PLACEHOLDER: {
      ADDITIONAL: 'ระบุเหตุผล',
      SEARCH: 'ค้นหา'
    },

    MENU: {
      CATEGORY: 'หมวดหมู่',
      BRAND: 'แบรนด์',
      STORE: 'ร้านค้า'
    },

    MODAL_COUPON: {
      TITLE: 'คูปองส่วนลด',
      ERROR: 'เกิิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      APPLY: 'นำไปใช้',
      ACTION: 'สั่งซื้อแบบไม่ใช้คูปองส่วนลด',
      PLACEHOLDER: 'กรอกรหัสคูปองเพื่อใช้ส่วนลด'
    },

    CART: {
      TITLE: 'รถเข็นของฉัน',
      EMPTY_CART1: 'ไม่มีสินค้าในรถเข็น',
      EMPTY_CART2: 'คุณยังไม่ได้เพิ่มสินค้าในรถเข็น',
      TOTAL_NET: 'ยอดรวมสุทธิ',
      CHECK_ORDER: 'ตรวจสอบคำสั่งซื้อ',
      SELECT_ALL: 'เลือกทั้งหมด',
      DEL: 'ลบ',
      GO_PRODUCT: 'ไปหน้ารายการสินค้า',
      CLOSE: 'ยืนยันการนำสินค้าออกจากรถเข็น',
      BIRNG: 'คุณต้องการนำ',
      OUT: 'ออกจากรถเข็นใช่หรือไม่?',
    },

    NOTIFICATION: {
      TITLE_EMPTY1: 'ยังไม่มีข้อความใหม่',
      TITLE_EMPTY2: 'อัพเดตข่าวสารที่น่าสนใจที่นี่เร็ว ๆ นี้!',
    },

    PAYMENT: {
      PRODUCT_TOTAL: 'ยอดรวมสินค้า',
      TOTAL: 'ยอดรวม',
      TOTAL_ALL: 'ยอดรวมสินค้าทั้งหมด',
      PROMOTION_DISCOUNT: 'ส่วนลดโปรโมชัน',
      PROMOTION_COUPON: 'ส่วนลดคูปอง',
      SHIPPING_COST: 'ค่าจัดส่ง',
      NET_TOTAL: 'ยอดรวมสุทธิ',
      UNIT_PRICE: 'ราคาต่อหน่วย',
      UNIT: 'หน่วย',
      SET: 'ชุด',
      FREE: 'แถม'

    },

    MANAGE_PRODUCT: {
      REMOVE_FROM_CART: 'นำออกจากรถเข็น',
      EDIT: 'แก้ไข',
      ORDER_MORE: 'สั่งเพิ่ม'
    },

    PROFILE: {
      ADDRESS: 'ที่อยู่',
      BILLING_ADDRESS: 'ที่อยู่สำหรับการเรียกเก็บเงิน',
      DATE_TIME: 'วัน/เวลา เปิดบริการ',
      BIND_AN_ACCOUNT: 'ผูกบัญชี',
      ACCOUNT_LINKED: 'ผูกบัญชีแล้ว',
      CANCEL_CONNECT: 'ยกเลิกการผูกบัญชี',
      MODAL: 'ต้องการยกเลิกการผูกบัญชี?',
      MODAL_1: `เมื่อยืนยันการยกเลิกผูกบัญชี\n คุณจะไม่ได้รับการแจ้งเตือนกิจกรรมที่เกิดขึ้นบน\n Wabi2b-TH เช่น การแจ้งเตือนคำสั่งซื้อ\nหรืออื่น ๆ ที่มีลักณะคล้ายกันบน\nช่องทางแชทไลน์ \n\nยืนยันการยกเลิกการผูกบัญชี?`,
    },

    FILTER: {
      LOW: 'ราคา : ต่ำ-สูง',
      HIGH: 'ราคา : สูง-ต่ำ'
    },

    HISTORY_STATUS: {
      PENDING: 'รอยืนยัน',
      CONFIRM: 'ยืนยันแล้ว',
      REQUEST: 'ขอยกเลิก',
      CANCEL: 'ยกเลิก',
      DELIVER: 'จัดส่งสำเร็จ',
      DEPRECATED: 'ยกเลิก'
    },

    ORDER_ERROR: {
      ERROR_PRODUCT: `ไม่สามารถสั่งซื้ออีกครั้งได้\nเนื่องจากสินค้าหรือโปรโมชันไม่พร้อมให้บริการ`
    },

    REGISTER: {
      REGISTER_INFORMATION: 'ข้อมูลผู้ลงทะเบียน',
      NAME: 'ชื่อร้านค้า',
      FIRSTNAME: 'ชื่อ',
      LASTNAME: 'นามสกุล',
      COUNTRY: 'ประเทศ',
      PHONE: 'หมายเลขโทรศัพท์มือถือ',
      ERROR_PHONE: 'หมายเลขโทรศัพท์มือถือนี้ถูกใช้ไปแล้ว',
      STORE_PHONE: 'หมายเลขโทรศัพท์ร้านค้า',
      EMAIL: 'อีเมล',
      SHIP_ADDRESS: 'ที่อยู่สำหรับการจัดส่ง',
      POST_CODE: 'รหัสไปรษณีย์',
      SUBDISTRICT: 'ตำบล',
      DISTRICT: 'อำเภอ',
      PROVINCE: 'จังหวัด',
      ADDRESS: 'ที่อยู่',
      LOCALTION: 'ค้นหาตำแหน่ง',
      CURRENT_LOCALTION: 'ตำแหน่งปัจจุบัน',
      ACCEPT_NEWS: 'ฉันมีความประสงค์ที่จะได้รับข้อมูลโปรโมชัน ข่าวสาร และกิจกรรมต่างๆ จาก',
      ACCEPT_TERM: 'ข้อตกลงและเงื่อนไขการใช้บริการ',
      ACCEPT_PRIVACY: 'นโยบายความเป็นส่วนตัว',
      ACCEPT: 'ฉันได้อ่านและฉันยอมรับ',
      REGISTER: 'ยืนยันการลงทะเบียน',
      REGISTER_SUCCESS_TITLE: 'ร้านค้าของคุณได้\nลงทะเบียนเบื้องต้นเรียบร้อยแล้ว',
      REGISTER_SUCCESS_DESC: 'กรุณารอการติดต่อกลับจากเจ้าหน้าที่'

    },

    REGISTER_HINT: {
      NAME: 'กรอกชื่อร้านค้า',
      FIRSTNAME: 'กรอกชื่อ',
      LASTNAME: 'กรอกนามสกุล',
      PHONE: 'กรอกหมายเลขโทรศัพท์มือถือ',
      STORE_PHONE: 'กรอกหมายเลขโทรศัพท์ร้านค้า',
      EMAIL: 'กรอกอีเมล',
      POST_CODE: 'กรอกรหัสไปรษณีย์',
      SUBDISTRICT: 'เลือกตำบล',
      DISTRICT: 'เลือกอำเภอ',
      PROVINCE: 'เลือกจังหวัด',
      ADDRESS: 'กรอกที่อยู่',

    },

    REGISTER_SUCCRESS: {
      TITLE_1: 'ร้านค้าของคุณได้',
      TITLE_2: 'ลงทะเบียนเบื้องต้นเรียบร้อยแล้ว',
      TITLE_3: 'กรุณารอการติดต่อกลับ',
      TITLE_4: 'จากทาง',
      TITLE_5: 'ภายใน 24 ชั่วโมง',
    },

    PRIVACY_MODAL: {
      TITLE: 'นโยบายความเป็นส่วนตัว',
      REGISTER_SUCCRESS: {
        TITLE_1: 'ร้านค้าของคุณได้',
        TITLE_2: 'ลงทะเบียนเบื้องต้นเรียบร้อยแล้ว',
        TITLE_3: 'กรุณารอการติดต่อกลับ',
        TITLE_4: 'จากทาง',
        TITLE_5: 'ภายใน 24 ชั่วโมง',
      },
    },

    MODAL_TERMS: {
      TITLE: 'ข้อตกลงและเงื่อนไขการใช้บริการ',
    },

    PROMOTION_RECOMMEND: {
      TITLE: 'โปรโมชันแนะนำ',
      TITLE_SUB: 'จากสินค้าเดียวกัน',
      DISCOUNT: 'ลดสูงสุด',
      GIFT: 'แถมสูงสุด',
      UNIT: 'อย่าง'
    },


    SUMMARY: {
      DISCOUNT_AMOUNT: "ประหยัดลง",
      GIFT_TITLE: 'ซื้อครบเงื่อนไขได้รับของแถม',
      SELECTED_ITEM: "สินค้าที่เลือก",
      SELECT_GIFT_TITLE: 'เลือกรับของแถม',
      GIFT: 'ของแถม',
      ADDITIONAL_CONDITIONAL_GIFT: 'ซื้อสินค้าครบตามเงื่อนไข ได้รับของแถมเพิ่มเติม'
    },

    PROMOTION_TIER_MESSAGE: {
      TITLE: 'เกิดข้อผิดพลาด',
      MESSAGE: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      CONFIRM: 'ปิด'
    },

    PROMOTION_BUNDLE: {
      SET: 'ชุดที่',
      BUNDLE_QTY: 'จํานวนชุด',
    },

    ADDITIONAL_PRIVILEGE: {
      TITLE: 'สิทธิประโยชน์เพิ่มเติมจากการซื้อโปรโมชัน',
      TITLE_GIFT: 'ของแถม',
      TITLE_DISCOUNT: 'ส่วนลด',
      FROM: 'จาก'
    },

    ORDER_INFO: {
      GO_BACK: 'กลับไปหน้าก่อนหน้า',
      PENDING: {
        ORDER_ID: 'หมายเลขคำสั่งซื้อ',
        STATUS_PENDING: 'รอยืนยัน',
        ORDER_AT: 'สั่งซื้อเมื่อ',
        ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
        PRODUCT_LIST: 'รายการสินค้า',
        GIFT: 'ของแถม',
        CHECK_ORDER: 'ตรวจสอบคำสั่งซื้อ',
        USER_NAME: 'ชื่อผู้ซื้อ',
        TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
        PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
        SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
      },

      CONFIRMED: {
        ORDER_ID: 'หมายเลขคำสั่งซื้อ',
        STATUS_CONFIRMED: 'ยืนยันแล้ว',
        CONFIRMED_AT: 'ยืนยันเมื่อ',
        NO_APPROVED: 'ไม่อนุมัติคำขอยกเลิกเมื่อ',
        ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
        PRODUCT_LIST: 'รายการสินค้า',
        GIFT: 'ของแถม',
        ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
        VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
        DELIVERED: 'จัดส่งสินค้าแล้ว',
        CANCEL_ORDER: 'ยกเลิกคำสั่งซื้อ',
        USER_NAME: 'ชื่อผู้ซื้อ',
        TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
        PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
        SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
        MODAL: {
          TITLE: 'มีการเปลี่ยนแปลงจำนวนสินค้า',
          MESSAGE: 'คุณได้เปลี่ยนแปลงข้อมูลสินค้าบางส่วน\nหมายเลขคำสั่งซื้อจะถูกเปลี่ยนแปลงเมื่อยืนยันข้อมูล',
          CONFIRM: 'ยืนยัน',
          CANCEL: 'ยกเลิก',
        }
      },

      CANCEL_REQUESTED: {
        ORDER_ID: 'หมายเลขคำสั่งซื้อ',
        STATUS_CANCEL_REQUESTED: 'ขอยกเลิก',
        CANCEL_REQUESTED_AT: 'ขอยกเลิกเมื่อ',
        ADDITIONAL_DETAILS: 'รายละเอียดเพิ่มเติม',
        PRODUCT_LIST: 'รายการสินค้า',
        GIFT: 'ของแถม',
        ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
        VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
        APPROVED_REQUEST: 'อนุมัติคำขอ',
        NOT_APPROVED: 'ไม่อนุมัติ',
        USER_NAME: 'ชื่อผู้ซื้อ',
        TAX_ID: 'เลขประจำผู้เสียภาษีอากร',
        PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
        SECONDARY_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
      },

      CANCELLED: {
        ORDER_ID: 'หมายเลขคำสั่งซื้อ',
        STATUS_CANCELLED: 'ยกเลิก',
        CANCELLED_AT: 'ยกเลิกเมื่อ',
        BY_STOCKIST: 'โดยร้านค้าส่ง',
        BY_RETAILER: 'โดยร้านค้าปลีก',
        PRODUCT_LIST: 'รายการสินค้า',
        GIFT: 'ของแถม',
        ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
        VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
      },

      DELIVERED: {
        ORDER_ID: 'หมายเลขคำสั่งซื้อ',
        STATUS_DELIVERED: 'จัดส่งสำเร็จ',
        DELIVERED_AT: 'จัดส่งเมื่อ',
        PRODUCT_LIST: 'รายการสินค้า',
        GIFT: 'ของแถม',
        ORDER_CHANGED: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
        VIEW_OLD_ORDER: 'ดูคำสั่งซื้อเดิม',
      },

      ADDITIONAL: {
        TITLE: 'สิทธิประโยชน์เพิ่มเติมจากการซื้อโปรโมชัน',
        GIVEAWAY_TITLE: 'ของแถม',
        DISCOUNT_TITLE: 'ส่วนลด',
        FROM_PROMOTION: 'จาก'
      }
    },
    PRODUCT_PROMOTION_LIST: {
      SUM: 'รวม',
      UNIT: 'หน่วย',
      SET: 'ชุด',
      FREE: 'ฟรี'
    }


  }
};
