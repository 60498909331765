<div class="background-order-confirm margin-btm" *ngIf="orderDataV2">
    <div class="d-flex justify-content-between fs-5 background-order-head">
        <div>
            <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
              {{ "TITLE.ORDER_CANCEL" | translate }}
            </p>
            <p class="fw-ligh text-head-mid">{{ "TITLE.NUMBER" | translate }} {{orderDataV2.id}}</p>
            <p class="fw-light text-head-last">
              {{ "TITLE.CANCEL_AT" | translate }} {{orderDataV2.cancelledDate| displayDate}}
            </p>
        </div>

        <div>
            <img class="" src="assets/images/Order Cancel.svg" width="52px" height="52px" alt="Not found" />
        </div>
    </div>

    <div class="d-flex gap-2 fs-6 py-2 text-wrap align-item-center top-border px-3">
        <img src="\assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{orderDataV2.retailerName}}</p>
    </div>
    <div class="bottom-border"></div>

    <div class="mt-3">
        <div class="px-3">
            <div class="fs-6 fw-semibold product-list-label pb-3">
                <p>{{ "ORDER_INFO.PENDING.PRODUCT_LIST" | translate }}</p>
                <app-show-product-promotion-order-list [products]="orderDataV2.items"></app-show-product-promotion-order-list>
            </div>
        </div>
        <div class="px-3">
            <app-additional-privilege [additionalDiscounts]="orderDataV2.additionalDiscounts" [additionalGiveAways]="orderDataV2.additionalGiveAways" *ngIf="orderDataV2.additionalDiscounts.length > 0 || orderDataV2.additionalGiveAways.length >0"></app-additional-privilege>
            <div>
                <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
            </div>
        </div>
    </div>

</div>

<div id="footer-page" class="footer-position">
    <div class="d-flex flex-column w-100 px-3 pt-3 background-order-confirm">
        <!-- <div class="d-flex flex-column w-100 px-3 pt-3  footer-top-border background-order-confirm"> -->
        <div class="d-flex flex-column gap-3 mb-3">
            <button type="button" class="btn detail-order-btn" (click)="navigateToOrderHistoryDetail()">
              {{ "TITLE.ORDER_DETAIL" | translate }}
            </button>
            <button type="button" class="btn btn-secondary-button" (click)="navigateToLanding()">
              {{ "ACTION.BACK_PAGE_MAIN" | translate }}
            </button>
        </div>

    </div>
</div>
